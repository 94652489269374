import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const ContactReadOnly = (props) => {
  return (
    <>
      <Container className="bg-defencewhite mt-4">
        <Container className="px-0">
          <h1>Personal Information:</h1>
          <Row>
            <Col>
              <Container>
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">Full name</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.PrimaryContact.FullName}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
            <Col>
              <Container>
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">Employee ID</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.PrimaryContact.EmployeeID}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>
          {props.reqData.Location.Type !== 'Other' && (
            <Row>
              {props.groupPrimaryDesc.length > 0 && (
                <Col>
                  <Container>
                    <Form.Group controlId="workType">
                      <Form.Label className="mb-0 p-lg-0">Group or entity</Form.Label>
                      <br />
                      <Container className="d-flex gap-5 mb-4 subTypes">
                        <p className="fw-light">{props.groupPrimaryDesc}</p>
                      </Container>
                    </Form.Group>
                  </Container>
                </Col>
              )}

              <Col>
                <Container>
                  <Form.Group controlId="workType">
                    <Form.Label className="mb-0 p-lg-0">Defence email address</Form.Label>
                    <br />
                    <Container className="d-flex gap-5 mb-4 subTypes">
                      <p className="fw-light">{props.reqData.PrimaryContact.Email[0].Address}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Container>
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">Preferred contact number</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.PrimaryContact.Phone[0].Number}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
            <Col>
              <Container>
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">How would you like to be contacted?</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">
                      {!!props.reqData.PrimaryContact.PreferredContactTimes
                        ? props.reqData.PrimaryContact.PreferredContactTimes
                        : ''}
                    </p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ContactReadOnly;
