import * as React from 'react';
import { SVGProps } from 'react';
const Mail_Orange = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#CF4928"
              d="M16.418 2.67188H1.58203C0.707941 2.67188 0 3.38421 0 4.25391V13.7461C0 14.621 0.713145 15.3281 1.58203 15.3281H16.418C17.2847 15.3281 18 14.6239 18 13.7461V4.25391C18 3.38576 17.2949 2.67188 16.418 2.67188ZM16.1964 3.72656C15.8732 4.04807 10.3107 9.58124 10.1187 9.77228C9.81984 10.0711 9.42258 10.2356 9 10.2356C8.57742 10.2356 8.18016 10.0711 7.88034 9.77129C7.75118 9.6428 2.25011 4.17073 1.80359 3.72656H16.1964ZM1.05469 13.5314V4.46924L5.61227 9.00281L1.05469 13.5314ZM1.80425 14.2734L6.36005 9.74661L7.13556 10.518C7.63358 11.0161 8.29571 11.2903 9 11.2903C9.70429 11.2903 10.3664 11.0161 10.8635 10.519L11.64 9.74661L16.1957 14.2734H1.80425ZM16.9453 13.5314L12.3877 9.00281L16.9453 4.46924V13.5314Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Mail_Orange;
