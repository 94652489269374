import React, { useEffect, useState } from 'react';
import Header from '../../components/ServiceUI/Core/Header/Header';
import content from './Content/Notifications.json';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Notifications as NotificationList } from '../../components/ServiceUI/LandingPage/NotificationsList/Notifications';
import { NotificationItem } from '../../components/ServiceUI/LandingPage/NotificationsList/Notification';
import { request } from '../../helper/useAxios';
import { appConstants } from '../../helper/client/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setShowError } from '../../reducers/errorHandling';
import { setNotificationsRead } from '../../reducers/requestDetailFormReducer';
import UtilityModals from '../../components/ServiceUI/Core/Modal/UtilityModals';
import { getAemUrl } from '../../helper/getOtherAppURL';

export const NotificationsPage: React.FC<any> = (): JSX.Element => {
  const [pegaNotifications, setNotifications] = useState<NotificationItem[]>([]);
  const [aemNotifications, setAEMNotifications] = useState<NotificationItem[]>([]);
  const [pegaNotificationsCount, setPegaNotificationsCount] = useState<number>(0);
  //When the pega count value updates, or the AEM notifications list updates run this
  useEffect(() => {
    const total = aemNotifications.length + pegaNotificationsCount;
    dispatch(setNotificationsRead(total));
  }, [aemNotifications, pegaNotificationsCount]);

  const { confirm } = UtilityModals();

  const dispatch = useDispatch();

  const getNotifications = () => {
    //Getting notifications from Pega
    request(appConstants.API_NOTIFICATION_LIST, 'get')
      .then((res) => {
        setNotifications(res.data.notifications);
      })
      .catch((error) => dispatch(setShowError({ hasError: true, error })));

    //Getting notifications from MyForms actions API
    request(`${getAemUrl}${appConstants.API_MYACTIONS}`, 'get', { isAEM: true })
      .then((d) => {
        //Mapping AEM notifications into Pega format
        setAEMNotifications(
          d.data.formsToReview.map((e) => {
            return {
              description: 'You have an action to complete in My Forms',
              createTime: e.dateSubmitted,
              message: `${e?.submittedBy ?? 'Someone'} has requested your action on ${e?.formTitle}`,
              tags: [e?.product],
              link: `${getAemUrl}${e.formURL}`,
            };
          }),
        );
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
    //Getting the Pega notification count API
    request(appConstants.API_NOTIFICATION_LIST, 'get', { id: '/count' })
      .then((res) => {
        setPegaNotificationsCount(res.data.notificationCount);
      })
      .catch((error) => dispatch(setShowError({ hasError: true, error })));
  };

  useEffect(() => {
    document.title = 'Notifications';
    getNotifications();
  }, []);

  const markSingleAsRead = (flag, id?) => {
    const data = {
      isRead: flag,
    };
    request(appConstants.API_NOTIFICATION_LIST, 'patch', {
      id: `/${id}`,
      data,
    })
      .then(() => getNotifications())
      .catch((error) =>
        dispatch(
          setShowError({
            error: true,
            code: error?.response?.status,
            errorObj: error?.response,
          }),
        ),
      );
  };

  const markAllAsRead = () => {
    const data = {
      isRead: true,
    };
    request(appConstants.API_NOTIFICATION_LIST, 'patch', { data })
      .then(() => getNotifications())
      .catch((error) =>
        dispatch(
          setShowError({
            error: true,
            code: error?.response?.status,
            errorObj: error?.response,
          }),
        ),
      );
  };

  const markAsReadHandler = (type, flag?, id?) => {
    switch (type) {
      case 'single':
        markSingleAsRead(flag, id);
        break;
      case 'all':
        markAllAsRead();
        break;
      default:
        break;
    }
  };

  const deleteSingle = (id) => {
    request(appConstants.API_NOTIFICATION_LIST, 'delete', {
      id: `/${id}`,
    })
      .then(() => getNotifications())
      .catch((error) => {
        dispatch(
          setShowError({
            error: true,
            code: error?.response?.status,
            errorObj: error?.response,
          }),
        );
      });
  };

  const deleteAll = () => {
    request(appConstants.API_NOTIFICATION_LIST, 'delete')
      .then(() => getNotifications())
      .catch((error) =>
        dispatch(
          setShowError({
            error: true,
            code: error?.response?.status,
            errorObj: error?.response,
          }),
        ),
      );
  };

  const deleteHandler = async (type, id) => {
    switch (type) {
      case 'single':
        if (
          await confirm('Are you sure you want to delete this notification?', {
            cancelText: 'No',
            okText: 'Yes',
          })
        ) {
          deleteSingle(id);
        }
        break;
      case 'all':
        if (
          await confirm('Are you sure you want to delete all notifications?', {
            cancelText: 'No',
            okText: 'Yes',
          })
        ) {
          deleteAll();
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Header
        {...content.header}
        bottomBadgeContent={useSelector((state: any) => state.requestForm.notificationsCount)}
      />
      <SubContainer verticalMargin="1rem">
        {(pegaNotifications ? pegaNotifications?.concat(aemNotifications)?.length > 0 : aemNotifications.length) ? (
          <NotificationList
            onDelete={(type, id?) => deleteHandler(type, id)}
            onMarkAsRead={markAsReadHandler}
            notifications={pegaNotifications ? pegaNotifications.concat(aemNotifications) : aemNotifications}
          />
        ) : (
          <>No notifications</>
        )}
      </SubContainer>
    </>
  );
};
