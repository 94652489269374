import { useEffect } from 'react';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../../../reducers/errorHandling';

const Heartbeat = ({ interval = 600000 }) => {
  // interval set to 10 minutes for heartbeat
  const dispatch = useDispatch();

  useEffect(() => {
    const sendHeartbeat = () => {
      // send periodic requests to refresh the session and token
      request(appConstants.API_HEART_BEAT, 'get').catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
    };

    const heartbeatId = setInterval(sendHeartbeat, interval);

    return () => clearInterval(heartbeatId);
  }, [dispatch, interval]);

  return null;
};

export default Heartbeat;
