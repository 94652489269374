import * as React from 'react';
import { SVGProps } from 'react';
const Arrow_NoFill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_111_15)">
      <g>
        <path
          fill="white"
          d="M15.6962 8.67174C16 8.30533 16 7.81678 15.6962 7.45037L9.72152 0.244261C9.41772 -0.122151 9.01266 -0.122151 8.70886 0.244261C8.40506 0.610673 8.40506 1.09922 8.70886 1.46564L13.4684 7.20609H0.708861C0.303797 7.20609 0 7.57251 0 8.06106C0 8.5496 0.303797 8.91602 0.708861 8.91602H13.5696L8.81013 14.5343C8.50633 14.9007 8.50633 15.3893 8.81013 15.7557C9.11392 16.1221 9.51899 16.1221 9.82278 15.7557L15.6962 8.67174Z"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Arrow_NoFill;
