//Use this file to determine the order, label and visibility of the fields on the view page

export const KeyToLabelMap = {
  RequestDetails: {
    Label: 'Request information',
    ServiceType: 'What type of service do you require?',
    //Start type of records
    IsPersonnelInformation: 'Requires personnel information',
    IsServiceReport: 'Requires service report',
    IsMedicalRecords: 'Requires medical records',
    IsPsychologicalRecords: 'Requires psychological records',
    IsRequestCopyOfRecords: 'Requires other records',
    IsEulogy: 'Requires eulogy',
    DateOfFuneral: 'Date of funeral',
    IsStatementOfService: 'Requires statement of service',
    IsCertificateOfService: 'Requires certificate of service',
    IsDisciplinaryRecords: 'Requires disciplinary records',
    //End type of records
    AdditionalAOADetails: 'Details of amendment or annotation',
    ApplicantType: 'Applicant type',
    SubmissionDateTime: 'Date of submission',
    DeliveryType: 'Delivery type',
    AdditionalDetails: 'Additional details',
  },
  AdvocateApplicantDetails: {
    Label: 'Member information',
    GivenNames: 'First name',
    Surname: 'Last name',
    DateOfBirth: 'Date of birth',
    ServiceAreaType: 'Service area(s)',
    PMKeySNumber: 'PMKeyS Number',
    IsActiveInForce: 'Is this member a current member of the Australian Defence Force?',
    YearOfDischarge: 'Year of Discharge',
    IsDeceased: 'Is this member deceased?',
    ProofOfIdentification: { Label: 'Proof of identification (current or ex member)', type: 'files' },
    SignedConsent: { Label: 'Signed consent (current or ex member)', type: 'files' },
    ProofOfDeath: { Label: 'Proof of death', type: 'files' },
    MedicalSupportingDocuments: { Label: 'Additional documents', type: 'files' },
    DeceasedFamilyMemberDetails: {
      Label: 'Family member details',
      FullName: 'Full name',
      ProofOfFamilyExMember: { Label: 'Proof of family member relationship to ex-member', type: 'files' },
      FamilyMemberIdentification: { Label: 'Family member proof of identification', type: 'files' },
      FamilyMemberSignedConsent: { Label: 'Family member signed consent', type: 'files' },
    },
    AdvocateDetails: {
      Label: 'My information (the advocate)',
      Relationship: 'Relationship description',
      GivenNames: 'First name',
      Surname: 'Last name',
      OrganisationName: 'Organisation name',
      OrganisationType: 'Organisation type',
      Email: 'Email address',
      Phone: 'Contact number',
      ContactMethod: 'How would you like to be contacted?',
    },
  },
  FamilyMemberApplicantDetails: {
    Label: 'Member information',
    GivenNames: 'First name',
    Surname: 'Last name',
    DateOfBirth: 'Date of birth',
    ServiceAreaType: 'Service area(s)',
    PMKeySNumber: 'Service/PMKeyS number',
    IsActiveInForce: 'Is this member a current member of the Australian Defence Force?',
    YearOfDischarge: 'Year of Discharge',
    IsDeceased: 'Is this member deceased?',
    ProofOfDeath: { Label: 'Proof of death', type: 'files' },
    MedicalSupportingDocuments: { Label: 'Additional documents', type: 'files' },
    ProofOfIdentification: { Label: 'Proof of identification (current or ex member)', type: 'files' },
    SignedConsent: { Label: 'Signed consent (current or ex member)', type: 'files' },
    FamilyDetails: {
      Label: 'My information (applicant)',
      Relationship: 'Relationship description',
      GivenNames: 'First name',
      Surname: 'Last name',
      Email: 'Email address',
      Phone: 'Contact number',
      ContactMethod: 'How would you like to be contacted?',
    },
    ProofOfFamilyMember: { Label: 'Proof of family member relationship to current member', type: 'files' },
    FamilyMemberIdentification: { Label: 'Proof of identification (applicant)', type: 'files' },
  },
  SelfApplicantDetails: {
    Label: 'Member information',
    GivenNames: 'First name',
    Surname: 'Last name',
    Email: 'Email address',
    Phone: 'Contact number',
    ContactMethod: 'How would you like to be contacted?',
    DateOfBirth: 'Date of birth',
    ServiceAreaType: 'Service area(s)',
    PMKeySNumber: 'Service/PMKeyS number',
    IsActiveInForce: 'Is this member a current member of the Australian Defence Force?',
    YearOfDischarge: 'Year of discharge',
    ServiceUnit: 'Service unit',
    PlannedDischargeDate: 'Planned date of discharge',
  },
};
