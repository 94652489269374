import React from 'react';
import { Sort, Chevron_Orange as Chevron } from '../icons/Outline';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonGroup,
  IconButton,
  Text,
  Flex,
  SystemProps,
} from '@chakra-ui/react';

interface DropdownItem {
  title: string;
  property: string;
}
export interface SortButtonProps {
  changeDirection?(): void;
  changeSort(item: string): void;
  text: string;
  dropdownItems: DropdownItem[];
  variant?: 'regular' | 'search' | string;
  currentSort?: string;
  display?: SystemProps['display'];
  isSRSort?: boolean;
}
export const SortButton: React.FC<SortButtonProps> = (props: SortButtonProps): JSX.Element => {
  return (
    <Menu arrowPadding={0}>
      {props.variant === 'search' ? (
        <MenuButton as={Button} variant="sort" border="none" colorScheme="transparent">
          <Flex gap={1} alignItems="center">
            <Text>{props.text}</Text>
            <Text fontWeight="bold">{props.currentSort}</Text>
            <Chevron height={8} />
          </Flex>
        </MenuButton>
      ) : (
        <ButtonGroup isAttached display={props.display ?? 'block'}>
          <MenuButton
            size={props.isSRSort ? 'sr' : 'size'}
            as={Button}
            padding="0 0.25rem 0 1rem !important"
            colorScheme="transparent"
            color="black"
          >
            Sort
          </MenuButton>
          <IconButton
            size={props.isSRSort ? 'sr' : 'size'}
            colorScheme="transparent"
            onClick={props.changeDirection}
            aria-label="Change direction"
            icon={<Sort height="1rem" />}
            padding="0 0 0 0.25rem !important"
          />
        </ButtonGroup>
      )}
      <MenuList>
        {props.dropdownItems?.map((item: DropdownItem, key: number) => (
          <MenuItem key={key} onClick={() => props.changeSort(item.property)} data-testid="menuitem">
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
