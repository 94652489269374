import React from 'react';
import { Box, Image, Flex, Link } from '@chakra-ui/react';
import SubNavigationMobile from './SubNavigationMobile';
import { MainContainer } from '../Layout/MainContainer/MainContainer';
import { NavItemProps } from '../NavItem/NavItem';
import MenuLinks from '../MenuLinks/MenuLinks';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface SubNavigationProps {
  router?: boolean;
  logoURL: string;
  logoAltText: string;
  homeURL: string;
  navigationItems?: NavItemProps[];
}

export const SubNavigationDesktop: React.FC<SubNavigationProps> = (props: SubNavigationProps): JSX.Element => {
  const navigationItems = props.navigationItems;
  return (
    <Box bg="white">
      <MainContainer flex>
        <Link
          data-testid="homeLink"
          as={ReactRouterLink}
          to={props.homeURL}
          height="80px"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Image alt={props.logoAltText} height="35" src={require(`../../../../images/${props.logoURL}`)} />
        </Link>
        <Flex justifyContent="flex-end">
          <MenuLinks variant="subNav" router={true} navigationItems={navigationItems ?? []} />
        </Flex>
      </MainContainer>
    </Box>
  );
};
const SubNavigation: React.FC<SubNavigationProps> = (props: SubNavigationProps): JSX.Element => {
  return (
    <>
      <Box display={{ base: 'none', lg: 'block' }}>
        <SubNavigationDesktop {...props} />
      </Box>
      <Box display={{ base: 'block', lg: 'none' }}>
        <SubNavigationMobile {...props} />
      </Box>
    </>
  );
};

export default SubNavigation;
