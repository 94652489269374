import { appConstants } from '../helper/client/constant';

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies
export const logoutURL = () => {
  let url = process.env.REACT_APP_LOGOUT_INTERNAL;
  //check if local, dev, test, tst2
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
  ) {
    return url;
  } else {
    if (cookieSplit.includes('IDP=mygovid')) {
      return process.env.REACT_APP_LOGOUT_EXTERNAL; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      return url; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        return process.env.REACT_APP_LOGOUT_EXTERNAL; //external
      } else {
        return url; //internal
      }
    }
  }
};
