import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export interface BreadcrumbItemProps {
  url: string;
  name: string;
}
export interface BreadcrumbProps {
  breadcrumbItems: BreadcrumbItemProps[];
}
export const Breadcrumbs: React.FC<BreadcrumbProps> = (props: BreadcrumbProps): JSX.Element => {
  const [currentLocation, setCurrentLocation] = React.useState<string>('');
  const location = useLocation();
  React.useEffect(() => {
    setTimeout(() => {
      setCurrentLocation(document.title);
    }, 1);
  }, [location.pathname]);
  return (
    <Breadcrumb separator=">" marginBottom="2rem" listProps={{ p: 0 }}>
      {props.breadcrumbItems.map((item: BreadcrumbItemProps, index: number) => (
        <BreadcrumbItem key={index} fontSize="xs">
          <BreadcrumbLink textDecoration="underline" as={Link} to={item.url}>
            {item.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
      <BreadcrumbItem fontWeight="bold" fontSize="xs">
        <Text>{currentLocation}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
