import axios from 'axios';
import { findByProp } from '../../../helper/findByProp';
import { getApiUrl } from '../../../helper/getOtherAppURL';

import { appConstants } from '../../../helper/client/constant';

export const refreshSRForm = (fields, nextAssignmentID) => {
  const apiUrl = getApiUrl;

  const URL = apiUrl + appConstants.API_ASSIGNMENTS;
  const data = {
    content: {},
    pageInstructions: fields,
  };
  return axios
    .put(URL + nextAssignmentID + '/actions/Create/refresh', data, {
      withCredentials: true,
    })
    .then((res) => {
      let fieldList = [];
      let groupObjectforServiceRequest;
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestTitle'),
      });
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestRelatesTo'),
      });
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestRelationDetail'),
      });
      groupObjectforServiceRequest = findByProp(res.data, 'viewID', 'StandardServiceRequestReact');
      fieldList = [...fieldList, ...groupObjectforServiceRequest.groups[0].layout.groups];
      return fieldList;
    });
};
