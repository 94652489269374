import { configureStore } from '@reduxjs/toolkit';
import eftRequestDetailFormReducer from './reducers/eftRequestDetailFormReducer';
import errorHandling from './reducers/errorHandling';
import requestDetailFormReducer from './reducers/requestDetailFormReducer';
import isInternal from './reducers/internalCheckReducer';

export const store = configureStore({
  reducer: {
    requestForm: requestDetailFormReducer,
    EFTRequestMemberForm: eftRequestDetailFormReducer,
    error: errorHandling,
    isInternal,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
