import { FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import React from 'react';
import { FormElement, SimpleTextProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';

const FormTextAreaInputSimple: React.FC<FormElement & SimpleTextProps> = (
  props: FormElement & SimpleTextProps,
): JSX.Element => {
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
        >
          <FormLabel>
            <span>{props.label}</span>
          </FormLabel>
          <Textarea
            data-testid="textarea"
            variant="primary"
            onBlur={(e) => props.onBlur(e.target.value)}
            onChange={(e) => props.onBlur(e.target.value)}
            value={String(props.value)}
            readOnly={props.readOnly}
          />
          <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormTextAreaInputSimple;
