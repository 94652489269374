import React, { useState, useEffect } from 'react';
import { request } from '../../../../helper/useAxios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import icon_hammer from '../../../../images/icon_hammer.svg';
import icon_list from '../../../../images/icon_list.svg';
import { setShowError } from '../../../../reducers/errorHandling';
import { appConstants } from '../../../../helper/client/constant';
import {
  Modal,
  Image,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Button,
  UnorderedList,
  ListItem,
  Text,
  ModalContent,
  Radio,
  RadioGroup,
  Flex,
} from '@chakra-ui/react';
export interface SRTypeSelectorProps {
  onClose: () => void;
  isOpen: boolean;
  modalProps: {
    modalHeader: string;
    mmData: MMData;
    srData: SRData;
  };
}
interface MMData {
  heading: string;
  description: string;
}
interface SRData {
  heading: string;
  description: string;
}

interface RefValue {
  description: string;
}

const SRTypeSelector: React.FC<SRTypeSelectorProps> = ({
  modalProps,
  onClose,
  isOpen,
}: SRTypeSelectorProps): JSX.Element => {
  //define variables
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { modalHeader, mmData, srData } = modalProps;

  //define states
  const [reqisInProgress, setReqIsInProgress] = useState(false);
  const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
  const [MMdescription, setMMDescription] = useState<{ refValues: RefValue[] }>({
    refValues: [],
  });
  const [SRdescription, setSRDescription] = useState<{ refValues: RefValue[] }>({
    refValues: [],
  });

  //on refresh get the list values
  useEffect(() => {
    getMinorMaintenanceList();
    getServiceRequestList();
  }, []);

  //close modal handler to reset the selected type
  const handleClose = () => {
    setSelectedType(undefined);
    onClose();
  };
  //get minor maintenance list
  const getMinorMaintenanceList = () => {
    request(appConstants.API_REF_DATA + 'WorkType', 'get')
      .then((res) => {
        setMMDescription((prevState) => {
          return {
            ...prevState,
            refValues: res.data.refValues,
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //get service request list
  const getServiceRequestList = () => {
    request(appConstants.API_REF_DATA + 'ServiceType', 'get')
      .then((res) => {
        setSRDescription((prevState) => {
          return {
            ...prevState,
            refValues: res.data.refValues,
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response?.status }));
      });
  };

  //pass SR type into createNewRequest
  const routeChange = (type) => {
    if (!reqisInProgress) {
      createNewRequest(type);
    }
  };

  //On continue create a new request
  const createNewRequest = (type) => {
    setReqIsInProgress(true);
    const data = {
      caseTypeID: 'DoD-SConn-Work-ServiceRequest',
      content: {},
    };
    request(appConstants.API_CASES, 'post', { data })
      .then((res) => {
        request(appConstants.API_ASSIGNMENTS + res.data.nextAssignmentID, 'get')
          .then((res) => {
            // updating the app states this we will use in update call
            sessionStorage.setItem('nextAssignmentId', JSON.stringify(res.data.ID));
            sessionStorage.setItem('actionId', JSON.stringify(res.data.actions[0].ID));
            // This call is to prepopulate the req data
            const url =
              appConstants.API_ASSIGNMENTS +
              res.data.ID +
              '/actions/' +
              res.data.actions[0].ID +
              '/?flatListOfFields=Basic';
            request(url, 'get')
              .then((res) => {
                sessionStorage.setItem('caseId', res.data.caseID);
                sessionStorage.setItem('createReqResData', JSON.stringify(res.data));
                let path = `/my-service-request/new-service-request?type=${type}`;
                setReqIsInProgress(false);
                navigate(path);
              })
              .catch((error) => {
                setReqIsInProgress(false);
                dispatch(setShowError({ error: true, code: error.response?.status }));
              });
          })
          .catch((error) => {
            setReqIsInProgress(false);
            dispatch(setShowError({ error: true, code: error.response?.status }));
          });
      })
      .catch((error) => {
        setReqIsInProgress(false);
        dispatch(setShowError({ error: true, code: error.response?.status }));
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xlarge" autoFocus={false}>
        <ModalOverlay />
        <ModalContent px={{ lg: '1.5rem', base: '10px' }}>
          {/* Modal Heading */}
          <ModalHeader pt="2.5rem" fontSize="28px" fontWeight="500">
            {modalHeader}
          </ModalHeader>
          {/* Modal Body with two grid items MM and SR */}
          <ModalBody>
            <RadioGroup
              as={Flex}
              gap={10}
              value={selectedType}
              onChange={setSelectedType}
              flexDir={{ lg: 'row', base: 'column' }}
            >
              <Radio variant="SR" value="MMR">
                <Image mb={3} src={icon_hammer} alt="hammer" />
                <Text fontSize="md" fontWeight="500">
                  {mmData.heading}
                </Text>
                <Text color="orange.standard">{mmData.description}</Text>
                <UnorderedList fontSize="regular">
                  {MMdescription.refValues.map((value, index) => {
                    return (
                      <>
                        {value.description !== 'Please select a service type' && (
                          <ListItem key={index} fontWeight="light">
                            {value.description}
                          </ListItem>
                        )}
                      </>
                    );
                  })}
                </UnorderedList>
              </Radio>
              <Radio variant="SR" value="SR">
                <Image mb={3} src={icon_list} alt="list icon" />
                <Text fontSize="md" fontWeight="500">
                  {srData.heading}
                </Text>
                <Text color="orange.standard">{srData.description}</Text>
                <UnorderedList fontSize="regular">
                  {SRdescription.refValues.map((value, index) => {
                    return (
                      <>
                        {value.description !== 'Please select a service type' && (
                          <ListItem key={index} fontWeight="light">
                            {value.description}
                          </ListItem>
                        )}
                      </>
                    );
                  })}
                </UnorderedList>
              </Radio>
            </RadioGroup>
          </ModalBody>

          {/* Modal Footer Cancel/Continue */}
          <ModalFooter
            display="flex"
            gap="3"
            flexDirection={{ lg: 'row', base: 'column-reverse' }}
            justifyContent="space-between"
          >
            <Button variant="secondary" w={{ lg: '190px', base: '100%' }} onClick={handleClose}>
              Cancel
            </Button>
            {selectedType !== undefined && (
              <Button
                variant="primary"
                w={{ lg: '190px', base: '100%' }}
                isDisabled={reqisInProgress}
                onClick={() =>
                  routeChange(
                    selectedType === 'MMR'
                      ? appConstants.SERVICE_REQUEST_TYPE_MINOR
                      : appConstants.SERVICE_REQUEST_TYPE_FULL,
                  )
                }
              >
                Start a request
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SRTypeSelector;
