import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import React from 'react';

interface RedirectorProps {
  urlToRedirectTo: string;
  showMessage?: boolean;
  message?: string;
  redirectButtonText?: string;
}

export const Redirector: React.FC<RedirectorProps> = (props: RedirectorProps) => {
  const redirect = () => {
    window.location.href = props.urlToRedirectTo;
  };
  return props.showMessage ? (
    <Modal isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text>{props.message}</Text>
        </ModalBody>
        <ModalFooter flexDir="row-reverse">
          <Button variant="primary" onClick={redirect}>
            {props.redirectButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <>{redirect()}</>
  );
};
