import React from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { TimePicker } from '../../../components/common/sc-react-timepicker';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormDateInput = (props) => {
  const {
    fieldID,
    fieldType,
    required,
    label,
    fieldPropMapping,
    visible,
    reference,
    formData,
    value,
    readOnly,
    error,
  } = props;
  const dispatch = useDispatch();

  const currentDate = new Date();

  const calculateMinMax = (): { min: string; max: string } => {
    let min = '';
    let max = '';
    switch (fieldID) {
      case 'DateOfBirth':
        max = new Date(new Date().setDate(currentDate.getDate() - 1)).toISOString().slice(0, 10);
        min = '';
        break;
      case 'PlannedDischargeDate':
        min = new Date(currentDate).toISOString().slice(0, 10);
        max = '9999-12-31';
        break;
      default:
        break;
    }
    return { min, max };
  };

  const validationRules = {
    DateOfBirth: {
      validate: (value) => !isDateInFutureOrToday(value) || 'Date of Birth cannot be current or future dated.',
    },
    PlannedDischargeDate: {
      validate: (value) => !isDateInPast(value) || 'Planned date of discharge cannot be past dated.',
    },
  };

  let customValidation = validationRules[fieldID];

  function isDateInPast(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();
    // Set the time of today to 00:00:00 for a fair comparison
    today.setHours(0, 0, 0, 0);
    return inputDate < today;
  }

  function isDateInFutureOrToday(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();
    // Set the time of today to 23:59:59 for a fair comparison
    today.setHours(23, 59, 59, 999);
    return inputDate > today;
  }

  const onTimePickerInputBlur = (time) => {
    dispatch(
      setFieldValue({
        reference,
        value: time,
        formData,
      }),
    );
  };

  const onDateBlur = ({ target }) => {
    dispatch(
      setFieldValue({
        reference,
        value: target.value,
        formData,
      }),
    );
  };

  return (
    <>
      {fieldType === 'Date' ? (
        <SCForm.DateInput
          disabled={readOnly}
          visible={visible}
          required={required}
          isInvalid={error}
          desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
          mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
          label={label}
          type="date"
          reference={reference}
          onDateBlur={(e) => onDateBlur(e)}
          value={value}
          maxDate={calculateMinMax().max || '9999-12-31'}
          minDate={calculateMinMax().min}
          // validation={fieldPropMapping[fieldID]?.validation}
          validation={{
            ...fieldPropMapping[fieldID]?.validation,
            ...customValidation,
          }}
        />
      ) : (
        <TimePicker
          id={reference}
          required={required}
          inputOnBlur={onTimePickerInputBlur}
          onSave={onTimePickerInputBlur}
          value={value}
          use12Hours
          validation={fieldPropMapping[fieldID]?.validation}
        />
      )}
    </>
  );
};

export default FormDateInput;
