import React from 'react';
import { Flex, SystemProps, Box } from '@chakra-ui/react';
interface SubContainerProps {
  verticalMargin?: string;
  children: JSX.Element[] | JSX.Element;
  padding?: string;
  wrap?: SystemProps['flexWrap'];
  alignItems?: SystemProps['alignItems'];
  direction?: SystemProps['flexDirection'];
  gap?: SystemProps['gap'];
  flex?: boolean;
}

export const SubContainer = (props: SubContainerProps): JSX.Element => {
  return props.flex ? (
    <Flex
      maxW="1196px"
      gap={props.gap}
      padding={props.padding || { lg: '0 55px', base: '0 25px' }}
      wrap={props.wrap}
      direction={props.direction}
      align={props.alignItems}
      w="100%"
      margin={`${props.verticalMargin ?? 0} auto`}
      justifyContent="space-between"
    >
      {props.children}
    </Flex>
  ) : (
    <Box
      maxW="1196px"
      w="100%"
      padding={props.padding || { lg: '0 55px', base: '0 25px' }}
      margin={`${props.verticalMargin ?? 0} auto`}
    >
      {props.children}
    </Box>
  );
};
