import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';
import { useCountdown } from './countdown';
import { appConstants } from '../../../helper/client/constant';
import { request } from '../../../helper/useAxios';
import { setShowError } from '../../../reducers/errorHandling';
import { useSelector, useDispatch } from 'react-redux';
import Heartbeat from '../../ServiceUI/LandingPage/HeartBeat/HeartBeat';

const IdleModal = (props) => {
  const dispatch = useDispatch();
  const isInternal = useSelector((state) => state.isInternal);
  const [redirectTimeoutValue, setRedirectTimeoutValue] = useState(null);

  const handleKillSession = () => {
    //Function to call API to kill the session
    request(appConstants.API_LOGOUT_USER, 'delete').catch((error) =>
      dispatch(setShowError({ error: true, code: error?.response?.status, errorObj: error?.response })),
    );
    //Redirection URL
    let logoutURL = '';
    if (
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
    ) {
      logoutURL = process.env.REACT_APP_LOGOUT;
    } else {
      if (isInternal) {
        logoutURL = process.env.REACT_APP_INACTIVE_INTERNAL;
      } else {
        logoutURL = process.env.REACT_APP_LOGOUT_EXTERNAL;
      }
    }
    window.location.href = logoutURL;
  };

  //Sets a timeout to kill the session
  useEffect(() => {
    const redirectTimeout = setTimeout(handleKillSession, props.timeUntilRedirect); //set the timeout time of props.timeUntilRedirect and calls handleKillSession after
    setRedirectTimeoutValue(redirectTimeout); //set this value state for OK button
    return () => clearTimeout(redirectTimeout);
  }, [props.timeUntilRedirect]); // if props.timeUntilRedirect changes the previous timeout is cleared and a new timeout is set

  // clearing timeout and resetting timer
  const handleClick = () => {
    props.setModalOpen(false);
    //need to call clearTimeout when user clicks OK
    clearTimeout(redirectTimeoutValue);
    props.resetTimer();
    //When OK button is pressed on modal, send heartbeat, resume the idle checks and close the modal
    request(appConstants.API_HEART_BEAT, 'get').catch((error) => {
      dispatch(setShowError({ hasError: true, error }));
    });
  };

  return (
    <>
      <Modal show>
        <Modal.Body>
          You have been inactive for {props.idleTime / 60} minutes. In accordance with Defence ICT Security Policy you
          will be logged out after 15 minutes. If logged out, your progress will not be saved. Press OK to resume
          session or you will be logged out in
          <CountdownTimer targetDate={props.timeUntilRedirect + new Date().getTime()} />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button onClick={handleClick}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [minutes, seconds] = useCountdown(targetDate);
  return <ShowCounter minutes={minutes} seconds={seconds} />;
};

const ShowCounter = ({ minutes, seconds }) => {
  return (
    <div>
      {minutes}:{seconds}
    </div>
  );
};

export default function SessionTimeout(props) {
  //State for opening/closing the modal
  const [modalOpen, setModalOpen] = useState(false);
  //time until idle is activated - Do not change name
  const timeout = props.idleTime * 1000; // how long till the popup shows up
  const onIdle = () => setModalOpen(true); //display modal on inactivity
  const onAction = () => reset(); //resetting timer on user activity

  //Configure idle timer
  const { reset, getLastActiveTime } = useIdleTimer({
    timeout,
    onIdle,
    onAction,
  });

  // tracking users interactions
  useEffect(() => {
    const handleVisibilityChange = () => {
      //check visibility to see if the doc is the foreground
      if (document.visibilityState === 'visible') {
        const lastActive = getLastActiveTime();
        const currentTime = new Date().getTime();
        const inactiveDuration = (currentTime - lastActive) / 1000; // In seconds
        if (inactiveDuration > props.idleTime) {
          //if inactive duration is longer than idletime show pop
          setModalOpen(true);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // periodic check every minute
    const intervalId = setInterval(() => {
      handleVisibilityChange();
    }, 60000);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [props.idleTime, getLastActiveTime]);

  return modalOpen ? (
    <>
      <IdleModal
        {...props}
        timeUntilRedirect={props.timeUntilRedirect * 1000} //the timer till redirect
        setModalOpen={setModalOpen}
        resetTimer={reset}
      />
      <Heartbeat interval={600000} /> {/* Heartbeat every 10 minutes */}
    </>
  ) : null;
}
