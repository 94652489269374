import React from 'react';
import { Checkbox, FormErrorMessage, Box } from '@chakra-ui/react';
import CheckboxIcon from '../../icons/Outline/Checkbox';
import { FormElement, CheckboxProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import sanitizeHtml from 'sanitize-html';
import { useFormContext } from 'react-hook-form';

const Icon = (props) => {
  const { isChecked } = props;
  return isChecked ? <CheckboxIcon height="1rem" /> : <></>;
};
const FormCheckbox: React.FC<FormElement & CheckboxProps> = (props: FormElement & CheckboxProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the input element and a fallback
  const id = props.reference?.replace('.', '') || 'checkbox';

  return props.visible ? (
    <PrimitiveControl
      disabled={props.disabled}
      isInvalid={!!errors[id]}
      required={props.required}
      desktopWidth={props.desktopWidth}
      mobileWidth={props.mobileWidth}
    >
      <Checkbox
        variant="main"
        icon={<Icon />}
        data-testid="checkbox"
        isChecked={Boolean(props.value)}
        {...register(id, {
          ...props?.validation,
          onChange: (e) => {
            props.onCheckboxChange(e.target.checked);
          },
        })}
      >
        <Box
          fontSize="18px"
          sx={{ a: { textDecoration: 'underline' } }}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(props.label),
          }}
        />
      </Checkbox>
      <FormErrorMessage>
        <>{errors[id] && errors[id]?.message}</>
      </FormErrorMessage>
    </PrimitiveControl>
  ) : null;
};

export default FormCheckbox;
