import * as React from 'react';
import { SVGProps } from 'react';
const Ringing_Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g>
      <path
        fill="#CF4928"
        d="M14.2917 10.4713L13.0998 9.27934C12.4617 8.64059 11.361 8.62747 10.6961 9.29247C10.0255 9.96368 8.97421 10.0825 8.19549 9.57055C7.50299 9.11623 6.91674 8.52122 6.45229 7.80372C5.94166 7.01497 6.05541 5.95812 6.72229 5.29188C7.04166 4.9725 7.21792 4.54813 7.21792 4.09625C7.21792 3.645 7.04166 3.21999 6.72229 2.90061L5.52666 1.70436C5.03416 1.21248 4.30353 1.07186 3.64234 1.35623C2.84358 1.69997 2.19109 2.28249 1.75482 3.04055L1.73794 3.06992C1.12044 4.14555 1.04544 5.4424 1.53669 6.54C3.16421 10.1738 5.83109 12.8407 9.46618 14.4669C9.94618 14.6812 10.4649 14.7875 10.9862 14.7875C11.653 14.7875 12.3249 14.6138 12.9274 14.27C13.6806 13.8394 14.2874 13.1643 14.6468 12.3418C14.9268 11.7 14.7881 10.965 14.293 10.47L14.2917 10.4713ZM13.7173 11.9706C13.4554 12.57 12.9986 13.0787 12.4304 13.4025C11.633 13.8569 10.6773 13.9137 9.87295 13.5549C6.47043 12.0325 3.97171 9.53498 2.44867 6.13241C2.08742 5.32615 2.14618 4.36809 2.60555 3.56873L2.62242 3.53935C2.9493 2.9706 3.43868 2.53371 4.05806 2.26747C4.1468 2.22935 4.23993 2.2106 4.3318 2.2106C4.51118 2.2106 4.68617 2.2806 4.8193 2.41309L6.01493 3.60873C6.14556 3.73935 6.21743 3.91247 6.21743 4.09686C6.21743 4.2806 6.14556 4.45498 6.01493 4.58498C5.01618 5.58374 4.84743 7.16626 5.61244 8.34819C6.15306 9.18255 6.8368 9.87568 7.6462 10.4069C8.81744 11.1756 10.3974 11.005 11.4161 9.98693C11.6774 9.72566 12.1318 9.72566 12.393 9.98693L13.5849 11.1787C13.7874 11.3813 13.8442 11.6812 13.7173 11.9706Z"
      />
      <path
        fill="#CF4928"
        d="M14.2393 7.76689C14.2585 7.76689 14.2779 7.76627 14.2973 7.76377C14.5717 7.73252 14.7686 7.48439 14.7367 7.21003C14.3749 4.07131 11.9292 1.62683 8.79046 1.26379C8.51797 1.23254 8.2686 1.42942 8.23672 1.70317C8.20547 1.97753 8.40236 2.22566 8.6761 2.25691C11.3505 2.56566 13.4342 4.64939 13.7436 7.32443C13.773 7.57879 13.9892 7.76689 14.2393 7.76689Z"
      />
      <path
        fill="#CF4928"
        d="M8.81602 3.17312C8.53977 3.12749 8.28602 3.31187 8.2404 3.58436C8.19476 3.85686 8.37914 4.11437 8.65164 4.16C10.288 4.43188 11.5685 5.71312 11.8405 7.3488C11.881 7.59317 12.0923 7.76693 12.3329 7.76693C12.3605 7.76693 12.3873 7.76505 12.4154 7.76005C12.6873 7.71443 12.8723 7.45756 12.8266 7.18442C12.4855 5.12698 10.8741 3.51488 8.81602 3.17312Z"
      />
    </g>
  </svg>
);
export default Ringing_Phone;
