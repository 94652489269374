//This is a primitive form control to be used as a parent for all form elements
import React from 'react';
import { FormElement } from './FormElementType';
import { ReactElement } from 'react';
import { FormControl } from '@chakra-ui/react';
import { convertTo12Grid } from '../../../../helper/convertTo12Grid';
type Primitive = FormElement & {
  'data-testid'?: string;
  children: ReactElement | ReactElement[];
};

export const PrimitiveControl: React.FC<Primitive> = (props: Primitive): JSX.Element => {
  return (
    <FormControl
      data-testid={props['data-testid']}
      id={props?.id}
      isInvalid={props.isInvalid}
      isRequired={props.required}
      display={{ lg: 'inline-block' }}
      w={{
        lg: convertTo12Grid(props.desktopWidth ?? 'auto'),
        sm: convertTo12Grid(props.mobileWidth ?? 'auto'),
      }}
      mb="1rem"
      pe={{ lg: '1rem', base: 0 }}
      minHeight={props.minHeight}
    >
      {props.children}
    </FormControl>
  );
};
