import { ComponentStyleConfig } from '@chakra-ui/react';
const Modal: ComponentStyleConfig = {
  parts: ['dialog', 'header', 'overlay'],
  variants: { feedback: { header: { paddingBottom: 0 } } },
  baseStyle: { overlay: { opacity: '0.8 !important', bg: 'black' } },
  sizes: {
    xlarge: { dialog: { width: { lg: '80%', base: '90%' }, maxW: '1206px' } },
    large: { dialog: { width: { lg: '60%', base: '90%' } } },
    small: { dialog: { width: { lg: '40%', base: '70%' } } },
  },
};

export default Modal;
