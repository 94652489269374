import React from 'react';
import {
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { FormElement, TextProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { useFormContext } from 'react-hook-form';

const FormTextInput: React.FC<FormElement & TextProps> = (props: FormElement & TextProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the input element and a fallback
  const id = props?.reference?.replace(/\./g, '') || 'registerId';

  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={!!errors[id]}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
          key={`textInput-${props.id}`}
          minHeight={props.minHeight}
        >
          <InputGroup flexDirection="column">
            {props.label && <FormLabel fontWeight="normal">{props.label}</FormLabel>}
            {/* TODO */}
            {props?.showTooltip && <>Tooltip here</>}
            {props.icon &&
              (props.iconPosition === 'left' ? (
                <InputLeftElement h="100%" pointerEvents="none" me="0.5rem">
                  {props.icon}
                </InputLeftElement>
              ) : (
                <InputRightElement h="100%" pointerEvents="none" me="0.25rem">
                  {props.icon}
                </InputRightElement>
              ))}
            <Input
              onKeyDown={(e) => e.key === 'Enter' && props.onEnter && props.onEnter()}
              data-testid="input"
              variant="primary"
              type={props.type}
              max={props?.max}
              min={props?.min}
              maxLength={props?.maxLength}
              minLength={props?.minLength}
              placeholder={props.placeholder}
              defaultValue={String(props.value)}
              readOnly={props?.readOnly}
              key={`input-${props.id}`}
              {...register(id, {
                ...props?.validation,
                onBlur: (e) => {
                  // @ts-ignore
                  props.onBlur(e.target.value);
                },
              })}
            />
            <FormErrorMessage>
              <>{errors[id] && errors[id]?.message}</>
            </FormErrorMessage>
            {props.helpText && <Text id={`helptext-${props.reference}`}>{props?.helpText}</Text>}
          </InputGroup>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormTextInput;
