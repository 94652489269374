import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Heading, Button } from '@chakra-ui/react';
import { Success } from '../../../components/ServiceUI/Core/icons/Outline';
import { SubContainer } from '../../../components/ServiceUI/Core/Layout';
import { appConstants } from '../../../helper/client/constant';
import { useParams } from 'react-router-dom';
import { getApiUrl, getAuthURL } from '../../../helper/getOtherAppURL';

const VerifyIdentityLink = () => {
  const { code } = useParams();
  const [tokenValidated, setTokenValidated] = useState(null);
  const apiUrl = getApiUrl;

  useEffect(() => {
    validateToken();
  }, []);
  const gotoLogin = () => {
    const loginURL = getAuthURL();
    window.location.href = loginURL;
  };
  const validateToken = () => {
    if (sessionStorage.getItem('tokenValidated') !== code) {
      let URL = appConstants.API_VERIFY_IDENTITYLINK + code;
      URL = apiUrl + URL;
      axios
        .post(URL, {})
        .then((res) => {
          setTokenValidated(res.status === 200 ? true : false);
        })
        .catch(() => {
          setTokenValidated(false);
        });
      sessionStorage.setItem('tokenValidated', code);
    } else {
      setTokenValidated(false);
    }
  };

  return (
    <SubContainer verticalMargin="1rem">
      {tokenValidated && (
        <Box m={2}>
          <Success height="4rem" />
          <Heading as="h1" fontSize={{ lg: 'sm', base: 'sm' }} fontWeight="medium" mt="1rem">
            Your Defence email address has now been verified.
          </Heading>
          <Box mt={{ lg: '1rem', base: '2rem' }} height="2rem"></Box>
          <p>
            You will need to close your internet browser and then upon your next log-in to{' '}
            <a href="https://www.defence.gov.au/about/staff-resources/serviceconnect">ServiceConnect</a> using your
            Digital Identity, such as myGovID, you will have access to all features available in ServiceConnect securely
            outside the Defence Protected Environment (DPE).
          </p>
          <p>
            For security reasons, you will be prompted to verify your Defence email address every 90 days. Please check
            your Defence email inbox regularly for reminders about re-verifying your Defence email address to retain
            full access to ServiceConnect.
          </p>
          <p>
            If you have verified your Defence email address for the first time, your ServiceConnect profile has now been
            updated with the details stored for you in the Defence Corporate Directory (DCD) (excluding your home base,
            personal email address and contact number).
          </p>
          <p>
            Visit your Profile in ServiceConnect to view your updated details and learn more about how to manage your
            information.
          </p>
          <Button data-testid="OutageButton" className="rounded-0 reLoginButton" variant="primary" onClick={gotoLogin}>
            Close
          </Button>
        </Box>
      )}
      {tokenValidated === false && (
        <Box m={2}>
          <Heading as="h1" fontSize={{ lg: 'sm', base: 'sm' }} fontWeight="medium" mt="1rem">
            Unable to link your Defence email to your myGovID.
          </Heading>
          <p>Your link has expired. Please try relinking your Defence email to your myGovID.</p>
        </Box>
      )}
    </SubContainer>
  );
};

export default VerifyIdentityLink;
