import { appConstants } from './client/constant';

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export const getUserType = () => {
  //check IDP cookie
  if (cookieSplit.includes('IDP=mygovid')) {
    return false; //external
  } else if (cookieSplit.includes('IDP=defence')) {
    return true; //internal
  } else {
    // check if the user is internal or external based on the URL
    return window.location.href.includes(appConstants.SR_PUBLIC_PORTAL) ? false : true;
  }
};
