import React from 'react';
import { Box, Spinner as ChakraSpinner, Text } from '@chakra-ui/react';
import { WithOverlay } from './SpinnerWithOverlay';

interface SpinnerProps {
  text?: string;
  overlay?: boolean;
  opaqueOverlay?: boolean;
}
export const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps): JSX.Element => {
  return props.overlay ? (
    <WithOverlay {...props} />
  ) : (
    <>
      <Box w="100%" textAlign="center" py="1rem">
        <ChakraSpinner size="lg" />
        {props.text && <Text>{props.text}</Text>}
      </Box>
    </>
  );
};
