import React from 'react';
import { Box, Flex, Image, List, ListItem, Text, Link } from '@chakra-ui/react';
import { External_White as ExternalWhite } from '../icons/Outline';
import { MainContainer } from '../Layout/MainContainer/MainContainer';
import { getBaseURL, getFormsURL, getPostingConnectURL } from '../../../../helper/getOtherAppURL';
import { appConstants } from '../../../../helper/client/constant';

export interface LinkProps {
  isExternal: boolean;
  title: string;
  url: string;
}

export interface FooterProps {
  logoAltText: string;
  logoURL: string;
  acknowledgmentText: string;
  quickAccessTitle: string;
  quickAccess: LinkProps[];
  moreInfoTitle: string;
  moreInformation: LinkProps[];
}

const Footer: React.FC<FooterProps> = (props: FooterProps): JSX.Element => {
  return (
    <Box bg="charcoalgrey" color="white">
      <MainContainer flex padding="2rem 0" direction={{ lg: 'row', base: 'column-reverse' }}>
        <Flex w={{ lg: '50%' }} flexDirection="column">
          <Image
            src={props.logoURL}
            w={{ lg: '50%', base: '80%' }}
            marginTop={{ lg: 0, base: '2rem' }}
            alt={props.logoAltText}
          />
          <Text
            fontWeight="regular"
            marginTop="1rem"
            marginRight={{ lg: '3rem' }}
            dangerouslySetInnerHTML={{ __html: props.acknowledgmentText }}
          />
          <Text fontSize="xxs" mt="0.5rem">
            v{process.env.REACT_APP_VERSION}
          </Text>
        </Flex>
        <Flex
          w={{ lg: '50%' }}
          gap={{ lg: '10rem', base: '2rem' }}
          flexDirection={{ lg: 'row-reverse', base: 'column' }}
        >
          <List spacing="0.2rem" p={0}>
            <Text fontWeight="bold">{props.quickAccessTitle}</Text>
            {props.quickAccess &&
              props.quickAccess.map((item: LinkProps, index: number) => (
                <ListItem key={index}>
                  <Link isExternal={item.isExternal} href={item.url}>
                    {item.title}
                  </Link>
                </ListItem>
              ))}
          </List>
          <List spacing="0.2rem" p={0}>
            <Text fontWeight="bold">{props.moreInfoTitle}</Text>
            {props.moreInformation &&
              props.moreInformation.map((item: LinkProps, index: number) => (
                <ListItem key={index}>
                  <Link marginRight="0.25rem" isExternal={item.isExternal} href={item.url}>
                    {item.title}
                  </Link>
                  {item.isExternal && <ExternalWhite style={{ height: '1rem', display: 'inline' }} />}{' '}
                </ListItem>
              ))}
          </List>
        </Flex>
      </MainContainer>
    </Box>
  );
};

export default Footer;
