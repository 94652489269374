import React from 'react';
import OrangeAlert from '../../../../images/OrangeAlert.svg';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../../../reducers/errorHandling';
import {
  Box,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

export const CloneModal = (props) => {
  const dispatch = useDispatch();

  const onConfirmClone = () => {
    request(appConstants.API_CLONE + props.caseID, 'post')
      .then((response) => {
        props.onConfirmSuccess(response.data);
        props.onClose();
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  return (
    <Modal data-testid="modal" isOpen={props.show} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent data-testid="modal" px={{ base: 4, lg: 16 }} maxW="70%">
        <ModalHeader ps="0" pt={{ base: '2rem', lg: '4rem' }} pb="0.7rem" border={0} m={0}>
          <Box as="h2">{props.header}</Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box bg="verylightblue" p={{ base: 2, lg: 12 }}>
            <Box as="h3">{props.infoboxContent.headingOne}</Box>
            <Box pb="1rem" fontWeight="light">
              {props.infoboxContent.subheadingOne}
            </Box>
            <Box as="h3">{props.infoboxContent.headingTwo}</Box>
            <Box pb="0.5rem" fontWeight="light">
              {props.infoboxContent.subheadingTwo}
            </Box>
            <Box display="flex" py="1rem" gap={4} alignItems="start">
              <Image src={OrangeAlert} height={14} alt="" />
              <Box fontWeight="light" dangerouslySetInnerHTML={{ __html: props.infoboxContent.alert }} />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter pb="4rem" px={0} justifyContent="space-between">
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="primary" fontSize="regular" onClick={onConfirmClone}>
            Clone and Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
