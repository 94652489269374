import { getAuthURL, getCurrentEnv } from '../helper/getOtherAppURL';

export function getURLWithEncoded(ssoURL, pathName, currentDomain) {
  if (window.location.pathname !== '/') {
    const url = ssoURL + '?callbackurl=' + encodeURIComponent(currentDomain + pathName);
    return url;
  } else {
    return ssoURL;
  }
}

export const redirectSSOCurrentURL = async () => {
  try {
    const { pathname: currentpageURL } = window.location;
    const loginURL = getAuthURL();
    const finalURL = getURLWithEncoded(loginURL, currentpageURL, getCurrentEnv);
    window.location.href = finalURL;
  } catch (error) {
    console.error('Error:', error);
  }
};
