import { Box, Flex, Grid, GridItem, Heading, Show, Spacer, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../ServiceUI/Core/Pagination/Pagination';
import { Tooltip } from '../../../ServiceUI/Core/Tooltip/Tooltip';
import { SR } from '../types';
import { SRItem } from './SRItem';
import { SortButton } from '../../../ServiceUI/Core/SortButton/SortButton';
import { requestSorter } from '../../../../helper/client/requestFuncs';

export interface SRDashboardProps {
  heading: string;
  tooltipContent?: string;
  noActiveRequestsMessage: string;
  srs: SR[];
}

export const SRDashBoard: React.FC<SRDashboardProps> = (props: SRDashboardProps) => {
  //Sort function states
  const [sort, setSort] = useState('pxUpdateDateTime');
  const [requests, setRequests] = useState(requestSorter(props.srs, sort));

  //Handling direction sort
  const changeDirection = () => {
    setRequests([...requests].reverse());
  };

  //Recalculating requests order when sort state changes
  useEffect(() => {
    setRequests(requestSorter(props.srs, sort));
  }, [props.srs, sort]);

  return (
    <>
      <Box data-testid="sr-dashboard" py="3.5rem">
        <Flex alignItems="center" gap={1}>
          <Heading as="h2" size="sm" fontWeight="medium" m={0}>
            {props.heading}
          </Heading>
          {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
        </Flex>

        {requests?.length > 0 ? (
          <>
            <Grid templateColumns={{ lg: '4fr 3fr 2fr 3fr' }} alignItems="end">
              <Spacer as={GridItem} colSpan={1} />
              <Show above="lg">
                <Text m={0} color="charcoalgrey" fontWeight="light" fontSize="xxs">
                  Location
                </Text>
                <Text m={0} color="charcoalgrey" fontWeight="light" fontSize="xxs">
                  Date submitted
                </Text>
              </Show>
              <Box alignItems="center" as={Flex} justifyContent="end">
                <SortButton
                  isSRSort={true}
                  display={{ lg: 'flex', base: 'flex' }}
                  text="Sort"
                  changeDirection={changeDirection}
                  changeSort={(sort) => {
                    setSort(sort);
                  }}
                  dropdownItems={[
                    { title: 'Location', property: 'pxPages.SRIndex.Name' },
                    { title: 'Title', property: 'pxPages.SRIndex.RequestTitle' },
                    { title: 'Date Submitted', property: 'pxUpdateDateTime' },
                  ]}
                />
              </Box>
            </Grid>

            <Pagination itemsPerPage={6}>
              {requests?.map((srItem: SR, key: number) => (
                <SRItem {...srItem} key={key} />
              ))}
            </Pagination>
          </>
        ) : (
          <Text fontWeight="light" mt="1rem">
            {props.noActiveRequestsMessage}
          </Text>
        )}
      </Box>
    </>
  );
};
