import React from 'react';
import { Link } from '@chakra-ui/react';
import { Arrow_Orange as Arrow } from '../icons/Outline';
import { Link as RouterLink } from 'react-router-dom';

interface CardLinkProps {
  href?: string;
  text: string;
  onClick?: () => void;
  router?: boolean;
}

export const CardLink: React.FC<CardLinkProps> = (props: CardLinkProps): JSX.Element => {
  return (
    <Link
      as={props.router ? RouterLink : Link}
      //@ts-expect-error
      to={props.router && props.href}
      onClick={props.onClick}
      href={props.href}
      display="flex"
      alignItems="center"
      w="auto"
      variant="card"
      fill="orange.standard"
    >
      {props.text}
      <Arrow height="1rem" fill="inherit" />
    </Link>
  );
};
