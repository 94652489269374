import { getSelectedDescription } from '../pages/EFT/Helper/eftHelperFunc';
import { request } from './useAxios';
import { appConstants } from './client/constant';

export const getRefData = (value: string, selectedCode: string) => {
  return new Promise((resolve, reject) => {
    request(appConstants.API_REF_DATA, 'get', { id: value })
      .then((res) => {
        let selectedDescription = 'Select';
        if (!!res.data) {
          selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
          resolve(selectedDescription);
        }
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
};
