import React, { useEffect } from 'react';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { Text, Heading, UnorderedList, ListItem } from '@chakra-ui/react';
import content from './Content/LearnMore.json';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';

export const LearnMorePage = () => {
  useEffect(() => {
    document.title = 'Learn more';
  }, []);
  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <Heading as="h2" fontSize="md" fontWeight="medium" mt="1rem">
          Digital Service Delivery Program
        </Heading>
        <Heading as="h2" fontSize="sm" fontWeight="medium" mt="1rem">
          Digital transformation
        </Heading>
        <Text mt="1rem">
          As part of the enduring Defence Transformation System, the vision for ServiceConnect is to transform and
          modernise access to services across the department.
        </Text>
        <Text mt="1rem">
          ServiceConnect and its key services, including My Service Request, My Forms and My Base, are being delivered
          under the <b>Digital Service Delivery Program.</b>
        </Text>

        <Text mt="1rem">
          The <b>Digital Service Delivery Program</b> conducts extensive research across Defence to understand where
          existing administrative and transactional services can be modernised and transformed into new, digital
          experiences.
        </Text>
        <Text mt="1rem">
          By digitising Defence services, the program aims to:
          <UnorderedList>
            <ListItem>Modernise traditional service delivery across Defence;</ListItem>
            <ListItem>Improve the customer experience;</ListItem>
            <ListItem>Reduce the need for administrative processing; and</ListItem>
            <ListItem>Improve data quality across Defence.</ListItem>
          </UnorderedList>
        </Text>
        <Text mt="0.5rem">
          This program operates under the Directorate of Digital Service Delivery (DDSD) within Security and Estate
          Group (SEG), and it is supported by representatives across the ADF, APS and Defence industry partners. The{' '}
          <b>Digital Service Delivery Program</b> is an iterative program, meaning that more services and features will
          be researched and delivered over time. To learn more, visit the Digital Service Delivery Program page on
          DRNet.
        </Text>
      </SubContainer>
    </>
  );
};
