import React from 'react';
import { Image, Flex, Button, useDisclosure, Link } from '@chakra-ui/react';
import { Chevron_Orange as Chevron } from '../icons/Outline';
import { SubNavigationProps } from './SubNavigation';
import ServiceUIDrawer from '../Drawer/ServiceUIDrawer';
import MenuLinks from '../MenuLinks/MenuLinks';
import { Link as ReactRouterLink } from 'react-router-dom';

const SubNavigationMobile = (props: SubNavigationProps): JSX.Element => {
  const { isOpen: menuOpen, onToggle: menuToggle } = useDisclosure();
  return (
    <>
      <Flex padding="0 1rem" alignItems="center" justify="space-between" h="80px" bg="white">
        <Link h="40%" as={ReactRouterLink} to={props.homeURL} data-testid="homeLink">
          <Image src={require(`../../../../images/${props.logoURL}`)} h="100%" alt={props.logoAltText} />
        </Link>
        <Button
          size="menu"
          aria-label={`${menuOpen ? 'Close' : 'Open'} menu`}
          onClick={menuToggle}
          variant="mobileMenu"
        >
          <Chevron style={{ height: '12px' }} className={menuOpen ? 'rotate' : ''} />
        </Button>
      </Flex>
      <ServiceUIDrawer open={menuOpen} direction="top" bg="white" zIndex={100}>
        <MenuLinks variant="subNav" router={true} navigationItems={props.navigationItems ?? []} />
      </ServiceUIDrawer>
    </>
  );
};
export default SubNavigationMobile;
