import React from 'react';
import { Flex, GridItem, Link, Text } from '@chakra-ui/react';
import { convertBytesToMB } from '../../../../helper/convertBytesToMB';
import Download from '../../Core/icons/Outline/Download';
import { PegaFile } from '../types';
import { Badge } from '../../Core/Badge/Badge';
import { removeFileExtension } from '../../../../helper/removeFileExtension';

interface DownloadableFileItemProps {
  file: PegaFile;
  isDownloaded?: Boolean | undefined;
  hasError?: Boolean | undefined;
  onClick(file: PegaFile): void;
}

export const DownloadableFileItem: React.FC<DownloadableFileItemProps> = ({
  file,
  isDownloaded,
  hasError,
  onClick,
}: DownloadableFileItemProps): JSX.Element => {
  return (
    <GridItem as={Flex} gap={2} mt="1rem" data-testid="downloadable-file-item">
      <Download />
      <Flex flexDir="column">
        <Link lineHeight={1} fontSize="xxs" onClick={() => onClick(file)}>
          {removeFileExtension(file.fileName) ?? ''}
        </Link>
        <Link hidden id="download-attachment" />
        <Text fontSize="xxs" m={0} fontWeight="medium" textTransform="uppercase">
          {file.extension} | {convertBytesToMB(file.fileSizeinbytes).toFixed(2)}mb
        </Text>
        <span>
          {isDownloaded && <Badge status="Downloaded" />}
          {hasError && <Badge status="Error" />}
        </span>
      </Flex>
    </GridItem>
  );
};
