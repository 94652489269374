import React from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue, setAcknowledgementCheckbox } from '../../../reducers/eftRequestDetailFormReducer';
const FormCheckbox = ({
  fieldID,
  label,
  required,
  isInvalid,
  fieldPropMapping,
  visible,
  reference,
  value,
  formData,
  readOnly,
}) => {
  const dispatch = useDispatch();

  const dispatchFieldValue = (value) => {
    // else
    if (reference === 'PersonalInfoRequest.Acknowledged') {
      dispatch(
        setAcknowledgementCheckbox({
          reference,
          value: value,
        }),
      );
    } else {
      dispatch(
        setFieldValue({
          reference,
          value: value,
          formData,
        }),
      );
    }
  };

  return (
    <SCForm.Checkbox
      disabled={readOnly}
      isInvalid={isInvalid}
      visible={visible}
      label={label}
      value={value}
      required={required}
      reference={reference}
      validation={fieldPropMapping[fieldID]?.validation}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      onCheckboxChange={(e) => {
        dispatchFieldValue(e);
      }}
    />
  );
};

export default FormCheckbox;
