import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Heading,
  RadioGroup,
  Radio,
  FormLabel,
  Grid,
  GridItem,
  FormControl,
} from '@chakra-ui/react';
import { SCForm } from '../FormElements';
import InfoBox from '../InfoBox/InfoBox';
import { V_Sad, sad, Neutral, happy, V_Happy, Alert, Success as SuccessIcon } from '../icons/Outline';
import { useForm, FormProvider } from 'react-hook-form';
import { ModalProps } from './Feedback';

const Radios = [
  { value: { title: 'Very Poor', value: '1' }, color: 'red', icon: V_Sad },
  { value: { title: 'Poor', value: '2' }, color: 'orange', icon: sad },
  { value: { title: 'Neutral', value: '3' }, color: 'blue', icon: Neutral },
  { value: { title: 'Good', value: '4' }, color: 'cyan', icon: happy },
  { value: { title: 'Very Good', value: '5' }, color: 'green', icon: V_Happy },
];

export interface Extras {
  isOpen: boolean;
  onClose(): void;
}

export type PropUnion = ModalProps & Extras;

const FeedbackModal: React.FC<PropUnion> = (props: PropUnion): JSX.Element => {
  const methods = useForm();
  const submitFeedback = () => {
    const data = {
      service: props.services.find((item) => item.value === service)?.value,
      rating: rating,
      detail: review,
    };
    props.submitAction &&
      props
        .submitAction(data)
        .then(() => setSubmitted(true))
        .catch(() => setError(true));
  };

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [service, setService] = useState(props.selectedService);
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const closeAction = () => {
    setSubmitted(false);
    setError(false);
    setService(props.services[0].value);
    setRating('');
    setReview('');
    props.onClose();
  };
  const Success = () => {
    return (
      <>
        <ModalHeader>
          <SuccessIcon height="4rem" />
          <Heading as="h2" fontSize="lg" fontWeight="medium" mt="0.5rem">
            {props.thankYouHeading}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text
            mb="0.5rem"
            fontWeight="light"
            dangerouslySetInnerHTML={{
              __html: props.thankYouDescription,
            }}
          />
          <InfoBox>
            <Heading as="h1" fontSize="sm" fontWeight="medium">
              {props.usageHeading}
            </Heading>
            <Text fontWeight="light">{props.usageContent}</Text>
          </InfoBox>
        </ModalBody>
      </>
    );
  };

  const Error = () => {
    return (
      <>
        <ModalHeader>
          <Alert height="4rem" />
          <Heading as="h2" fontSize="lg" fontWeight="medium" mt="0.5rem">
            {props.errorHeading}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text fontWeight="light">{props.errorDescription}</Text>
        </ModalBody>
      </>
    );
  };
  return (
    <FormProvider {...methods}>
      <Modal
        isCentered
        data-testid="feedback-modal"
        motionPreset="none"
        variant="feedback"
        size="xlarge"
        isOpen={props.isOpen}
        closeOnOverlayClick={false}
        onClose={closeAction}
      >
        <ModalOverlay />
        <ModalContent padding={{ lg: '2rem', base: '1rem' }}>
          {!submitted && !error && (
            <>
              <ModalHeader as="h2" fontWeight="medium" fontSize={{ lg: 'lg', base: 'sm' }}>
                {props.modalHeading}
              </ModalHeader>
              <ModalBody>
                <Text mb="1rem" fontWeight="light">
                  {props.modalDescription}
                </Text>
                <SCForm.Select
                  label={props.serviceHeading}
                  desktopWidth={12}
                  visible={true}
                  onSelect={(e) => setService(e)}
                  options={props.services}
                  value={service}
                />
                <FormControl as="fieldset">
                  <FormLabel as="legend" mb="1rem">
                    {props.ratingHeading} <>{props.services.find((item) => item.value === service)?.value ?? ''}</>?
                  </FormLabel>
                  <RadioGroup onChange={setRating} value={rating}>
                    <Grid
                      gridTemplateColumns={{
                        lg: 'repeat(5,1fr)',
                        base: 'repeat(2,1fr)',
                      }}
                      gap={3}
                      mb="1rem"
                    >
                      {Radios.map((item, idx) => {
                        const Icon = item.icon;
                        return (
                          <GridItem colSpan={1} textAlign="center" key={item.value.title}>
                            <Radio
                              w={{ md: '75%', base: '100%' }}
                              variant="smile"
                              value={item.value.value}
                              backgroundColor={`smile_${item.color} !important`}
                              mb="2rem"
                            >
                              {Icon && <Icon />}
                              <Text m="0" textAlign="center" mt="1rem" fontSize="regular">
                                {item.value.title}
                              </Text>
                            </Radio>
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
                <SCForm.TextArea
                  desktopWidth={12}
                  label={props.reviewHeading}
                  value={review}
                  visible={true}
                  onBlur={(e) => setReview(e)}
                />
                <Text fontWeight="light" dangerouslySetInnerHTML={{ __html: props.disclaimer }} />
              </ModalBody>
            </>
          )}
          {submitted && <Success />}
          {error && <Error />}
          <ModalFooter
            justifyContent={!submitted && !error ? 'space-between' : 'flex-end'}
            gap={{ lg: 0, base: 2 }}
            flexDirection={{ lg: 'row', base: 'column' }}
          >
            <Button
              w={{ lg: 'auto', base: '100%' }}
              onClick={closeAction}
              variant={submitted ? 'primary' : 'secondary'}
            >
              {props.closeCTAText}
            </Button>
            {!submitted && !error && (
              <Button
                w={{ lg: 'auto', base: '100%' }}
                variant="primary"
                onClick={submitFeedback}
                isDisabled={review === '' || rating === ''}
              >
                {props.submitCTAText}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};

export default FeedbackModal;
