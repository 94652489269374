import { Button, Flex, Grid, GridItem, Heading, Show, Text, Box } from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import { PIR } from '../types';
import { CardLink } from '../../Core/Card/CardLink';
import { PIRStatuses } from '../types';

export type PIRItemProps = PIR;

const DownloadableTypes: PIRStatuses[] = [
  'Pending-Download',
  'Pending-Completion',
  'Pending-Completion-Partial',
  'Pending-Expiration',
  'Pending-Closure',
];
const submittedType: PIRStatuses = 'Pending-Fulfillment';
const draftedType: PIRStatuses = 'Pending-Review';
const NewType: PIRStatuses = 'New';

export const PIRItem: React.FC<PIRItemProps> = (props: PIRItemProps) => {
  const navigate = useNavigate();
  const drafted = draftedType === props.pyStatusWork;

  const getPage = () => {
    if (DownloadableTypes.includes(props.pyStatusWork)) {
      return 'doc-ready';
    }
    switch (props.pyStatusWork) {
      case NewType:
        return 'new';
      case submittedType:
        return 'view';
    }
  };

  const getLink = () => {
    const page = getPage();
    const path = props.pyStatusWork === submittedType ? `?CASEID=${props.pzInsKey}` : `/${props.pzInsKey}`;
    const link = `/personal-information-request/${page}${path}`;
    return link;
  };

  const continueRequest = () => {
    navigate(
      '/personal-information-request/review/' + props.pxGotoStage, // going review page
      {
        state: [
          {
            caseId: { caseId: props.pzInsKey },
          },
        ],
      },
    );
  };
  const draftable: Boolean = props.pyStatusWork === 'Pending-Review' || props.pyStatusWork === 'New';
  const buttonText = draftable ? 'Continue' : 'View';

  const submittedDate = props.pxPages.IndexPIR.SubmissionDateTime
    ? new Date(props.pxPages.IndexPIR.SubmissionDateTime).toDateString()
    : '';

  return (
    <Grid
      data-testid="pir-item"
      borderRadius="0.25rem"
      bg="white"
      p="1.25rem"
      gridTemplateColumns="repeat(5,1fr)"
      mt="0.5rem"
      gap={3}
    >
      <GridItem colSpan={{ lg: 3, base: 5 }} as={Flex} alignItems="flex-start" flexWrap="wrap" flexDir="column" gap={2}>
        {/* @ts-ignore */}
        <PegaCaseBadge status={props.pyStatusWork} />
        <Heading as="h3" m={0} fontSize="regular" fontWeight="light">
          {props.pyTitle ?? 'Untitled request'}
        </Heading>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 3 }} as={Flex} alignItems="center">
        <Text m={0} fontWeight="light" color="darkgrey">
          <Show below="lg">Submitted: </Show>
          {submittedDate}
        </Text>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 2 }} as={Flex} justifyContent="flex-end" alignItems="center">
        {drafted ? (
          <Button
            display={{ lg: 'flex', base: 'none' }}
            onClick={() => continueRequest()}
            variant="secondary"
            data-testid="action-request"
          >
            {buttonText}
          </Button>
        ) : (
          <Button
            display={{ lg: 'flex', base: 'none' }}
            data-testid="action-request"
            variant="secondary"
            as={Link}
            to={getLink()}
          >
            {buttonText}
          </Button>
        )}
        <Show below="lg">
          <CardLink
            text={buttonText}
            href={!drafted ? getLink() : undefined}
            onClick={() => continueRequest()}
            data-testid="action-request"
          />
        </Show>
      </GridItem>
    </Grid>
  );
};
