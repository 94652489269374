import React from 'react';
import { Card, Flex, Text, CardHeader, CardBody, CardFooter, Link, Heading } from '@chakra-ui/react';
import { CardLink } from '../../Core/Card/CardLink';
import Bin from '../../Core/icons/Outline/Bin';
import { Badge } from '../../Core/Badge/Badge';

export interface NotificationItem {
  context?: string;
  createdBy?: string;
  createTime: string;
  description?: string;
  ID?: string;
  message: string;
  pxObjClass?: string;
  status?: string;
  tags: string[];
  link?: string;
  onDelete?(type: string, id?: string): void;
  onMarkAsRead?(type: string, flag?: boolean, id?: string): void;
  onView?(id?: string): void;
}

export interface NotificationProps extends NotificationItem {
  variant?: 'AEM' | 'PEGA' | {};
}

export const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
  const IS_READ = props.status !== 'UNREAD';
  const TAGS = props.tags?.filter((item) => item !== '') ?? [];
  return (
    <Card
      data-testid="notification"
      borderRadius={0}
      padding="2rem"
      bg={IS_READ ? 'white' : 'whispergrey'}
      marginTop="1rem"
      borderTop={IS_READ ? '2px solid grey' : '2px solid hidden'}
      borderStyle="dotted"
      boxShadow="none"
      borderColor="grey"
    >
      <CardHeader padding="none">
        <Flex justifyContent="space-between" flexDir={{ lg: 'row', base: 'column-reverse' }}>
          <Flex gap={2} mb="0.5rem">
            {TAGS.length > 0 && (
              <Flex gap={1}>
                {TAGS.map((value: string, key: number) => (
                  <Badge key={key} status={value} bgOverride="transparent" />
                ))}
              </Flex>
            )}
            {props.variant === 'PEGA' && (
              <Link
                fontSize="xs"
                role="button"
                display="flex"
                alignItems="center"
                onClick={() => props.onMarkAsRead!('single', !IS_READ, props.ID)}
              >
                Mark as {IS_READ ? 'unread' : 'read'}
              </Link>
            )}
          </Flex>
          <Text>{new Date(props.createTime).toLocaleDateString()}</Text>
        </Flex>
        <Heading mt="0.5rem" as="h4" fontWeight="medium" fontSize="sm" data-testid="heading">
          {props.description}
        </Heading>
      </CardHeader>
      <CardBody
        mb="0.5rem"
        padding="none"
        w="90%"
        data-testid="description"
        fontWeight="light"
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      {props.variant === 'PEGA' ? (
        <CardFooter padding="none" display="flex" justifyContent="space-between">
          <Link
            role="button"
            display="flex"
            alignItems="center"
            gap={1}
            onClick={() => props.onDelete && props.onDelete('single', props.ID)}
          >
            <Bin height="1rem" />
            Delete
          </Link>
          <CardLink onClick={() => props.onView && props.onView(props.ID)} text="View" />
        </CardFooter>
      ) : (
        <CardFooter padding="none" display="flex" justifyContent="flex-end">
          <CardLink href={props.link} text="Open form" />
        </CardFooter>
      )}
    </Card>
  );
};
