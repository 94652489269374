import React from 'react';
import { Link as ChakraLink, chakra, Badge, Box } from '@chakra-ui/react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { Home } from '../icons/Outline';

export interface NavItemProps {
  url: string;
  title: string;
  router?: boolean;
  variant?: 'mainNav' | 'subNav';
  isHome?: boolean;
  notificationBubble?: boolean;
  notificationCount?: number;
  close?(): void;
  extraRoutes?: string;
}

const Navigation = chakra(Link);

const style = (variant, active) => {
  return {
    fontWeight: 'light',
    fontSize: 'regular',
    bg: active ? 'orange.standard' : variant === 'mainNav' ? 'charcoal' : 'white',
    color: variant === 'mainNav' ? 'white' : 'black',
    display: 'flex',
    gap: 1,
    alignContent: 'center',
    textDecoration: 'none',
    width: { lg: 'auto', base: '100%' },
    alignItems: 'center',
    padding: '1rem',
    fill: 'navy',
    _activeLink: { color: 'white', bg: variant === 'mainNav' ? 'orange.standard' : 'navy', fill: 'white' },
    _hover: {
      color: 'white',
      bg: variant === 'mainNav' ? 'orange.standard' : 'navy',
      fill: 'white',
    },
  };
};

const LinkContent = (props: NavItemProps) => {
  return (
    <>
      <Box>
        {props.isHome === true && <Home fill="inherit" />}
        {props.title}
      </Box>
      {props.notificationBubble && props.notificationCount && props.notificationCount > 0 ? (
        <Badge
          position="absolute"
          p="0.25rem"
          justifyContent="center"
          display="flex"
          fontSize="xxs"
          borderRadius="16px"
          height="1rem"
          width="fit-content"
          alignItems="center"
          color="orange.standard"
          mb={{ lg: '1.5rem' }}
          right="8px"
        >
          {props.notificationCount}
        </Badge>
      ) : null}
    </>
  );
};

export const NavItem: React.FC<NavItemProps> = (props: NavItemProps): JSX.Element => {
  const location = useLocation().pathname;
  return props.router ? (
    <Navigation
      position="relative"
      {...style(props.variant, location === props.extraRoutes)}
      to={props.url}
      end
      data-testid="navlink"
      justifyContent="space-between"
      onClick={props.close}
    >
      <LinkContent {...props} />
    </Navigation>
  ) : (
    <ChakraLink {...style(props.variant, location === props.extraRoutes)} href={props.url}>
      <LinkContent {...props} />
    </ChakraLink>
  );
};
