import * as React from 'react';
import { SVGProps } from 'react';
const Bin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g id="Bin" clipPath="url(#clip0_409_9)">
      <g id="np_trash_3367700_000000">
        <path
          id="Path 1756"
          fill="#454545"
          d="M15.4664 1.88313H0.533586C0.461428 1.88005 0.389314 1.88993 0.321613 1.91217C0.253911 1.93441 0.192034 1.96855 0.139732 2.01252C0.0874293 2.05649 0.045791 2.10937 0.0173406 2.16796C-0.0111099 2.22654 -0.0257797 2.28962 -0.0257797 2.35336C-0.0257797 2.4171 -0.0111099 2.48017 0.0173406 2.53876C0.045791 2.59735 0.0874293 2.65023 0.139732 2.6942C0.192034 2.73817 0.253911 2.77231 0.321613 2.79455C0.389314 2.81679 0.461428 2.82667 0.533586 2.82359H2.13309V13.6472C2.13309 13.9562 2.20206 14.2621 2.33607 14.5476C2.47009 14.833 2.66651 15.0924 2.91413 15.3109C3.16176 15.5294 3.45573 15.7027 3.77927 15.8209C4.1028 15.9391 4.44956 16 4.79975 16H11.2003C11.5504 16 11.8972 15.9391 12.2207 15.8209C12.5443 15.7027 12.8382 15.5294 13.0859 15.3109C13.3335 15.0924 13.5299 14.833 13.6639 14.5476C13.7979 14.2621 13.8669 13.9562 13.8669 13.6472V2.82359H15.4664C15.6033 2.81775 15.7324 2.76566 15.8269 2.67811C15.9215 2.59056 15.9742 2.47428 15.9742 2.35336C15.9742 2.23244 15.9215 2.11615 15.8269 2.02861C15.7324 1.94106 15.6033 1.88897 15.4664 1.88313ZM12.7998 13.6472C12.8004 13.8329 12.7595 14.0169 12.6794 14.1886C12.5993 14.3603 12.4816 14.5164 12.333 14.6479C12.1844 14.7794 12.0079 14.8838 11.8135 14.955C11.6191 15.0262 11.4107 15.0628 11.2003 15.0628H4.79975C4.37554 15.0628 3.9687 14.9141 3.66874 14.6495C3.36877 14.3848 3.20025 14.0259 3.20025 13.6516V2.82799H12.7998V13.6472Z"
        />
        <path
          id="Path 1757"
          fill="#454545"
          d="M5.86691 0.941565H10.1331C10.2746 0.941565 10.4103 0.891965 10.5104 0.803676C10.6105 0.715388 10.6667 0.595642 10.6667 0.470783C10.6667 0.345924 10.6105 0.226178 10.5104 0.137889C10.4103 0.0496005 10.2746 0 10.1331 0H5.86816C5.72665 0 5.59093 0.0496005 5.49086 0.137889C5.3908 0.226178 5.33458 0.345924 5.33458 0.470783C5.33458 0.595642 5.3908 0.715388 5.49086 0.803676C5.59093 0.891965 5.72665 0.941565 5.86816 0.941565H5.86691Z"
        />
        <path
          id="Path 1758"
          fill="#454545"
          d="M6.40549 12.2348C6.4756 12.235 6.54506 12.2229 6.60987 12.1993C6.67468 12.1757 6.73357 12.141 6.78315 12.0973C6.83273 12.0535 6.87203 12.0016 6.89879 11.9444C6.92555 11.8872 6.93923 11.8259 6.93907 11.7641V6.11797C6.94256 6.05431 6.93136 5.99068 6.90616 5.93095C6.88095 5.87122 6.84225 5.81662 6.79242 5.77048C6.74258 5.72433 6.68265 5.68759 6.61625 5.66249C6.54984 5.63739 6.47835 5.62444 6.40611 5.62444C6.33387 5.62444 6.26238 5.63739 6.19598 5.66249C6.12957 5.68759 6.06964 5.72433 6.0198 5.77048C5.96997 5.81662 5.93127 5.87122 5.90607 5.93095C5.88086 5.99068 5.86966 6.05431 5.87315 6.11797V11.7652C5.87299 11.827 5.88668 11.8883 5.91343 11.9455C5.94019 12.0027 5.97949 12.0546 6.02907 12.0984C6.07865 12.1421 6.13754 12.1768 6.20235 12.2004C6.26716 12.224 6.33662 12.2361 6.40674 12.2359L6.40549 12.2348Z"
        />
        <path
          id="Path 1759"
          fill="#454545"
          d="M9.60573 12.2348C9.67585 12.235 9.74531 12.2229 9.81012 12.1993C9.87493 12.1757 9.93382 12.141 9.9834 12.0973C10.033 12.0535 10.0723 12.0016 10.099 11.9444C10.1258 11.8872 10.1395 11.8259 10.1393 11.7641V6.11797C10.1428 6.05431 10.1316 5.99068 10.1064 5.93095C10.0812 5.87122 10.0425 5.81662 9.99267 5.77048C9.94283 5.72433 9.8829 5.68759 9.81649 5.66249C9.75009 5.63739 9.6786 5.62444 9.60636 5.62444C9.53411 5.62444 9.46263 5.63739 9.39622 5.66249C9.32982 5.68759 9.26989 5.72433 9.22005 5.77048C9.17022 5.81662 9.13152 5.87122 9.10631 5.93095C9.08111 5.99068 9.06991 6.05431 9.0734 6.11797V11.7652C9.07324 11.827 9.08692 11.8883 9.11368 11.9455C9.14044 12.0027 9.17974 12.0546 9.22932 12.0984C9.2789 12.1421 9.33779 12.1768 9.4026 12.2004C9.46741 12.224 9.53687 12.2361 9.60698 12.2359L9.60573 12.2348Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_409_9">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Bin;
