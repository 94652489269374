import axios from 'axios';
import { getApiUrl } from '../../../helper/getOtherAppURL';
import { formDataTransform } from '../Helper/formDataTransform';
import { appConstants } from '../../../helper/client/constant';

export const refreshEFTForm = async (fields, nextAssignmentID, actionId, viewID, refreshID) => {
  const apiUrl = getApiUrl;

  const URL = apiUrl + appConstants.API_ASSIGNMENTS;
  const data = {
    content: {},
    pageInstructions: fields,
  };
  try {
    const res = await axios.put(
      `${URL}${nextAssignmentID}/actions/${actionId}/refresh${refreshID ? `?refreshFor=${refreshID}` : ''}`,
      data,
      {
        withCredentials: true,
      },
    );
    const fieldList = formDataTransform(res);
    return fieldList;
  } catch (error) {
    // Handle error
  }
};
