// API service file
import { appConstants } from '../../../helper/client/constant';
import { request } from '../../../helper/useAxios';

const buildURL = (baseUrl, id, path = '') => `${baseUrl}${id}${path}`;

// Get Personal Info Request
export const getPersonalInfoRequest = (caseID) => {
  const URL = appConstants.API_CASES + caseID;
  return request(URL, 'get');
};

// Get Attachments
export const getAttachments = (caseID) => {
  const URL = appConstants.API_CASES + caseID + '/attachments';
  return request(URL, 'get');
};

// Edit Request
export const editRequest = (caseID) => {
  const URL = appConstants.API_EDIT_CASE + caseID;
  return request(URL, 'put');
};

// Forward Request
export const forwardEditRequest = (id, caseID) => {
  const URL = appConstants.API_ASSIGNMENTS + id + '?actionID=ReviewPIRInformation';
  return request(URL, 'post');
};

// Save PIR
export const savePIR = async (id: string, data: any) => {
  try {
    const res = await request(appConstants.API_CASES, 'get', { id });
    const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
    const config = {
      headers: {
        'if-match': finalETagValue,
      },
      withCredentials: true,
    };
    return await request(appConstants.API_CASES, 'put', { id: id, data, config });
  } catch (err) {
    throw err;
  }
};

// Delete PIR
export const deletePIR = async (id: string, actionID: string) => {
  const data = {
    content: {
      pyGotoStage: 'Withdrawn',
      pyAuditNote: 'User Withdrawn the Case',
    },
    pageInstructions: [],
  };

  try {
    const res = await request(appConstants.API_CASES, 'get', { id });
    const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
    const config = {
      headers: {
        'if-match': finalETagValue,
      },
      withCredentials: true,
    };
    return await request(appConstants.API_CASES, 'put', {
      id: id + '?actionID=pyChangeStage',
      data,
      config,
    });
  } catch (err) {
    throw err;
  }
};

// Submit PIR
export const submitPIR = (id) => {
  const data = {
    content: {},
    pageInstructions: [],
  };
  const URL = buildURL(appConstants.API_ASSIGNMENTS, id, '?actionID=ReviewPIRInformation');
  return request(URL, 'post', { data });
};

// Get SR Data
export const getSRData = (caseID) => {
  const URL = appConstants.API_CASES + caseID;
  return request(URL, 'get');
};
