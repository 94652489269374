import { RecentItemProps } from '../components/ServiceUI/LandingPage/RecentItems/RecentItems';
import { appConstants } from './client/constant';
import { request } from './useAxios';

const getLink = (data: any): string => {
  const { pxPages, pyStatusWork, pzInsKey } = data;
  const RequestType =
    pxPages.SRIndex.ServiceType === appConstants.SERVICE_REQUEST_TYPE
      ? appConstants.SERVICE_REQUEST_TYPE_MINOR
      : appConstants.SERVICE_REQUEST_TYPE_FULL;
  switch (pyStatusWork) {
    case appConstants.NEW_SERVICE_REQUEST:
      return `/my-service-request/edit-service-request-create/${pzInsKey}?type=${RequestType}`;
    case appConstants.REVIEW_SERVICE_REQUEST:
      return `/my-service-request/edit-service-request-review/${pzInsKey}?type=${RequestType}`;
    default:
      return `/my-service-request/view-service-request/${pzInsKey}`;
  }
};

export const getRecentItems = async (): Promise<RecentItemProps[]> => {
  const response = await request(appConstants.API_RECENT_REQUEST, 'get');
  const recentItems: RecentItemProps[] = response.data.pxResults.map((item: any) => {
    const { RequestTitle: heading, WorkType, WorkSubType } = item.pxPages.SRIndex;
    const tags: string[] = [];
    if (WorkType !== 'Select' && WorkType !== undefined) {
      tags.push(WorkType);
    }
    if (WorkSubType !== undefined) {
      tags.push(WorkSubType);
    }
    const link = getLink(item);
    return { heading, tags, link };
  });
  return recentItems;
};
