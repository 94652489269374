import { NotificationItem } from '../../components/ServiceUI/LandingPage/NotificationsList/Notification';
import _ from 'lodash';

export const notificationSorter = (array: NotificationItem[], paramater: string) => {
  //This could be improved w.r.t handling date sorting
  const validItems = array.filter((item) => item[paramater]);
  const sortFunction = (a: NotificationItem, b: NotificationItem) => {
    const keyA = paramater === 'createTime' ? new Date(_.get(a, paramater)) : _.get(a, paramater);
    const keyB = paramater === 'createTime' ? new Date(_.get(b, paramater)) : _.get(b, paramater);
    return paramater === 'createTime' ? (keyA > keyB ? 1 : -1) : keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1;
  };
  validItems.sort(sortFunction);
  let returnValue = validItems.concat(array.filter((item) => !item[paramater]));
  return paramater !== 'createTime' ? returnValue : returnValue.reverse();
};

export const notificationFilterer = (notifications: NotificationItem[], filterer) => {
  const arrayToFilter = notifications;
  const filterFunction = (notification: NotificationItem) => {
    return notification.tags?.find((i) => i === filterer);
  };
  if (filterer !== '') {
    return arrayToFilter.filter(filterFunction);
  } else {
    return arrayToFilter;
  }
};
