import React, { useEffect } from 'react';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants } from '../../helper/client/constant';
import { request } from '../../helper/useAxios';
import { setShowError } from '../../reducers/errorHandling';

// Function to call API to kill the session
const useDeleteSessionAPI = () => {
  const dispatch = useDispatch();

  const deleteSession = () => {
    request(appConstants.API_LOGOUT_USER, 'delete').catch((error) =>
      dispatch(setShowError({ error: true, code: error?.response?.status, errorObj: error?.response })),
    );
  };
  return { deleteSession };
};

// Function to check if user is internal or external and handle logout
const logoutUser = (deleteSession, isInternal) => {
  deleteSession();
  // Get logout URL - assign logoutURL depending on env
  let logoutURL: string | undefined = '';
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
  ) {
    logoutURL = process.env.REACT_APP_LOGOUT;
  } else {
    if (isInternal) {
      logoutURL = process.env.REACT_APP_LOGOUT_INTERNAL;
    } else {
      logoutURL = process.env.REACT_APP_LOGOUT_EXTERNAL;
    }
  }
  // Check if logoutURL is valid and redirect user
  if (logoutURL) {
    window.location.href = logoutURL;
  }
};

const Logout: React.FC = () => {
  // Use selector to check internal/external from redux
  const isInternal = useSelector((state: { isInternal: boolean }) => state.isInternal);
  const { deleteSession } = useDeleteSessionAPI();

  useEffect(() => {
    logoutUser(deleteSession, isInternal);
  }, [deleteSession, isInternal]);

  return <Spinner overlay text="Logging out" opaqueOverlay />;
};

export default Logout;
