import React from 'react';
import { Checkbox, Flex, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FormElement, MultiProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { Text } from '@chakra-ui/react';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useFormContext } from 'react-hook-form';

export const Multi: React.FC<FormElement & MultiProps> = (props: FormElement & MultiProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the group to use for all checkbox erefrences
  const groupId = props.id || 'formsMulti';

  return props.visible ? (
    <PrimitiveControl
      disabled={props.disabled}
      required={props.required}
      desktopWidth={props.desktopWidth}
      isInvalid={!!errors[groupId]}
      minHeight="12rem"
    >
      {props.label ? (
        <Flex alignItems="center" gap={1} mb={2}>
          <FormLabel>{props.label}</FormLabel>
          {props.showTooltip && props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
        </Flex>
      ) : (
        <></>
      )}
      <Flex gap={3} justifyContent="flex-start" overflowX={{ lg: 'auto', base: 'scroll' }}>
        {props?.options?.map((item) => (
          <Checkbox
            h="auto"
            variant="multi"
            data-testid="checkbox"
            isChecked={Boolean(item.isChecked)}
            display="flex"
            alignItems="flex-start"
            key={item.key}
            disabled={item.isDisabled}
            // @ts-ignore
            inputProps={{ reference: [item.reference] }}
            // @ts-ignore
            {...register(groupId, {
              ...props?.validation,
              onChange: (e) => {
                // @ts-ignore
                const reference = e.target.getAttribute('reference');
                // @ts-ignore
                if (props.newFunction && reference) {
                  // @ts-ignore
                  props.newFunction(reference, e.target.checked);
                }
                // @ts-ignore
                props.onSelection(reference, e.target.checked);
              },
            })}
          >
            <Text fontWeight="bold" margin={0} whiteSpace="break-spaces">
              {item.label}
            </Text>
            {/* @ts-ignore */}
            {item.subtitle && <Text fontWeight="light"> {item.subtitle}</Text>}
          </Checkbox>
        ))}
      </Flex>
      <FormErrorMessage>
        <>{errors[groupId] && errors[groupId]?.message}</>
      </FormErrorMessage>
    </PrimitiveControl>
  ) : null;
};
