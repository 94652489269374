import React from 'react';
import {
  FormLabel,
  Menu,
  MenuList,
  MenuItem,
  Button,
  MenuButton,
  Box,
  FormErrorMessage,
  InputGroup,
} from '@chakra-ui/react';
import { FormElement, Option, SelectProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { Chevron_Orange as ChevronOrange } from '../../icons/Outline';

export const FormMenu: React.FC<FormElement & SelectProps> = (props: FormElement & SelectProps) => {
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={props.isInvalid}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
          data-testid="menu"
        >
          <InputGroup flexDirection="column" zIndex={1}>
            <FormLabel variant="primary">{props.label}</FormLabel>
            <Menu isLazy matchWidth gutter={0}>
              <MenuButton
                data-testid="menubutton"
                borderColor={props.isInvalid ? 'redalert' : ''}
                overflow="hidden"
                w="100%"
                as={Button}
                variant="menuButton"
                textAlign="left"
                rightIcon={<ChevronOrange height="0.75rem" />}
              >
                <Box overflow="clip">{props.value}</Box>
              </MenuButton>
              <MenuList transform="none !important" data-testid="dropdown" maxH="300px" overflowX="scroll">
                {props.options?.map((item: Option, key: number) => (
                  <MenuItem textAlign="left" key={key} onClick={() => props.onSelect(item)} data-testid="menuitem">
                    {item.value}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
          </InputGroup>
        </PrimitiveControl>
      )}
    </>
  );
};
