import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CardLink } from '../../Core/Card/CardLink';

export interface RecentItemProps {
  heading: string;
  tags: string[];
  link: string;
}

export interface RecentItemsProps {
  mainHeading: string;
  recentItems: RecentItemProps[];
  errorMessage: string;
}

export const RecentItem: React.FC<RecentItemProps> = (props: RecentItemProps) => {
  return (
    <Flex
      data-testid="recentItem"
      py="1rem"
      borderBottom="1px"
      _last={{ borderBottom: 'none' }}
      borderColor="mediumgrey"
      justifyContent="space-between"
    >
      <Flex justifyContent="flex-end" flexDir="column">
        <Text fontWeight="medium" fontSize="regular">
          {props.heading ?? 'Untitled'}
        </Text>
        {props.tags.length > 0 && (
          <Text fontWeight="light" whiteSpace="break-spaces">
            {props.tags.map((item) => `${item}; `)}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <CardLink href={props.link} text="View" />
      </Flex>
    </Flex>
  );
};

export const RecentItems: React.FC<RecentItemsProps> = (props: RecentItemsProps): JSX.Element => {
  return (
    <Box>
      <Heading as="h3" fontWeight="medium" fontSize="regular">
        {props.mainHeading}
      </Heading>
      {props.recentItems.length > 0 ? (
        <Box bg="white" px="1rem">
          {props.recentItems.slice(0, 5).map((item: RecentItemProps, i) => (
            <RecentItem {...item} key={`${item.heading}-${i}`} />
          ))}
        </Box>
      ) : (
        <Text>{props.errorMessage}</Text>
      )}
    </Box>
  );
};

export default RecentItems;
