import React from 'react';
import { Box, Heading, IconButton, Flex, Input } from '@chakra-ui/react';
import { defencetheme } from '../../../../theme/theme';
import { SubContainer } from '../../Core/Layout';
import { Search_White as SearchWhite } from '../../Core/icons/Outline';

const orange = defencetheme.colors.orange.standard;

export interface SearchBarProps {
  onSubmit?(value: string): void;
  searchTitle: string;
  placeholder: string;
  value?: string;
  setValue?(e: string): void;
}

export const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  const handleClick = () => {
    props.onSubmit && props.value && props.onSubmit(props.value);
  };
  return (
    <Box pb="2rem" background={`linear-gradient(to bottom, ${orange} 60%, transparent 50%)`}>
      <SubContainer>
        <Box borderRadius="0.5rem" bg="white" padding="3rem">
          <Heading as="h3" fontWeight="medium" fontSize="sm" marginBottom="0.5rem">
            {props.searchTitle}
          </Heading>
          <Flex alignItems="end">
            <Input
              variant="primary"
              value={props.value}
              placeholder={props.placeholder}
              onChange={(e) => props.setValue && props.setValue(e.target.value)}
              onKeyDown={(key) => key.key === 'Enter' && handleClick()}
            />
            <IconButton
              size="menu"
              px={0}
              w="44px"
              variant="primary"
              aria-label="Search"
              icon={<SearchWhite height={16} />}
              onClick={handleClick}
            />
          </Flex>
        </Box>
      </SubContainer>
    </Box>
  );
};
