import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import Header from '../../components/ServiceUI/Core/Header/Header';
import content from '../../pages/Core/Content/Privacy.json';
import React from 'react';

const Privacy = () => {
  useEffect(() => {
    document.title = 'Privacy';
  });
  return (
    <>
      <Header {...content.header} />
      <Box
        dangerouslySetInnerHTML={{
          __html: content.header.content,
        }}
      />
    </>
  );
};

export default Privacy;
