export const appConstants = {
  // APIS
  API_HEART_BEAT: '/prweb/app/sconn/api/Auth/v1/heartbeat',
  API_HEART_BEAT_DOD: '/prweb/app/dodauth/api/Auth/v1/heartbeat',
  API_TOKEN_DOD: '/prweb/app/dodauth/api/Auth/v1/token',
  API_IDENTITYLINK: '/prweb/app/sconn/api/Auth/v1/identitylink',
  API_VERIFY_IDENTITYLINK: '/prweb/api/MyGovId/v1/identitylink/verify/',
  API_USER_PROFILE_DETAILS: '/prweb/app/sconn/api/Profile/v1/personnel',
  API_RECENT_PIRS: '/prweb/app/sconn/api/DoDAPI/v1/data/D_RecentPIRList',
  API_FEEDBACK: '/prweb/app/sconn/api/SConn/v1/feedback',
  API_RECENT_REQUEST: '/prweb/app/sconn/api/DoDAPI/v1/data/D_RecentServiceRequestList',
  API_ASSIGNMENTS: '/prweb/app/sconn/api/v1/assignments/',
  API_ASSIGNMENTS_DOD: '/prweb/app/dodauth/api/v1/assignments/',
  API_CASES: '/prweb/app/sconn/api/v1/cases/',
  API_CASES_DOD: '/prweb/app/dodauth/api/v1/cases',
  API_EDIT_CASE: '/prweb/app/sconn/api/SConn/v1/editcase/',
  API_AUDIT_UPDATE: '/prweb/app/sconn/api/SConn/v1/auditcase/',
  API_REF_DATA: '/prweb/app/sconn/api/sconn/v1/getrefdata/',
  API_REF_DATA_GROUP: '/prweb/app/sconn/api/sconn/v1/getrefdata/groupdiv',
  API_ATTACHMENTS: '/prweb/app/sconn/api/sconn/v1/attachments/',
  API_ATTACHMENTS_FILE: '/prweb/app/sconn/api/v1/attachments/',
  API_ATTACHMENT_UPLOAD: '/prweb/app/sconn/api/v1/attachments/upload',
  API_CLONE: '/prweb/app/sconn/api/SConn/v1/clone/',
  API_SEARCH: '/content/defence/spaapi/search.globalsearch.json',
  API_MYACTIONS:
    '/content/defence-forms/my-forms/manage-forms/jcr:content/root/responsivegrid/my_actions.myactions.json',
  API_LOCATION: '/prweb/app/sconn/api/Profile/v1/personnel/location',
  API_LOCATION_DETAILS: '/prweb/app/sconn/api/sconn/v1/getrefdata/locationdetails',
  API_VALIDATE_CCC: '/prweb/app/sconn/api/sconn/v1/validateccc/',
  API_WORK_TYPE: '/prweb/app/sconn/api/SConn/v1/getrefdata/worksubtype',
  API_SERVICE_TYPE: '/prweb/app/sconn/api/SConn/v1/servicetype/',
  API_RESPONSE_TYPE: '/prweb/app/sconn/api/File/v1/responsetype/',
  API_NOTIFICATION_LIST: '/prweb/app/sconn/api/Notify/v1/notifications',
  API_BASE_LOCATION: '/prweb/app/sconn/api/sconn/v1/getrefdata/baselocationdetails',
  API_LOGOUT_USER: '/prweb/app/sconn/api/Auth/v1/revokesession',

  LOGOUT_LOWER_ENV_URL:
    'https://ucazuredefenceb2c.b2clogin.com/ucazuredefenceb2c.onmicrosoft.com/b2c_1a_dvtentra_signup_signin/oauth2/v2.0/logout',

  SR_WORK_TYPE: 'worktype',
  SR_SERVICE_TYPE: 'servicetype',
  // NOTE: Check different file type in here:
  //https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  SR_ALLOWED_FILE_EXT_IMAGE: ['png', 'jpeg'],
  SR_ALLOWED_FILE_EXT_TRANSPORT_WASTE: ['vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  SR_ALLOWED_FILE_EXT_SUPPORTING_DOC: [
    'vnd.ms-excel',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'png',
    'jpeg',
    'pdf',
  ],
  SR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING: ' xlsx, xls, png, jpeg or pdf',
  SR_ALLOWED_FILE_EXT_TRANSPORT_WASTE_STRING: ' xlsx, xls',
  SR_ALLOWED_FILE_EXT_IMAGE_STRING: ' jpeg, png',
  SR_FILE_SIZE_EXCEED_MSG: 'File size can not be more than 12MB.',
  SR_FILE_ERROR_TYPE_SIZE: 'Size',
  SR_FILE_SIZE_MAX_LIMIT: 12,
  SR_FILE_SIZE_INVALID_MSG: ' is invalid format.',
  SR_FILE_NAME_INVALID_MSG: ' exceeds 48 characters',
  SR_THE_ATTACHMENTS: 'The attachment ',
  SR_FILE_NAME_SPECIAL_CHARS_INVALID_NAME_MSG: ' cannot contain special characters',
  SR_FILE_ERROR_TYPE_FORMAT: 'file format',
  SR_FILE_ERROR_NAME_FORMAT: 'file name',
  SR_FILE_ERROR_NAME_SPECIAL_CHARS_FORMAT: 'file name special chars',
  SR_ATTACHMENT_TYPE: 'file',
  SR_ATTACHMENT_CATEGORY: 'File',
  SR_ATTACHMENT_FORM_DATA_KEY: 'content',

  //PROFILE
  PROFILE_LOCSELECT: 'Select',
  PROFILE_ERROR_NAME: 'Please enter given names(s).',
  PROFILE_ERROR_SURNAME: 'Please enter your surname.',
  PROFILE_ERROR_PH: 'Please enter your contact number.',
  PROFILE_INVALID_PH: 'Please provide valid phone number.',
  PROFILE_INVALID_MOBILE: 'Please provide valid mobile number.',
  PROFILE_ERROR_BASE: 'Please enter your base location.',
  PROFILE_ERROR_ALT_BASE: 'Please enter your alternate base.',
  PROFILE_ERROR_WORK_BASE: 'Please enter your working location.',
  PROFILE_ERROR_START_DATE: 'Please select start date',
  PROFILE_ERROR_END_DATE: 'Please select end date later than start date',
  PROFILE_ERROR_DOB: 'Please enter your date of birth.',
  PROFILE_INVALID_DOB: 'Please enter your date of birth.',
  PROFILE_ERROR_PMKEYS: 'Please enter your PMKeys number.',
  PROFILE_RECORD_FOUND: 'Record found in PMKeys so user is redirected to home page',
  PROFILE_RECORD_NOT_FOUND: 'Record not found in PMKeys',
  PROFILE_USERTYPE_CONTRACTOR: 'Contractor',
  PROFILE_DEFAULT_BASE: 'Russell Precinct',

  SR_GENERIC_ERROR: 'Something went wrong! Please try again later.',
  SR_ERROR_AUTH: 'Authentication Failed! Your session token has expired or is no longer active. Please relogin.',
  PHONE_NUM_MAX_LIMIT: 15,
  PHONE_NUM_MAX_LIMIT_MSG: 'Please provide valid phone number.',
  SR_PROFILE: '/profile',
  SR_PUBLIC_PORTAL: 'public',
  SR_VERIFY_LINK: 'verifyidentitylink',
  SC_PROFILE_PAGE: '/serviceconnect/Profile',
  SR_FLAG_FALSE: 'false',
  SR_FLAG_TRUE: 'true',
  PROFILE_PHONE_TYPE_WORK: 'Work',
  PROFILE_PHONE_TYPE_MOBILE: 'Mobile',
  PROFILE_PHONE_TYPE_HOME: 'Home',
  PAGE_INSTR_UPDATE: 'UPDATE',
  CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST: 'Transport Manifest',
  CATEGORY_TYPE_NAME_WASTE_MANIFEST: 'Waste Manifest',
  TEMPLATE_NAME_TRANSPORT_MANIFEST: 'Transport_Request_Template',
  TEMPLATE_NAME_WASTE_MANIFEST: 'Waste_Manifest_Template',
  FILE_PATH_TRANSPORT_MANIFEST: 'TransportManifest.xlsx',
  FILE_PATH_TRANSPORT_WASTE: 'WasteManifestForm.xlsx',
  REPOSITORY_NAME_TRANSPORT_MANIFEST: 'TransportManifest',
  REPOSITORY_NAME_WASTE_MANIFEST: 'wastemanagement',
  CONVERSION_BASE64__FILE_DEFAULT_URL: 'data:application/vnd.ms-excel;base64,',
  CATEGORY_IMAGE: 'Image',
  ATTACHMENT_TYPE_CORRESPONDENCE: 'Correspondence',
  ATTACHMENT_TYPE_OFFICIAL_DOC: 'OfficialDocuments',
  ATTACHMENT_TYPE_OFFICIAL_DOCS: [
    'OfficialDocuments',
    'OtherRecords',
    'MedicalRecords',
    'PsychologicalRecords',
    'PersonnelInformation',
    'StatementOfService',
    'Eulogy',
    'DisciplinaryRecords',
    'CertificateOfService',
    'ServiceReport',
  ],
  ATTACHMENTS_SPECIAL_CHARACTERS_STRING: "#%}{&<>*?/$!':@+`|=",
  ATTACHMENTS_FILE_CATEGORY: 'File',
  ATTACHMENTS_JSON_CATEGORY: 'JSON',
  ATTACHMENTS_FILESIZE_TOOLTIP:
    'You can attach PDF, JPG, PNG, XLS and XLSX files with a maximum combined size of 12 MB.',

  // cost center code message
  COST_CENTER_CODE_FOUND: 'Matches a stored active Cost Centre Code record.',
  COST_CENTER_CODE_NOT_FOUND: 'This does not match a stored active Cost Code Centre record.',

  // Service request View/Edit/Delete
  NEW_SERVICE_REQUEST: 'New',
  REVIEW_SERVICE_REQUEST: 'Pending-Review',
  SERVICE_REQUEST_TYPE: 'BuildingMaintenance',
  SERVICE_REQUEST_TYPE_MINOR: 'minor',
  SERVICE_REQUEST_TYPE_FULL: 'full',

  //Saved/deleted SR/PIR
  SERVICE_REQUEST_SAVE_SUCCESS_MESSAGE: 'Service request saved successfully.',
  PIR_SAVE_SUCCESS_MESSAGE: 'Personal Information Request saved successfully.',
  SERVICE_REQUEST_DELETE_SUCCESS_MESSAGE: 'Service request has been deleted.',
  PIR_DELETE_SUCCESS_MESSAGE: 'Personal Information Request has been deleted.',

  CAME_FROM_ATTACHMENT: 'attachment',
  SR_TYPE_CATERING: 'Catering',
  SR_TYPE_CATERING_DESC: 'Hospitality and catering',
  SR_TYPE_TRANSPORT: 'Transport',
  SR_TYPE_WASTE: 'Waste',
  SR_TYPE_CATERING_MEAL_DETAILS: 'Meal Details',
  SERVICE_REQUEST_DEFAULT_TITLE: 'Untitled Request',
  NO_RECENT_ITEMS: 'You have no recent items.',

  // ENV related constants
  VISIT_MY_FORMS: 'Visit My Forms',
  VISIT_MY_BASE: 'Visit My Base',
  VISIT_POSTING_CONNECT: 'I am posting soon',
  MY_BASE: 'My Base',
  MY_FORMS: 'My Forms',
  POSTING_CONNECT: 'PostingConnect',
  EMAIL_SERVICECONNECT_TECHSUPPORTP1:
    'If you are experiencing a technical issue with ServiceConnect, please contact 1800DEFENCE (1800 333 362) or email ',
  EMAIL_SERVICECONNECT_TECHSUPPORT: 'yourcustomer.service@defence.gov.au',
  EMAIL_SERVICECONNECT_TECHSUPPORTP2: ' for further assistance.',

  REACT_APP_DEV_ENV: 'dev',
  REACT_APP_LOCAL_ENV: 'local',
  REACT_APP_PRE_PROD_ENV: 'Preprod',
  REACT_APP_PROD_ENV: 'prod',
  REACT_APP_QA_ENV: 'qa',
  REACT_APP_TST_ENV: 'test',
  REACT_APP_TST2_ENV: 'test2',

  SC_LOGIN_INTERNAL_URL: '/prweb/PRAuth/ServiceConnectAD',
  SC_LOGIN_EXTERNAL_URL: '/prweb/PRAuth/MyGovIDSC',

  SC_DEV_DOMAIN_URL: 'https://devpublic.serviceconnect.defence.gov.au',
  SC_TEST_DOMAIN_URL: 'https://tstpublic.serviceconnect.defence.gov.au',
  SC_TEST2_DOMAIN_URL: 'https://tst2public.serviceconnect.defence.gov.au',

  QA_DOMAIN_URL_INTERNAL: 'https://qainternal.pubaemforms.serviceconnect.defence.gov.au',
  QA_DOMAIN_URL_EXTERNAL: 'https://qainternal.pubaemforms.serviceconnect.defence.gov.au',
  TST_DOMAIN_URL: 'https://public.tstpubaemforms.serviceconnect.defence.gov.au',
  TST2_DOMAIN_URL: 'https://public.tst2pubaemforms.serviceconnect.defence.gov.au',

  POSTINGCONNECT_COMMON_URL: '/prweb/PRAuth/DefenceAD',
  DEAP_INTERNAL_URL: '/prweb/PRAuth/app/DEAP/DEAPDefenceAD',
  DEAP_EXTERNAL_URL: '/prweb/PRAuth/app/DEAP/DEAPMyGovIDSC',
  TRAVEL_INTERNAL_URL: '/prweb/PRAuth/app/travel/TravelDefenceAD',
  TRAVEL_EXTERNAL_URL: '/prweb/PRAuth/app/travel/TravelMyGovIDSC',
  IAU_PORTAL_COMMON_URL: '/prweb/PRAuth/IAUDefenceAD',
  DEV_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  PRE_PROD_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  PROD_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  QA_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  TST_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  TST2_DOMAIN_URL_POSTINGCONNECT: 'https://ext.defence.gov.au/postingconnect',
  SC_PRE_PROD_DOMAIN_URL_INTERNAL: 'https://ppinternal.serviceconnect.defence.gov.au',
  SC_PRE_PROD_DOMAIN_URL_EXTERNAL: 'https://pppublic.serviceconnect.defence.gov.au',
  SC_PROD_DOMAIN_URL_INTERNAL: 'https://internal.serviceconnect.defence.gov.au',
  SC_PROD_DOMAIN_URL_EXTERNAL: 'https://public.serviceconnect.defence.gov.au',
  SC_QA_DOMAIN_URL_INTERNAL: 'https://qainternal.serviceconnect.defence.gov.au',
  SC_QA_DOMAIN_URL_EXTERNAL: 'https://qapublic.serviceconnect.defence.gov.au',
  //AEM URLs

  FORMS_COMMON_URL: '/content/defence-forms/my-forms.html',
  MYBASE_COMMON_URL: '/content/defence/mybase.html',
  POSTINGCONNECT_INTERNAL_URL: '/prweb/PRAuth/app/PostingConnect/PostingConnectDefenceAD',
  POSTINGCONNECT_EXTERNAL_URL: '/prweb/PRAuth/app/PostingConnect/PostingConnectMyGovIDSC',

  INTERNAL_LOGOUT_URL: 'http://drnet/serviceconnect/Pages/ServiceConnect-Channel.aspx',
  EXTERNAL_LOGOUT_URL: 'https://www.defence.gov.au/about/staff-resources/serviceconnect',

  SR_STATUS_MAP: {
    New: [
      {
        displayText: 'Drafted',
        color: 'dark',
      },
    ],
    'Pending-Review': [
      {
        displayText: 'Drafted',
        color: 'dark',
      },
    ],
    'Pending-Fulfillment': [
      {
        displayText: 'Submitted',
        color: 'defenceorange',
        message:
          'Your request has been forwarded to the BSSC for processing. It will be triaged by the BSSC team within the next 7 business days. If you experience an issue with your request before it is triaged by the BSSC, please contact 1800DEFENCE (1800 333 362). You will be asked to provide the ServiceConnect ID for your request.',
      },
    ],
    Received: [
      {
        displayText: 'Received',
        color: 'defencewhite',
        text: 'defenceorange',
        style: 'outline',
        message: 'The BSSC has received and accepted your request. You can now view your service request number above.',
      },
      {
        displayText: 'Submitted',
        color: 'defenceorange',
      },
    ],
    Completed: [
      {
        displayText: 'Complete',
        color: 'defencedarkgreen',
        message: 'Your request has now been completed by the relevant service provider.',
      },
    ],
    'In Progress': [
      {
        displayText: 'In Progress',
        color: 'defencedarkblue',
        message: 'Your request is currently being actioned by the BSSC team and relevant service provider.',
      },
    ],
    Error: [
      {
        displayText: 'Error',
        color: 'defencealerturgent',
        message:
          'There is an issue with your request. The BSSC will send further information via email regarding your request shortly.',
      },
    ],
    Rejected: [
      {
        displayText: 'Rejected',
        color: 'defencealerturgent',
        message:
          'Your service request has been cancelled or rejected. The BSSC will send further information via email regarding your request shortly',
      },
    ],
    Cancelled: [
      {
        displayText: 'Cancelled',
        color: 'defencealerturgent',
        message:
          'Your service request has been cancelled or rejected. The BSSC will send further information via email regarding your request shortly',
      },
    ],
  },
  SR_LIST_PAGINATION_LIMIT: 10,

  TITLE_REQUEST_TYPE_MAINTENANCE: 'Minor maintenance request',
  TITLE_REQUEST_TYPE_NOT_MAINTENANCE: 'New service request',

  BREADCRUMB_REQUEST_TYPE_MAINTENANCE: [
    { name: 'My Service Request', url: '/my-service-request' },
    { name: 'Minor maintenance request' },
  ],

  BREADCRUMB_REQUEST_TYPE_NOT_MAINTENANCE: [
    { name: 'My Service Request', url: '/my-service-request' },
    { name: 'New service request' },
  ],
};

export const cateringCTAs = {
  addNewMeal: {
    control: {
      label: 'Add new item',
      type: 'pxButton',
    },
    type: 'pxButton',
    fieldID: 'AddNewItem',
  },
  removeMeal: {
    control: {
      label: 'Remove this item',
      type: 'pxButton',
    },
    type: 'pxButton',
    fieldID: 'RemoveThisItem',
  },
};
