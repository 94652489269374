import { SR } from '../../components/ServiceUI/ServiceRequest/types';
import _ from 'lodash';

export const requestSorter = (array: SR[], parameter: string) => {
  //determine values for Location and Title
  const getSortValue = (item, parameter) => {
    if (parameter === 'pxPages.SRIndex.Name') {
      return _.get(item, 'pxPages.SRIndex.Name') || _.get(item, 'pxPages.SRIndex.Line1');
    }
    if (parameter === 'pxPages.SRIndex.RequestTitle') {
      return _.get(item, 'pxPages.SRIndex.RequestTitle') || 'Untitled request';
    }
    return _.get(item, parameter);
  };

  const validItems = array.filter((item) => getSortValue(item, parameter));
  //sort date submitted
  const sortFunction = (a: SR, b: SR) => {
    const keyA = parameter === 'pxUpdateDateTime' ? new Date(getSortValue(a, parameter)) : getSortValue(a, parameter);
    const keyB = parameter === 'pxUpdateDateTime' ? new Date(getSortValue(b, parameter)) : getSortValue(b, parameter);
    return parameter === 'pxUpdateDateTime' ? (keyA > keyB ? 1 : -1) : keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1;
  };
  validItems.sort(sortFunction);
  let returnValue = validItems.concat(array.filter((item) => !getSortValue(item, parameter)));
  return parameter !== 'pxUpdateDateTime' ? returnValue : returnValue.reverse();
};
