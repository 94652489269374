import React from 'react';
import { SubContainer } from '../../../ServiceUI/Core/Layout/SubContainer/SubContainer';
import './ViewSubheader.scss';

const ViewSubheader = (props) => {
  return (
    <SubContainer>
      <div className="pt-4 pt-lg-6">
        <h1 className="pb-3" style={{ textAlign: 'start' }}>
          {props.heading}
        </h1>
        <h4 className="fw-light pb-3">{props.subheading}</h4>
      </div>
    </SubContainer>
  );
};

export default ViewSubheader;
