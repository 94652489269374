import type { ComponentStyleConfig } from '@chakra-ui/theme';
const Text: ComponentStyleConfig = {
  baseStyle: {
    margin: 0,
    a: {
      textDecoration: 'underline',
      _hover: { color: 'orange.standard' },
    },
  },
  variants: {
    smallgrey: { color: 'darkgrey', fontSize: 'xs', fontWeight: 'light' },
  },
};
export default Text;
