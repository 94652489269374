import React, { useState } from 'react';
import {
  Accordion as ChakraAccordion,
  Heading,
  Box,
  Flex,
  Text,
  AccordionProps as ChakraAccordionProps,
  Show,
  Button,
  Center,
} from '@chakra-ui/react';

import { SubContainer } from '../Layout/SubContainer/SubContainer';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import Item, { ItemProps } from './FAQAccordionItem';

export interface AccordionProps extends ChakraAccordionProps {
  items: ItemProps[];
  expandButtonText: string;
  title: string;
  anchorId?: string;
  showExpandCollapseAll?: boolean;
  index: number;
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  title,
  anchorId,
  showExpandCollapseAll,
  index,
}: AccordionProps): JSX.Element => {
  const [indexes, setIndexes] = useState<number[]>([]);
  const arrIndex: number[] = [];
  items.forEach((item, key) => {
    if (!item.isDisabled) arrIndex.push(key);
  });
  const [allIndexes] = useState(arrIndex);
  const backgroundColor = index % 2 ? 'white' : '#EDEDED';
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandCollapseAll = () => {
    if (isExpanded) {
      setIndexes(allIndexes);
    } else {
      setIndexes([]);
    }
    setIsExpanded(!isExpanded);
  };

  const newAnchorId = anchorId || index.toString();

  return (
    <ChakraAccordion index={indexes} allowMultiple data-testid="accordion">
      <Box py="4rem" style={{ backgroundColor }}>
        <SubContainer>
          <Flex alignItems="end" justifyContent="space-between" mb="0.5rem">
            <Heading fontSize="sm" as="h2" id={newAnchorId}>
              {title}
            </Heading>
            {showExpandCollapseAll && (
              <Button
                variant="unstyled"
                height="2rem"
                //TEMP TO WORK AROUND BOOTSTRAP
                _active={{ outline: '1px black solid !important' }}
                _focus={{ outline: '1px solid black' }}
                cursor="pointer"
                onClick={handleExpandCollapseAll}
                data-testid="expand-collapse-all"
                minWidth="none"
              >
                <Flex flexDirection="row" gap={1}>
                  <Show above="lg">
                    <Text>{isExpanded ? 'Expand All' : 'Collapse All'}</Text>
                  </Show>
                  <Center>
                    {isExpanded ? (
                      <AddIcon w={4} h={4} color="orange.standard" />
                    ) : (
                      <MinusIcon w={4} h={4} color="orange.standard" />
                    )}
                  </Center>
                </Flex>
              </Button>
            )}
          </Flex>
          <>
            {items &&
              items.map((item, key) => (
                <Item
                  {...item}
                  key={key}
                  toggleExpand={() =>
                    indexes.includes(key)
                      ? setIndexes([...indexes.filter((i) => i !== key)])
                      : setIndexes([...indexes, key])
                  }
                  isExpanded={indexes.includes(key)}
                />
              ))}
          </>
        </SubContainer>
      </Box>
    </ChakraAccordion>
  );
};

export default Accordion;
