import React, { useState } from 'react';
import { Image, Flex, Button, useDisclosure, InputGroup, Input, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ServiceUIDrawer from '../Drawer/ServiceUIDrawer';
import MenuLinks from '../MenuLinks/MenuLinks';
import { MainNavigationProps } from './MainNavigation';
import { Cross, Hamburger, Search_White as SearchWhite } from '../icons/Outline';

const MainNavigationMobile = (props: MainNavigationProps): JSX.Element => {
  const { isOpen: menuOpen, onToggle: menuToggle } = useDisclosure();
  const { isOpen: searchOpen, onToggle: searchToggle } = useDisclosure();
  const [query, setQuery] = useState('');
  return (
    <>
      <Flex padding="0 1rem" alignItems="center" justifyContent="space-between" h="80px" bg="charcoalgrey">
        <Button size="menu" onClick={menuToggle} variant="mobileMenu" visibility={!searchOpen ? 'visible' : 'hidden'}>
          {!menuOpen ? <Hamburger style={{ marginBottom: '4px' }} /> : <Cross style={{ marginBottom: '4px' }} />}
          {!menuOpen ? 'MENU' : 'CLOSE'}
        </Button>
        <Link to={props.homeURL ?? ''}>
          <Image alt={props.altText} h="30%" src={props.mobileLogoURL} data-testid="mobileLogo" />
        </Link>
        <Button size="menu" onClick={searchToggle} variant="mobileMenu" visibility={!menuOpen ? 'visible' : 'hidden'}>
          {!searchOpen ? <SearchWhite style={{ marginBottom: '4px' }} /> : <Cross style={{ marginBottom: '4px' }} />}
          {!searchOpen ? 'SEARCH' : 'CLOSE'}
        </Button>
      </Flex>
      <ServiceUIDrawer top="80px" open={menuOpen} direction="left" bg="charcoalgrey">
        <MenuLinks
          variant="mainNav"
          router={true}
          navigationItems={props.navigationItems ?? []}
          notificationCount={props.notificationCount}
          close={menuToggle}
        />
      </ServiceUIDrawer>
      <ServiceUIDrawer top="80px" open={searchOpen} direction="top" bg="charcoalgrey" zIndex="50">
        <Box w="100%" padding="1.5rem 1.25rem">
          <InputGroup w="100%">
            <Input
              data-testid="searchbar"
              placeholder={props.placeholder}
              variant="primary"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              size="menu"
              data-testid="searchbutton"
              as={ChakraLink}
              variant="mobileSearch"
              isExternal
              title="Search"
              href={`${props.searchURL}?search=${query}`}
            >
              <SearchWhite />
            </Button>
          </InputGroup>
        </Box>
      </ServiceUIDrawer>
    </>
  );
};
export default MainNavigationMobile;
