import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { SubmissionSuccess } from '../../components/ServiceUI/EFT/SubmissionSuccess/SubmissionSuccess';
import content from './Content/ProvideDocumentsComplete.json';
import { useLocation } from 'react-router-dom';

export const ProvideDocumentsCompletePage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Documents provided';
  }, []);
  const { id } = useParams();
  let location = useLocation();
  const mainHeading = location.state[0].mainHeadingData;
  const subHeading = location.state[0].subHeadingData;
  content.header.mainHeading = mainHeading;
  content.header.subHeading = subHeading;

  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <SubmissionSuccess
          {...content.submissionSuccess}
          dateHeading="Completed"
          caseID={`${id}`}
          date={new Date()}
          status="PENDING-DOWNLOAD"
        />
      </SubContainer>
    </>
  );
};
