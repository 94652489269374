import React from 'react';
import SUIFooter from '../../ServiceUI/Core/Footer/Footer';

export const Footer = (props) => {
  const shouldShow = (item) => {
    //Is there a condition?
    if (item.condition) {
      if (item.condition === 'hasTravel') {
        return props.myServices?.hasTravel;
      }
    }
    return true;
  };
  const quickAccess = props.quickAccess.filter(shouldShow);
  return <SUIFooter {...props} quickAccess={quickAccess} />;
};
