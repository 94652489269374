import React from 'react';
import { FormLabel, FormErrorMessage, Radio, RadioGroup, Text, Flex } from '@chakra-ui/react';
import { PrimitiveControl } from '../PrimitiveControl';
import { FormElement, RadioProps } from '../FormElementType';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useFormContext } from 'react-hook-form';

const FormRadio: React.FC<FormElement & RadioProps> = (props: FormElement & RadioProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the input element and a fallback
  const groupId = props.id || 'radiobutton';
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          id={props.id}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
          isInvalid={!!errors[groupId]}
          minHeight={props.minHeight}
        >
          {props.label ? (
            // changed to mb 0 from 2
            <Flex alignItems="center" gap={1} mb={0}>
              <FormLabel m={0}>{props.label}</FormLabel>
              {props.showTooltip && props.tooltipContent && (
                <Tooltip content={props.tooltipContent} placement="bottom" />
              )}
            </Flex>
          ) : (
            <></>
          )}
          <RadioGroup data-testid="radiogroup" value={String(props.value)} isDisabled={props.disabled}>
            <Flex flexDir={{ lg: 'row', base: 'column' }} gap={3}>
              {props.options?.map((item, idx) => (
                <Radio
                  isReadOnly={props.readOnly}
                  data-testid={item.key}
                  variant={props.variant}
                  value={item.key}
                  key={`${item.key}-${idx}`}
                  {...register(groupId, {
                    ...props?.validation,
                    onBlur: (e) => {
                      // @ts-ignore
                      props.onBlur(e.target.value);
                    },
                    onChange: (e) => {
                      // @ts-ignore
                      let selectedValue = e.target.value;
                      // @ts-ignore
                      if (props.selectFunction) {
                        // @ts-ignore
                        props.selectFunction(selectedValue);
                      } else {
                        props.onSelectRadio(selectedValue);
                      }
                    },
                  })}
                >
                  {item.value}
                  {item.tooltip && <Text fontWeight="light">{item.tooltip}</Text>}
                </Radio>
              ))}
            </Flex>
          </RadioGroup>
          <FormErrorMessage>
            <>{errors[groupId] && errors[groupId]?.message}</>
          </FormErrorMessage>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormRadio;
