import React, { useEffect } from 'react';
import CtaPanels from '../../components/ServiceUI/LandingPage/CtaPanels/CtaPanels';
import content from './Content/Home.json';
import { Grid, GridItem, Box } from '@chakra-ui/react';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import LandingPageHeader from '../../components/ServiceUI/LandingPage/LandingPageHeader/LandingPageHeader';
import { RecentItems } from '../../components/ServiceUI/LandingPage/RecentItems/RecentItems';
import { getRecentItems } from '../../helper/getRecentItems';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../reducers/errorHandling';
import { appConstants } from '../../helper/client/constant';
import { CommonFeedback } from '../../components/common/CommonFeedback/commonFeedback';

export const Home = (ProfileData) => {
  const [recentItems, setRecentItems] = React.useState<any>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'ServiceConnect';
    getRecentItems()
      .then((res) => setRecentItems(res))
      .catch((error) =>
        dispatch(setShowError({ error: true, code: error?.response?.status, errorObj: error.response })),
      );
  }, []);

  return (
    <>
      <LandingPageHeader
        usersName={ProfileData.givenNames}
        usersHomeBase={ProfileData.location.name}
        {...content.header}
      />
      <SubHeader bg="charcoal" color="white" {...content.subheader} buttonVariant="secondary_white" />
      <Box bg="whispergrey">
        <SubContainer>
          <Grid templateColumns={{ base: '1fr', md: 'repeat(9, 1fr)' }} gap={{ lg: '1.5rem', base: '25px' }} py="48px">
            <GridItem colSpan={{ md: 6, base: 1 }}>
              <CtaPanels myServices={ProfileData.myServices} heading={content.panels_heading} panels={content.panels} />
            </GridItem>
            <GridItem colSpan={{ md: 3, base: 1 }}>
              <RecentItems
                mainHeading={content.recentItems.mainHeading}
                recentItems={recentItems}
                errorMessage={appConstants.NO_RECENT_ITEMS}
              />
            </GridItem>
          </Grid>
        </SubContainer>
      </Box>
      <CommonFeedback bg="orange.standard" myServices={ProfileData.myServices} />
    </>
  );
};
