import React from 'react';
import { InputGroup, Button, Input, FormLabel, Text, Flex } from '@chakra-ui/react';
import { FilePickerProps, FormElement } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { Tooltip } from '../../Tooltip/Tooltip';

export const FormFilePicker: React.FC<FormElement & FilePickerProps> = (props: FormElement & FilePickerProps) => {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <PrimitiveControl
      disabled={props.disabled}
      isInvalid={props.isInvalid}
      required={props.required}
      desktopWidth={props.desktopWidth}
      mobileWidth={props.mobileWidth}
    >
      <InputGroup display="block">
        {props.label && (
          <Flex alignItems="center" gap={1}>
            <FormLabel
              htmlFor={`file-upload${props.categoryId ? `-${props.categoryId}` : ''}`}
              mb="6px"
              fontSize="18px"
              fontWeight="600"
            >
              {props.label}
            </FormLabel>
            {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
          </Flex>
        )}
        {props.content && <Text mb="10px">{props.content}</Text>}
        <Button
          isDisabled={props.disabled}
          isLoading={props.loading}
          loadingText={props.loadingText}
          mb="1rem"
          variant="secondary"
          onClick={() => {
            ref.current && ref.current.click();
          }}
        >
          {props.ctaText}
        </Button>
        <Input
          accept={props.validFileTypes?.toString()}
          id={`file-upload${props.categoryId ? `-${props.categoryId}` : ''}`}
          onChange={(e) => props.onClick(e)}
          ref={ref}
          type="file"
          multiple={props.multiple}
        />
      </InputGroup>
    </PrimitiveControl>
  );
};
