import React, { useEffect, useState } from 'react';
import Header from '../../components/ServiceUI/Core/Header/Header';
import content from './Content/Search.json';
import { Search } from '../../components/ServiceUI/LandingPage/Search/Search';
import { request } from '../../helper/useAxios';
import { appConstants } from '../../helper/client/constant';
import CtaPanels from '../../components/ServiceUI/LandingPage/CtaPanels/CtaPanels';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Box } from '@chakra-ui/react';
import { getAemUrl } from '../../helper/getOtherAppURL';

export const SearchPage: React.FC<any> = (): JSX.Element => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.title = 'Search';
  });

  const handleSearch = (searchValue) => {
    setIsLoading(true);
    request(`${getAemUrl + appConstants.API_SEARCH}${appConstants.API_SEARCH}`, 'get', {
      id: `?search=${searchValue}`,
      isAEM: true,
    }).then((e) => {
      setResults(e.data.searchResults);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Header {...content.header} />
      <Search {...content.search} submitAction={handleSearch} searchResults={{ results, isLoading }} />
      <Box bg="whispergrey" py="1rem">
        <SubContainer>
          <CtaPanels {...content.panels} />
        </SubContainer>
      </Box>
    </>
  );
};
