import { appConstants } from '../../../helper/client/constant';
import { request } from '../../../helper/useAxios';

const axiosConfig = { withCredentials: true };

export const PIRCaseAudit = (caseID, attachmentID, auditcode) => {
  const URL_AUDIT =
    appConstants.API_AUDIT_UPDATE + caseID + '?AuditCode=' + auditcode + '&AttachmentID=' + attachmentID;
  if (attachmentID !== '') {
    return request(URL_AUDIT, 'put').then((res) => {
      return 'success';
    });
  } else {
    const URL_AUDIT = appConstants.API_AUDIT_UPDATE + caseID + '?AuditCode=' + auditcode;
    return request(URL_AUDIT, 'put', {}, axiosConfig).then((_) => {
      return 'success';
    });
  }
};
