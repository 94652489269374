import { ComponentStyleConfig } from '@chakra-ui/react';
const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '400',
    fontSize: 'regular',
    margin: '0',
    '.chakra-form__required-indicator': {
      color: 'redalert',
    },
  },
};

export default FormLabel;
