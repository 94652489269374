import React from 'react';
import { PegaFile } from '../types';
import { FileTile } from './FileTile';
import { Box, Flex, Heading } from '@chakra-ui/react';

interface FileTilesProps {
  filterCategory?: string;
  files: PegaFile[];
  heading?: string;
}

export const FileTiles: React.FC<FileTilesProps> = (props: FileTilesProps) => {
  const { files, filterCategory } = props;
  const filesToShow = filterCategory ? files.filter((i) => i.category === filterCategory) : files;
  return filesToShow.length > 0 ? (
    <Box mb="1rem">
      {props.heading && (
        <Heading as="h4" fontSize="regular" mb="1rem">
          {props.heading}
        </Heading>
      )}
      <Flex gap={3}>
        {filesToShow.map((item, index) => (
          <FileTile {...item} key={index} />
        ))}
      </Flex>
    </Box>
  ) : null;
};
