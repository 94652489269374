import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import { SubContainer } from '../../Core/Layout';
import { ArrowLink } from '../../Core/Links/Links';
import home from '../../../../images/home.svg';

export interface LandingPageHeaderProps {
  usersName: string;
  usersHomeBase: string;
  ctaText: string;
  ctaURL: string;
  heading: string;
  subheading: string;
  backgroundImgURL?: string;
  mobileBackgroundImgURL?: string;
  mobileSwirlsURL?: string;
}

export const LandingPageHeader: React.FC<LandingPageHeaderProps> = (props: LandingPageHeaderProps): JSX.Element => {
  return (
    <Box
      data-testid="header"
      bg="orange.standard"
      paddingTop={{ lg: '5rem', md: '25rem', sm: '19rem', base: '11rem' }}
      paddingBottom={{ lg: '6rem', base: '3rem' }}
      backgroundRepeat={{ lg: 'no-repeat', base: 'no-repeat, no-repeat' }}
      backgroundSize={{ lg: 'auto 100%', base: '100% 100%, 100% auto' }}
      backgroundPosition={{ lg: 'right', base: 'top' }}
      backgroundImage={{
        //Using the css comment '/**/' to bypass a ChakraUI bug
        lg: `/**/url('/images/${props.backgroundImgURL}')`,
        base: `/**/url('/images/SCMobileSwirls.png'), url('/images/${props.mobileBackgroundImgURL}')`,
      }}
      color="white"
    >
      <SubContainer>
        <Image src={home} alt="home" />
        <Text fontWeight="500" fontSize={{ md: 'regular', base: 'xs' }}>
          Hello {props.usersName}
        </Text>
        <Text fontWeight="light" fontSize={{ md: 'regular', base: 'xs' }}>
          Your home base is {props.usersHomeBase}
        </Text>
        <ArrowLink text={props.ctaText} href={props.ctaURL} />
        <Heading as="h1" fontSize={{ xl: '6xl', md: '3xl', base: 'lg' }} fontWeight="medium" margin="0.75rem 0 0.25rem">
          {props.heading}
        </Heading>
        <Text fontWeight="light" width={{ lg: '45%', base: '100%' }} fontSize={{ md: 'regular', base: 'xs' }}>
          {props.subheading}
        </Text>
      </SubContainer>
    </Box>
  );
};

export default LandingPageHeader;
