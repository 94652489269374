import React from 'react';
import { PegaFile } from '../types';
import { Box, Text } from '@chakra-ui/react';
import { convertBytesToMB } from '../../../../helper/convertBytesToMB';
import { removeFileExtension } from '../../../../helper/removeFileExtension';

export const FileTile: React.FC<PegaFile> = (props: PegaFile) => {
  const { name, extension, fileSizeinbytes } = props;
  return (
    <Box bg="whispergrey" p="0.5rem 1rem">
      <Text m={0} fontWeight="light" fontSize="xs">
        {removeFileExtension(name)}
      </Text>
      <Text m={0} fontWeight="medium" fontSize="xxs">
        {extension} | {convertBytesToMB(fileSizeinbytes).toFixed(2)}mb
      </Text>
    </Box>
  );
};
