import React, { useEffect, useState } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { SCForm } from '../../ServiceUI/Core/FormElements';
import { FormProvider, useForm } from 'react-hook-form';
import { logoutURL } from '../../../helper/getLogoutURL';
import sanitizeHTML from 'sanitize-html';
import content from '../../../pages/Core/Content/Privacy.json';
import { request } from '../../../helper/useAxios';
import { appConstants } from '../../../helper/client/constant';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../../reducers/errorHandling';

interface ModalProps {
  onPrivacyFlagUpdated: () => void;
}

const PrivacyModal: React.FC<ModalProps> = (props: ModalProps): JSX.Element => {
  const [showModal, setShowModal] = useState(true);
  const [logoutLink, setlogoutLink] = useState('');
  const [allowAnalyticsTracking, setAllowAnalyticsTracking] = useState('true');
  const methods = useForm();
  const initialRef = React.useRef(null);
  const dispatch = useDispatch();
  const { onClose } = useDisclosure();

  useEffect(() => {
    const getURL = logoutURL();
    setlogoutLink(getURL);
  }, []);

  const updatePrivacyHandler = () => {
    const param = { allowAnalyticsTracking: allowAnalyticsTracking };
    request(appConstants.API_USER_PROFILE_DETAILS, 'patch', { data: param })
      .then(() => {
        setShowModal(false);
        props.onPrivacyFlagUpdated();
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  const handleCheckboxChange = (value: string) => {
    setAllowAnalyticsTracking(value);
  };

  return (
    <ChakraModal
      onClose={onClose}
      isOpen={showModal}
      size="xlarge"
      scrollBehavior="inside"
      isCentered
      data-testid="privacy-modal"
      initialFocusRef={initialRef}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent p={[2, 6, 14]}>
        <ModalHeader p={0}>
          <Heading as="h1" ref={initialRef} fontSize="lg" fontWeight="medium">
            Privacy notice
          </Heading>
        </ModalHeader>
        <ModalBody bg="whispergrey" p={4} overflowY="auto" maxH="60vh">
          <Box
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(content.header.content, {
                allowedAttributes: {
                  ol: ['type'],
                  a: ['href'],
                },
              }),
            }}
          />
          <FormProvider {...methods}>
            <SCForm.Checkbox
              value={allowAnalyticsTracking}
              reference="acknowledgement"
              required={true}
              visible={true}
              label="Allow analytics tracking"
              isInvalid={null}
              onCheckboxChange={(e) => {
                handleCheckboxChange(e);
              }}
            />
          </FormProvider>
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          flexDirection={{ lg: 'row', base: 'column' }}
          gap={{ lg: 0, base: 2 }}
          px={0}
        >
          <a href={logoutLink} className="w-100">
            <Button w={{ lg: 'auto', base: '100%' }} variant="secondary">
              Reject and logout
            </Button>
          </a>
          <Button w={{ lg: 'auto', base: '100%' }} variant="primary" onClick={updatePrivacyHandler}>
            Accept and continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default PrivacyModal;
