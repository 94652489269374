import React from 'react';
import { Select, FormErrorMessage, FormLabel, InputGroup } from '@chakra-ui/react';
import { FormElement, Option, SelectProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { useFormContext } from 'react-hook-form';

const FormSelectInput: React.FC<FormElement & SelectProps> = (props: FormElement & SelectProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the input element and a fallback
  const id = props.reference?.replace(/\./g, '') || 'checkbox';

  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={!!errors[id]}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
        >
          <InputGroup flexDirection="column">
            <FormLabel variant="primary">{props.label}</FormLabel>
            <Select
              placeholder={props.options[0].value || 'Please select'}
              variant="primary"
              data-testid="select"
              isDisabled={props.disabled}
              // @ts-ignore
              value={props.value}
              {...register(id, {
                ...props?.validation,
                onChange: (e) => {
                  props.onSelect(e.target.value);
                },
              })}
            >
              {props.options?.slice(1).map((item: Option) => (
                <option key={item.key} value={item.key}>
                  {item.value}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <>{errors[id] && errors[id]?.message}</>
            </FormErrorMessage>
          </InputGroup>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormSelectInput;
