import * as React from 'react';
import { SVGProps } from 'react';
const Search_White = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_8_11)">
      <path
        fill="white"
        d="M11.977 10.8423C12.9132 9.66405 13.4205 8.20162 13.4153 6.69604C13.4152 5.36871 13.0224 4.07116 12.2863 2.96725C11.5501 1.86335 10.5038 1.00261 9.27934 0.49375C8.05492 -0.015109 6.70733 -0.149264 5.4068 0.108229C4.10626 0.365721 2.9111 1.00331 1.97228 1.94047C1.03345 2.87763 0.39307 4.07233 0.132007 5.37366C-0.129055 6.675 0.000913772 8.02461 0.505499 9.25204C1.01008 10.4795 1.86665 11.5297 2.96702 12.27C4.06739 13.0103 5.3622 13.4075 6.68791 13.4115C8.18946 13.4036 9.64597 12.8971 10.8291 11.9715L14.8535 16.0007L16 14.8528L11.977 10.8423ZM6.68791 11.8157C5.68439 11.8155 4.70345 11.5175 3.86912 10.9592C3.03479 10.4009 2.38455 9.60742 2.00061 8.67912C1.61668 7.75083 1.51629 6.7294 1.71215 5.74398C1.90801 4.75857 2.39132 3.85344 3.10097 3.14303C3.81061 2.43262 4.71472 1.94885 5.69896 1.75289C6.68321 1.55692 7.70339 1.65757 8.63051 2.0421C9.55763 2.42662 10.35 3.07776 10.9076 3.91318C11.4651 4.7486 11.7626 5.73077 11.7626 6.7355C11.7615 8.08263 11.2265 9.37426 10.275 10.3268C9.32355 11.2793 8.03341 11.8148 6.68791 11.8157Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Search_White;
