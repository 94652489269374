import { Link } from '@chakra-ui/react';
import React from 'react';
import { EFTConstants } from '../../../../pages/EFT/EFTConstant';
import { appConstants } from '../../../../helper/client/constant';

export const getUploadInfo = (
  e,
): { minFiles?: number; maxFiles: number; caption: JSX.Element; tooltipContent?: string } => {
  switch (e) {
    case EFTConstants.PIR_CATEGORY_COMMON_IDENTITY:
      return {
        minFiles: 2,
        maxFiles: 2,
        caption: (
          <>
            Two{' '}
            <Link
              href="https://immi.homeaffairs.gov.au/help-support/applying-online-or-on-paper/on-paper/certified-copy"
              target="_blank"
              rel="noreferrer noopener"
            >
              certified
            </Link>{' '}
            proof of identity documents of the ex-member (Max 3MB, pdf jpg jpeg).
            <br />
            1. Primary - passport, drivers licence, birth certificate. <br />
            2. Secondary - medicare card, senior card, or RSL membership card.
          </>
        ),
        tooltipContent:
          'Proof of identification is required for the ADF member. Your identity has been verified through your ServiceConnect profile.',
      };

    case EFTConstants.PIR_CATEGORY_COMMON_SIGNED_CONSENT:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "I [name] residing at [address] consent to allow my family
            member's Defence information to be released to [name] via [address]. By signing this document I authorise
            Defence to disclose the information identified in the online application made via www.defence.gov.au on
            [date]." followed by signature. (Max 3MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_SIGN_THIS_FORM:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "This signature supports my online application for Defence
            information on [insert date]" followed by a signature (Max 3MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_MEMBER:
      return {
        maxFiles: 1,
        caption: (
          <>
            Example: marriage certificate, birth certificate, proof of defacto relationship, etc (Max 3MB, pdf jpg
            jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_EX_MEMBER:
      return {
        maxFiles: 1,
        caption: (
          <>
            Example: marriage certificate, birth certificate, proof of defacto relationship, etc (Max 3MB, pdf jpg
            jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_FAMILY_MEMBER_IDENTIFICATION:
      return {
        minFiles: 2,
        maxFiles: 2,
        caption: (
          <>
            Two{' '}
            <a
              href="https://immi.homeaffairs.gov.au/help-support/applying-online-or-on-paper/on-paper/certified-copy"
              target="_blank"
              rel="noreferrer"
            >
              certified
            </a>{' '}
            proof of identity documents of family member (Max 3MB, pdf jpg jpeg). Primary - passport, drivers licence,
            birth certificate. Secondary - medicare card, senior card, or RSL membership card.
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_FAMILY_MEMEBER_SIGNED_CONSENT:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "I [name] residing at [address] consent to allow my Defence
            information to be released to [name] via [address]. By signing this document I authorise Defence to disclose
            the information identified in the online application made via www.defence.gov.au on [date]." followed by
            signature. (Max 3MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_MEDICAL_SUPPORTING_DOCUMENTS:
      return {
        maxFiles: 3,
        caption: (
          <>
            Health information is classified as "sensitive information" under the Privacy Act and as such is subject to
            a higher level of protection. Health Information held by Defence is subject to equitable duties of
            confidence, and professional and ethical obligations of confidentiality. A request for release of health
            information of a deceased person can be sought under administrative release, however to satisfy the
            eligibility requirement, proof of A Will, Grant of Probate, and/or Letter of administration is required to
            support the application. (Max 3MB, pdf jpg jpeg)
          </>
        ),
      };
    case appConstants.ATTACHMENT_TYPE_OFFICIAL_DOC:
      return {
        maxFiles: 0,
        caption: (
          <>
            Once the requested documents are ready for the customer, you can upload them by selecting the 'Upload'
            button below.
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_PROOF_OF_DEATH:
      return { maxFiles: 1, caption: <>Example: death certificate or funeral notice (Max 3MB, pdf jpg jpeg).</> };

    default:
      return { maxFiles: 0, caption: <></> };
  }
};
