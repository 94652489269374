import { Box, Checkbox, FormControl, Grid, GridItem, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Spinner } from '../../Core/Spinner/Spinner';
import { Checkbox as CheckboxIcon } from '../../Core/icons/Outline';
import { FileTiles } from '../FileTiles/FileTiles';
import { PegaFile } from '../types';
import { KeyToLabelMap } from './KeyToLabelMap';

interface ViewPIRProps {
  PIR?: any;
  Attachments?: PegaFile[];
}

interface SectionItems {
  Label: string;
  caption: string;
  type: string;
  subHeading: string;
}
interface SectionProps {
  section: string;
  map: SectionItems;
  Label?: string;
  PIR: any;
  attachments?: PegaFile[] | undefined;
}

interface TitleValue {
  title: string;
  value: string | [{ Address?: string; Number?: string }];
}

const TypeToPropertySetMap = {
  'I am an advocate for a current or ex-member': 'AdvocateApplicantDetails',
  'I am a family member of a current or ex-member': 'FamilyMemberApplicantDetails',
  'I am representing myself': 'SelfApplicantDetails',
};

const PropValuePair: React.FC<TitleValue> = (props: TitleValue) => {
  const value = typeof props.value === 'object' ? Object.values(props.value[0])[0] : props.value;
  return value && value !== 'Invalid Date' ? (
    <GridItem colSpan={{ lg: 1, base: 2 }}>
      <Text m={0} color="charcoal">
        {props.title}
      </Text>
      <Text fontWeight="light">{value}</Text>
    </GridItem>
  ) : null;
};

//Need this for review stage
const getServiceTypes = (isNavy, isArmy, isAirForce): string | null => {
  let services: string[] = [];
  isNavy === 'true' && services.push('Royal Australian Navy');
  isArmy === 'true' && services.push('Australian Army');
  isAirForce === 'true' && services.push('Royal Australian Air Force');
  let x = services[0];
  services.slice(1).forEach((element) => {
    x = `${x}, ${element}`;
  });
  return x;
};

const Section: React.FC<SectionProps> = (props: SectionProps): JSX.Element => {
  const items = { ...props.map };
  return (
    <>
      <GridItem colSpan={2}>
        <Heading as="h3" fontSize="sm">
          {items.Label}
        </Heading>
      </GridItem>
      {Object.keys(items).map((i, index) => {
        const item = items[i];
        return props.section === 'RequestDetails'
          ? i !== 'Label' && props.PIR[i] && (
              <PropValuePair
                key={`${props.section}-${item}-${index}`}
                title={item}
                value={i !== 'SubmissionDateTime' ? props.PIR[i] : new Date(props.PIR[i]).toDateString()}
              />
            )
          : typeof item === 'string'
          ? i !== 'Label' &&
            (i === 'ServiceAreaType' || props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]][i]) && (
              <PropValuePair
                key={`${props.section}-${item}-${index}`}
                title={item}
                value={
                  // If there is no ServiceAreaType value (occurs on review page),
                  // run the get service types function which will infer from the other boolean values (IsArmy etc...)
                  i === 'ServiceAreaType'
                    ? props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]].ServiceAreaType
                      ? props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]].ServiceAreaType
                      : getServiceTypes(
                          props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]].IsNavy,
                          props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]].IsArmy,
                          props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]].IsAirForce,
                        )
                    : props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]][i]
                }
              />
            )
          : item.type !== 'files'
          ? props.PIR[props.section][i] && (
              <React.Fragment key={`${props.section}-${item.Label}-${index}`}>
                <GridItem colSpan={2}>
                  <Heading as="h3" fontSize="sm">
                    {item.Label}
                  </Heading>
                </GridItem>
                {Object.keys(item).map(
                  (j, index) =>
                    j !== 'Label' &&
                    (typeof item[j] === 'object' ? (
                      <GridItem colSpan={2} key={`${props.section}-${item[j].Label}-${index}`}>
                        <FileTiles heading={item[j].Label} files={props.attachments ?? []} filterCategory={j} />
                      </GridItem>
                    ) : (
                      props.PIR[props.section][i][j] && (
                        <PropValuePair
                          key={`${props.section}-${items[i][j]}-${index}`}
                          title={items[i][j]}
                          value={props.PIR[props.section][i][j]}
                        />
                      )
                    )),
                )}
              </React.Fragment>
            )
          : props.attachments && (
              <GridItem colSpan={2} key={`${props.section}-${item.Label}--${index}`}>
                <FileTiles heading={item.Label} files={props.attachments} filterCategory={i} />
              </GridItem>
            );
      })}
    </>
  );
};

export const ViewPIR: React.FC<ViewPIRProps> = (props: ViewPIRProps) => {
  const Icon = (props) => {
    const { isChecked } = props;
    return isChecked ? <CheckboxIcon height="1rem" /> : <></>;
  };
  return props.PIR ? (
    <Grid gridTemplateColumns="repeat(2,1fr)" my="1rem" gap={3}>
      {Object.keys(KeyToLabelMap).map(
        (section) =>
          (section === 'RequestDetails' || TypeToPropertySetMap[props.PIR.ApplicantType] === section) && (
            <Section
              key={section}
              section={section}
              map={KeyToLabelMap[section]}
              PIR={props.PIR}
              attachments={props.Attachments}
            />
          ),
      )}
      <GridItem colSpan={2} gap={3}>
        <Heading as="h3" fontSize="sm">
          Delivery information
        </Heading>
        <Text>Receive records via ServiceConnect</Text>
      </GridItem>
      <GridItem colSpan={2} display="flex" alignItems="center" gap={3}>
        <FormControl display="flex" gap={1} isReadOnly>
          <Checkbox icon={<Icon />} variant="main" isChecked={Boolean(props.PIR.Acknowledged)}>
            By submitting this form I accept the terms of the{' '}
            <Link
              href="https://www.defence.gov.au/about/governance/defence-privacy-policy/personal-information-request-privacy-notice"
              isExternal
            >
              personal information request privacy notice
            </Link>
            .
          </Checkbox>
        </FormControl>
      </GridItem>
    </Grid>
  ) : (
    <Spinner />
  );
};
