import { Box, Heading, Badge, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { SubContainer } from '../Layout/SubContainer/SubContainer';
import { Breadcrumbs, BreadcrumbItemProps } from '../Breadcrumbs/Breadcrumbs';
import sanitizeHtml from 'sanitize-html';

interface CommonProps {
  mainHeading: string;
  bg?: 'navy' | 'orange.standard' | {};
  subHeading?: string;
  backgroundImgURL?: string;
  hasBadge?: boolean;
  badgeContent?: string;
  bottomBadgeContent?: string | number;
  bottomBadgeLabel?: string;
}

interface NoBreadcrumbs extends CommonProps {
  breadcrumbs: boolean;
  breadcrumbItems: BreadcrumbItemProps[];
}

interface HasBreadcrumbs extends CommonProps {
  breadcrumbs: boolean;
  breadcrumbItems: BreadcrumbItemProps[];
}

export type HeaderProps = NoBreadcrumbs | HasBreadcrumbs;

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  return (
    <Box
      data-testid="header"
      bg={props.bg ?? 'navy'}
      py={{ lg: '5rem', base: '1rem' }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundImage={{ lg: `url(${props.backgroundImgURL})` }}
    >
      <SubContainer>
        <>
          {props.breadcrumbs === true && props.breadcrumbItems && (
            <Breadcrumbs breadcrumbItems={props.breadcrumbItems} />
          )}
        </>
        {props.hasBadge ? (
          <Badge
            my="1rem"
            p="0.1rem 0.5rem"
            bg="white"
            color="black"
            fontSize="xxs"
            borderRadius="1rem"
            alignItems="center"
            fontWeight="400"
          >
            {props.badgeContent}
          </Badge>
        ) : (
          <></>
        )}

        <Heading
          color="white"
          as="h1"
          fontWeight="medium"
          fontSize={props.breadcrumbs === true || props.hasBadge === true ? 'lg' : { lg: '5xl', base: 'lg' }}
        >
          {props.mainHeading}
        </Heading>
        {props.subHeading ? (
          <Box
            w={{ lg: '60%', base: '100%' }}
            color="white"
            mb="1rem"
            lineHeight="1.25rem"
            fontWeight="light"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.subHeading) }}
          />
        ) : (
          <></>
        )}
        {props.bottomBadgeContent ? (
          <Flex color="white" alignItems={'center'} gap={1}>
            <Badge
              color={'orange.standard'}
              my="1rem"
              p="0.1rem 1rem"
              bg="white"
              fontSize="xxs"
              borderRadius="1rem"
              alignItems="center"
              fontWeight="400"
            >
              {props.bottomBadgeContent}
            </Badge>
            <Text>{props.bottomBadgeLabel}</Text>
          </Flex>
        ) : (
          <></>
        )}
      </SubContainer>
    </Box>
  );
};

export default Header;
