import React from 'react';
import { appConstants } from '../../../../helper/client/constant';
import { StatusPill } from './Styles';

export const StatusBadge = ({ status }) => {
  const statusMap = appConstants.SR_STATUS_MAP;

  return (
    <>
      {statusMap[status]?.map((item, key) => (
        <StatusPill
          key={key}
          pill
          bg={item?.color}
          text={item?.text}
          className={item?.style === 'outline' ? 'badgeOutline' : ''}
        >
          {item?.displayText}
        </StatusPill>
      ))}
    </>
  );
};
