import React from 'react';
import { Container, Button, Modal, Image, Accordion } from 'react-bootstrap';
import './GenericErrorModal.scss';
import { useState } from 'react';
import warning from '../../../images/warning.svg';

const SystemOutageModal = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    props.onClose && props.onClose();
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      dialogClassName="modal-lg modalPadding px-lg-0 py-4"
      className=""
    >
      <Image className="py-4 px-5 systemWarningIcon" src={warning} alt="" />
      <Modal.Title className="px-5 modalTitle">{props.title}</Modal.Title>
      <Container className="px-5 pt-4 pb-6">
        <Button data-testid="OutageButton" className="rounded-0 reLoginButton" variant="primary" onClick={handleClose}>
          {props.ctaText}
        </Button>
      </Container>
      <Accordion className="ms-5 mb-2">
        <Accordion.Header>Errors</Accordion.Header>
        <Accordion.Body>
          <b>Message:</b>
          {props.error.response ? (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.error.response.data,
                }}
              />
              <b>Response code:</b>
              <div>{props.error.response.status}</div>
            </>
          ) : (
            <>
              <div>{!!props.error?.message ? props.error?.message : props.title}</div>
            </>
          )}
        </Accordion.Body>
      </Accordion>
    </Modal>
  );
};

export default SystemOutageModal;
