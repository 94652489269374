import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './ReviewSubheader.scss';
import { appConstants } from '../../../../helper/client/constant';
import { request } from '../../../../helper/useAxios';

//Modals
import AuthenticationModal from '../../../Modals/AuthenticationModal/AuthenticationModal';
import SystemOutageModal from '../../../../components/Modals/SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../../../components/Modals/GenericErrorModal/GenericErrorModal';
import AuthenticationModalProps from '../../../Modals/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../../Modals/GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../../Modals/SystemOutageModal/__mocks__/default.json';
import { SubContainer } from '../../../ServiceUI/Core/Layout/SubContainer/SubContainer';

const ReviewSubheader = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const routeChangetoEdit = (caseId, type) => {
    const path = `/my-service-request/edit-service-request-create/${caseId}?type=${type}`;
    navigate(path);
  };

  const editRequestHandler = () => {
    request(appConstants.API_EDIT_CASE + props.caseIdForFirstEditAPI, 'put')
      .then((res) => {
        request(appConstants.API_ASSIGNMENTS + props.caseIdForSecondEditAPI + '?actionID=ReviewRequest', 'post')
          .then((res) => {
            routeChangetoEdit(props.caseIdForFirstEditAPI, props.requestType);
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      <SubContainer>
        <div className="pt-4 pt-lg-6">
          <h1 className="pb-3" style={{ textAlign: 'start' }}>
            {props.heading}
          </h1>
          <h4 className="fw-light pb-3">{props.subheading}</h4>
          <Button variant="primary" className="mobileButton" onClick={() => editRequestHandler()}>
            Edit request
          </Button>
        </div>
      </SubContainer>
    </>
  );
};

export default ReviewSubheader;
