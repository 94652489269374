import React, { useEffect, useState } from 'react';
import { Box, Flex, Menu, SystemProps } from '@chakra-ui/react';
import { Notification, NotificationItem } from './Notification';
import { MenuButton, Text, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { SortButton } from '../../Core/SortButton/SortButton';
import { OpenedNotification } from './OpenedNotification';
import { notificationSorter, notificationFilterer } from '../../../../helper/client/notificationFuncs';
import { Pagination } from '../../Core/Pagination/Pagination';

export interface NotificationsProps {
  notifications: NotificationItem[];
  onMarkAsRead(type: string, flag?: Boolean, id?: string): void;
  onDelete(id: string, notificationType?: string): void;
}

interface FilterButtonProps {
  text: string;
  dropdownItems: any[];
  changeFilter(e): void;
  width?: SystemProps['width'];
}

const FilterButton: React.FC<FilterButtonProps> = (props: FilterButtonProps) => {
  return (
    <Menu>
      <MenuButton w={props.width} as={Button} variant="secondary">
        <Text>{props.text}</Text>
      </MenuButton>
      <MenuList>
        {props.dropdownItems?.map(
          (item: string, key: number) =>
            item && (
              <MenuItem
                key={key}
                data-testid={`filterby-${item.replace(' ', '-').toLowerCase()}`}
                onClick={() => props.changeFilter(item)}
              >
                {item}
              </MenuItem>
            ),
        )}
      </MenuList>
    </Menu>
  );
};

const getAllTags = (notifications: NotificationItem[]) => {
  let tags: string[] = [];
  notifications.forEach((item) => {
    return item.tags?.forEach((x) => {
      !tags.some((e) => x === e) && tags.push(x);
    });
  });
  return tags;
};

export const Notifications: React.FC<NotificationsProps> = (props: NotificationsProps): JSX.Element => {
  //Is there a notification open?
  const [openNotification, setOpenNotification] = useState<boolean>(false);

  //What notification is selected? Defaults to the first one in the prope for type safety
  const [openedNotification, setOpenedNotification] = useState<NotificationItem>(props.notifications[0]);

  //What filter is currently applied?
  const [filter, setFilter] = useState('');

  //How are the notifications currently sorted? Defaults to by date
  const [sort, setSort] = useState('createTime');

  //Initalising notifications with sort applied
  const [notifications, setNotifications] = useState(notificationSorter(props.notifications, sort));

  //Handling direction sort
  const changeDirection = () => {
    setNotifications([...notifications].reverse());
  };

  //Recalculating notification order (sort and filter) when filter/sort state changes
  useEffect(() => {
    setNotifications(notificationFilterer(notificationSorter(props.notifications, sort), filter));
  }, [props.notifications, filter, sort]);

  getAllTags(props.notifications);
  const buttonWidth = { lg: 'fit-content', base: '100%' };

  return (
    <>
      {openNotification !== false ? (
        <OpenedNotification
          {...openedNotification}
          onClose={() => {
            setOpenNotification(false);
          }}
        />
      ) : (
        <Box bg="white">
          <Flex justifyContent="space-between">
            <Flex gap={3} wrap="wrap" width="100%">
              <FilterButton
                dropdownItems={getAllTags(props.notifications)}
                text="Filter"
                changeFilter={(e) => {
                  setFilter(e);
                }}
                width={buttonWidth}
              />
              <Button width={buttonWidth} variant="secondary" onClick={() => props.onMarkAsRead('all')}>
                Mark all as read
              </Button>
              <Button width={buttonWidth} variant="secondary" onClick={() => props.onDelete('all')}>
                Delete all
              </Button>
              {filter !== '' && (
                <Button width={buttonWidth} onClick={() => setFilter('')} variant="secondary_dark">
                  Clear
                </Button>
              )}
            </Flex>
            <SortButton
              isSRSort={false}
              display={{ lg: 'flex', base: 'none' }}
              text="Sort"
              changeDirection={changeDirection}
              changeSort={(sort) => {
                setSort(sort);
              }}
              dropdownItems={[
                { title: 'Date', property: 'createTime' },
                { title: 'Title', property: 'description' },
              ]}
            />
          </Flex>
          <Pagination itemsPerPage={12}>
            {notifications?.map((item: NotificationItem, key: number) => (
              <Notification
                {...item}
                onDelete={props.onDelete}
                key={key}
                onMarkAsRead={props.onMarkAsRead}
                onView={(id: string) => {
                  setOpenedNotification(
                    () => props.notifications.find((i: NotificationItem) => i.ID === id) || props.notifications[0],
                  );
                  setOpenNotification(true);
                }}
                variant={item.tags?.includes('My Forms') ? 'AEM' : 'PEGA'}
              />
            ))}
          </Pagination>
        </Box>
      )}
    </>
  );
};

export default Notifications;
