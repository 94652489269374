import React from 'react';
import { Box, Button, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { MainContainer } from '../Layout/MainContainer/MainContainer';
import FeedbackModal from './FeedbackModal';

export interface Service {
  value: string;
}

export interface ModalProps {
  modalHeading: string;
  modalDescription: string;
  serviceHeading: string;
  services: Service[];
  selectedService?: string;
  ratingHeading: string;
  reviewHeading: string;
  disclaimer: string;
  closeCTAText: string;
  submitCTAText: string;
  thankYouHeading: string;
  thankYouDescription: string;
  usageHeading: string;
  usageContent: string;
  errorHeading: string;
  errorDescription: string;
  submitAction?(data): Promise<any>;
}

export interface FeedbackProps {
  title: string;
  description: string;
  ctaText: string;
  backgroundImgURL: string;
  feedbackSwirlURL: string;
  modalProps: ModalProps;
  bg?: string;
}

const Feedback: React.FC<FeedbackProps> = (props: FeedbackProps): JSX.Element => {
  const { isOpen: modalOpen, onToggle: modalToggle } = useDisclosure();
  return (
    <>
      <Box
        data-testid="feedback-modal"
        bg={props.bg ?? 'navy'}
        color="white"
        padding="1rem 0"
        backgroundRepeat={{ lg: 'no-repeat', base: 'no-repeat' }}
        backgroundSize={{ lg: 'cover', sm: '5%', base: '8%' }}
        backgroundPosition={{ lg: '', base: 'top right' }}
        backgroundImage={{
          lg: `url(${props.backgroundImgURL})`,
          base: `url(${props.feedbackSwirlURL})`,
        }}
      >
        <MainContainer flex alignItems={{ lg: 'center', base: 'flex-start' }} direction={{ lg: 'row', base: 'column' }}>
          <Heading as="h3" fontSize="sm" w={{ lg: '20%' }} fontWeight="medium">
            {props.title}
          </Heading>
          <Text color="white" w={{ lg: '50%' }} fontWeight="light">
            {props.description}
          </Text>
          <Button
            w={{ lg: '20%', base: '100%' }}
            marginTop={{ lg: 0, base: '1rem' }}
            variant="secondary_fill"
            onClick={modalToggle}
          >
            {props.ctaText}
          </Button>
        </MainContainer>
      </Box>
      {props.modalProps && <FeedbackModal {...props.modalProps} isOpen={modalOpen} onClose={modalToggle} />}
    </>
  );
};

export default Feedback;
