import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Badge } from '../../Core/Badge/Badge';
import { NotificationItem } from './Notification';
import { BackLink } from '../../Core/Links/Links';
type OpenedNotificationProps = NotificationItem & { onClose(): void };

export const OpenedNotification: React.FC<OpenedNotificationProps> = (props: OpenedNotificationProps) => {
  return (
    <Flex gap={2} flexDir="column" pt="1rem" pb="2rem">
      <BackLink text="Back" onClick={props.onClose!} />
      <Flex gap={1} justifyContent="space-between" flexDir={{ lg: 'row', base: 'column-reverse' }}>
        <Flex gap={1}>
          {props.tags &&
            props.tags.map((value: string, key: number) => {
              return value !== '' && <Badge key={key} status={value} bgOverride="transparent" />;
            })}
        </Flex>
        <Text>{new Date(props.createTime).toLocaleDateString()}</Text>
      </Flex>
      <Box>
        <Heading as="h2" fontSize="md" fontWeight="medium">
          {props.description}
        </Heading>
        <Text fontWeight="light" dangerouslySetInnerHTML={{ __html: props.message }} />
      </Box>
    </Flex>
  );
};
