import React from 'react';
import { FormLabel, FormErrorMessage, Textarea } from '@chakra-ui/react';
import { PrimitiveControl } from '../PrimitiveControl';
import { FormElement, TextProps } from '../FormElementType';
import { useFormContext } from 'react-hook-form';

const FormTextAreaInput: React.FC<FormElement & TextProps> = (props: FormElement & TextProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods

  const id = props?.reference?.replace(/\./g, '') || 'registerId';

  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={!!errors[id]}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
        >
          <FormLabel>
            <span>{props.label}</span>
          </FormLabel>
          <Textarea
            data-testid="textarea"
            variant="primary"
            defaultValue={String(props.value)}
            readOnly={props.readOnly}
            maxLength={props?.maxLength}
            minLength={props?.minLength}
            {...register(id, {
              ...props?.validation,
              onChange: (e) => {
                // @ts-ignore
                props.onBlur(e.target.value);
              },
              onBlur: (e) => {
                // @ts-ignore
                props.onBlur(e.target.value);
              },
            })}
          />
          <FormErrorMessage>
            <>{errors[id] && errors[id]?.message}</>
          </FormErrorMessage>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormTextAreaInput;
