import * as React from 'react';
import { SVGProps } from 'react';
const Sort = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g>
      <path
        fill="black"
        d="M4.44446 11.1111H11.5556L8.00001 15.1111L4.44446 11.1111ZM4.44446 5.77777L8.00001 1.77777L11.5556 5.77777H4.44446Z"
      />
    </g>
  </svg>
);
export default Sort;
