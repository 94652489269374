import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { BackLink } from '../../components/ServiceUI/Core/Links/Links';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { Alert, Success } from '../../components/ServiceUI/Core/icons/Outline';
import KeyRequestInfo from '../../components/ServiceUI/EFT/KeyRequestInfo/KeyRequestInfo';
import ReadyToDownloadDocuments from '../../components/ServiceUI/EFT/ReadyToDownloadDocuments/ReadyToDownloadDocuments';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import { getRefData } from '../../helper/getRefData';
import { request } from '../../helper/useAxios';
import { setShowError } from '../../reducers/errorHandling';
import { PIRCaseAudit } from './API/PIRCaseAudit';
import content from './Content/Complete.json';

export const ApplicantDocsReadyToDownload: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [savedFiles, setsavedFiles] = useState([]);
  const [pirInfo, setpirInfo] = useState<any>();
  const [pirInfoView, setpirInfoView] = useState<any>({});
  const [attachments, setAttachments] = useState([]);
  const [categories, setCategories] = useState<
    { title: string; property: string; key: string; isReady: boolean | null }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      setLoading(true);

      try {
        const getres = await request(appConstants.API_CASES, 'get', { id });
        const pegaRes = getres.data.content.PersonalInfoRequest;
        setpirInfo(getres.data);

        const [serviceType, applicantType] = await Promise.all([
          getRefData('PIRType', pegaRes.ServiceType),
          getRefData('PIRApplicantType', pegaRes.ApplicantType),
        ]);

        const newObj = { ...pegaRes, ServiceType: serviceType, ApplicantType: applicantType };
        setpirInfoView(newObj);

        const res = await request(appConstants.API_CASES, 'get', { id: `${id}/attachments` });
        setLoading(false);
        setAttachments(res.data.attachments);
        setCategories([
          {
            title: 'Official documents',
            property: pegaRes.IsOfficialDocuments,
            key: 'OfficialDocuments',
            isReady: true,
          },
          {
            title: 'Statement of service',
            property: pegaRes.IsStatementOfService,
            key: 'StatementOfService',
            isReady: Boolean(pegaRes.IsStatementOfServiceComplete),
          },
          {
            title: 'Eulogy',
            property: pegaRes.IsEulogy,
            key: 'Eulogy',
            isReady: Boolean(pegaRes.IsEulogyComplete),
          },
          {
            title: 'Disciplinary records',
            property: pegaRes.IsDisciplinaryRecords,
            key: 'DisciplinaryRecords',
            isReady: Boolean(pegaRes.IsDisciplinaryRecordsComplete),
          },
          {
            title: 'Certificate of service',
            property: pegaRes.IsCertificateOfService,
            key: 'CertificateOfService',
            isReady: pegaRes.IsCertificateOfServiceComplete ? Boolean(pegaRes.IsCertificateOfServiceComplete) : null,
          },
          {
            title: 'Medical records',
            property: pegaRes.IsMedicalRecords,
            key: 'MedicalRecords',
            isReady: pegaRes.IsMedicalRecordsComplete ? Boolean(pegaRes.IsMedicalRecordsComplete) : null,
          },
          {
            title: 'Personnel information',
            property: pegaRes.IsPersonnelInformation,
            key: 'PersonnelInformation',
            isReady: pegaRes.IsPersonnelInformationComplete ? Boolean(pegaRes.IsPersonnelInformationComplete) : null,
          },
          {
            title: 'Psychological records',
            property: pegaRes.IsPsychologicalRecords,
            key: 'PsychologicalRecords',
            isReady: pegaRes.IsPsychologicalRecordsComplete ? Boolean(pegaRes.IsPsychologicalRecordsComplete) : null,
          },
          {
            title: 'Service report',
            property: pegaRes.IsServiceReport,
            key: 'ServiceReport',
            isReady: pegaRes.IsServiceReportComplete ? Boolean(pegaRes.IsServiceReportComplete) : null,
          },
          {
            title: 'Other',
            property: pegaRes.IsRequestCopyOfRecords,
            key: 'OtherRecords',
            isReady: pegaRes.IsOtherComplete ? Boolean(pegaRes.IsOtherComplete) : null,
          },
        ]);

        if (res.data.attachments) {
          const filteredArrayOfficialDocsOnly = res.data.attachments.filter((file: any) =>
            appConstants.ATTACHMENT_TYPE_OFFICIAL_DOCS.includes(file.category),
          );
          if (filteredArrayOfficialDocsOnly.length > 0) {
            setsavedFiles(filteredArrayOfficialDocsOnly);
          }
        }

        await PIRCaseAudit(id, '', 'PIR06');
      } catch (error) {
        setLoading(false);
        dispatch(setShowError({ hasError: true, error }));
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header
        {...content.header}
        breadcrumbItems={[{ name: 'Personal Information Request', url: '/personal-information-request' }]}
      />
      <SubContainer verticalMargin="1rem">
        <BackLink href=".." text="Back to homepage" />
        <Box mt={{ lg: '1rem', base: '2rem' }} height="2rem">
          <Success height="100%" />
        </Box>
        <Heading as="h2" fontSize={{ lg: 'sm', base: 'sm' }} fontWeight="medium" mt="1rem" mb="2rem">
          {content.subheading}
        </Heading>
        {pirInfo?.content.PersonalInfoRequest?.CommentsForApplicant && (
          <>
            <Heading as="h3" fontSize="regular" fontWeight="normal">
              {content.subcontentheading}
            </Heading>
            <Text fontWeight="light">{pirInfo?.content.PersonalInfoRequest?.CommentsForApplicant}</Text>
          </>
        )}
        <Heading as="h3" fontSize="regular" fontWeight="normal" mb="2rem">
          {content.subcontent}
        </Heading>
        {loading && <Spinner overlay={false} text="Fetching document list" />}
        <ReadyToDownloadDocuments
          files={savedFiles}
          caseID={id ?? ''}
          categories={categories}
          status={pirInfo?.status}
        />
        <Flex
          mt="2rem"
          bg="whispergrey"
          gap={3}
          alignItems={{ lg: 'center', base: 'flex-start' }}
          p="1rem"
          flexDir={{ lg: 'row', base: 'column' }}
        >
          <Box height="3rem">
            <Alert height="100%" />
          </Box>
          <Box m="0" dangerouslySetInnerHTML={{ __html: content.alert }} />
        </Flex>
        {pirInfo && (
          <>
            <KeyRequestInfo variant="full" pirInfo={pirInfo} />
            <ViewPIR PIR={pirInfoView} Attachments={attachments} />
          </>
        )}
      </SubContainer>
    </>
  );
};
