import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../helper/findByProp';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';

const FormTextAreaInput = ({
  fieldID,
  label,
  fieldPropMapping,
  required,
  control,
  visible,
  reference,
  formData,
  error,
  readOnly,
}) => {
  const eftreqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(eftreqDetailDataFromReduxStore?.value);
  const dispatch = useDispatch();

  const dispatchFieldValue = (value) => {
    dispatch(
      setFieldValue({
        reference,
        value: value,
        formData,
      }),
    );
  };

  useEffect(() => {
    const updatedValueText = eftreqDetailDataFromReduxStore?.value;
    setDisplayValue(updatedValueText);
  }, [eftreqDetailDataFromReduxStore]);

  return (
    <SCForm.TextArea
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      disabled={readOnly}
      id={fieldID}
      reference={reference}
      isInvalid={error}
      label={label}
      max={control?.modes[0]?.max}
      maxLength={control?.modes[0]?.maxChars}
      min={control?.modes[0]?.min}
      minLength={control?.modes[0]?.minChars}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      readOnly={readOnly}
      required={required}
      validation={fieldPropMapping[fieldID]?.validation}
      value={displayValue}
      visible={visible}
      onBlur={(e) => {
        dispatchFieldValue(e);
      }}
    />
  );
};

export default FormTextAreaInput;
