import React from 'react';
import { Link as ChakraLink, Text } from '@chakra-ui/react';
import {
  Ringing_Phone as Phone,
  File,
  Arrow_NoFill as ArrowNoFill,
  Arrow_Black as ArrowBlack,
  Mail_Orange as Email,
} from '../icons/Outline';
import { getLinkType } from '../../../../helper/getLinkType';
import { NavLink } from 'react-router-dom';

export interface LinkProps {
  text: string;
  href?: string;
  onClick?(): void;
}
export interface LinkWithType extends LinkProps {
  type?: 'phone' | 'file' | 'email' | 'link' | {};
}
/**
 * These are the automatic links
 */
export const Link: React.FC<LinkWithType> = (props: LinkWithType) => {
  const getIcon = (type) => {
    switch (type) {
      case 'phone':
        return <Phone height="1rem" />;
      case 'file':
        return <File height="1rem" />;
      case 'email':
        return <Email height="1rem" />;
      case 'link':
        return <File height="1rem" />;
      default:
        return null;
    }
  };
  return (
    <>
      <ChakraLink
        w="fit-content"
        href={`${getLinkType(props.type)}${props.href}`}
        display="flex"
        alignItems="center"
        gap={1}
      >
        {getIcon(props.type)}
        <Text fontWeight="light">{props.text}</Text>
      </ChakraLink>
    </>
  );
};

export const ArrowLink: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <ChakraLink
      w="fit-content"
      fontWeight="500"
      href={props.href}
      display="flex"
      fontSize={{ lg: '18px', md: '18px', base: '16px' }}
      textDecoration="none"
      color="white"
      _hover={{ textDecoration: 'underline', color: 'white' }}
      alignItems="center"
      gap={1}
    >
      {props.text}
      <ArrowNoFill height="1rem" />
    </ChakraLink>
  );
};

export const BackLink: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <ChakraLink
      w="fit-content"
      _hover={{ fill: 'orange.standard' }}
      gap={1}
      as={NavLink}
      to={props.href}
      display="flex"
      alignItems="center"
      onClick={props.onClick}
    >
      <ArrowBlack style={{ rotate: '180deg' }} fill="inherit" height="1rem" />
      {props.text}
    </ChakraLink>
  );
};
