import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import content from './Content/Help.json';
import { CommonFeedback } from '../../components/common/CommonFeedback/commonFeedback';
import Accordion from '../../components/ServiceUI/Core/Accordion/Accordion';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';

export const SRHelpPage: React.FC<any> = ({ myServices }) => {
  useEffect(() => {
    document.title = "Help and FAQ's";
  }, []);

  return (
    <>
      <Box bg="whispergrey">
        <Header {...content.header} />
        {content.accordion.map((value, index) => (
          <Accordion {...value} key={index} index={index} />
        ))}
      </Box>
      {/* replace Box below? */}
      <SubContainer>
        <Box>
          <p className="text-defencered">
            *Link accessible through the Defence network (Defence Protected Environment) only.
          </p>
        </Box>
      </SubContainer>

      <CommonFeedback
        title="Please tell us what you need from My Service Request"
        selectedService="myservicerequest"
        myServices={myServices}
      />
    </>
  );
};
