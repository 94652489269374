import React from 'react';
import { Badge } from '../../Core/Badge/Badge';
import { Card, CardBody, CardFooter, CardHeader, Text, Flex, GridItem } from '@chakra-ui/react';
import { CardLink } from '../../Core/Card/CardLink';
export interface SearchResultProps {
  tags: string[];
  title: string;
  description: string;
  service: string;
  buttonText: string;
  buttonURL: string;
}

export const SearchResult: React.FC<SearchResultProps> = (props: SearchResultProps) => {
  return (
    <GridItem colSpan={{ xl: 4, lg: 6, base: 12 }} data-testid="search-result">
      <Card height="100%" justifyContent="space-between">
        {props.tags.length > 0 && (
          <CardHeader>
            <Flex flexWrap="wrap" gap={1}>
              {props.tags?.map((i: string, key: number) => (
                <Badge status={i} />
              ))}
            </Flex>
          </CardHeader>
        )}
        <CardBody py={0}>
          <Text fontWeight="bold" data-testid="title">
            {props.title}
          </Text>
          <Text
            data-testid="description"
            wordBreak="break-word"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </CardBody>
        <CardFooter>
          <CardLink text={props.buttonText} href={props.buttonURL} />
        </CardFooter>
      </Card>
    </GridItem>
  );
};
