import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { SearchBar, SearchBarProps } from './Searchbar';
import { SearchResults, SearchResultsProps } from './SearchResults';
import { useSearchParams } from 'react-router-dom';

export interface SearchProps {
  searchBar: SearchBarProps;
  searchResults: SearchResultsProps;
  submitAction(e): void;
}

export const Search: React.FC<SearchProps> = (props: SearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const [params] = useSearchParams();

  const handleSubmit = (e) => {
    props.submitAction(e);
    setShowResults(true);
  };

  const handleClear = () => {
    setShowResults(false);
    setSearchValue('');
  };

  useEffect(() => {
    const searchQuery = params.get('search');
    if (searchQuery) {
      setSearchValue(searchQuery);
      handleSubmit(searchQuery);
    }
  }, []);

  return (
    <Box bg="whispergrey">
      <SearchBar
        {...props.searchBar}
        onSubmit={(e) => handleSubmit(e)}
        value={searchValue}
        setValue={(e) => setSearchValue(e)}
      />
      {showResults && <SearchResults {...props.searchResults} onClear={handleClear} />}
    </Box>
  );
};

export default Search;
