import { Container, Tooltip, OverlayTrigger, Button, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { StatusBadge } from '../../CreateRequest/StatusBadge/StatusBadge';
import content from '../content/ViewServiceRequest.json';
import { appConstants } from '../../../../helper/client/constant';
import { CloneModal } from '../../../ServiceUI/ServiceRequest/CloneRequest/CloneModal';
import clonecontent from '../../../../pages/ServiceRequest/Content/LandingPage.json';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KeyRequestInfo.scss';
import { SubContainer } from '../../../ServiceUI/Core/Layout/SubContainer/SubContainer';

const requestContent = content.keyrequestinfo;
const statusMap = appConstants.SR_STATUS_MAP;

const KeyRequestInfo = (props) => {
  const navigate = useNavigate();

  const [showCloneModal, setShowCloneModal] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your ServiceConnect ID can be used to identify a submitted request before it is received and accepted by the BSSC.
      If you experience an issue with your request before it is accepted by the BSSC, please contact 1800DEFENCE (1800
      333 362). You will be asked to provide the ServiceConnect ID for your request. You can disregard your
      ServiceConnect ID once your service request number has been created.
    </Tooltip>
  );
  const renderSRNumberTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your service request number is the 12-digit number assigned to your submitted base service request. Service
      request numbers are created once your request is received and accepted by the BSSC. You will be asked to provide
      this number if you contact the BSSC for further assistance with your request.
    </Tooltip>
  );
  const navigatetoCreatePage = (clonedCaseData) => {
    const RequestType =
      clonedCaseData.ServiceType === appConstants.SERVICE_REQUEST_TYPE
        ? appConstants.SERVICE_REQUEST_TYPE_MINOR
        : appConstants.SERVICE_REQUEST_TYPE_FULL;
    const path = `/my-service-request/edit-service-request-create/${clonedCaseData.CloneSRID}?type=${RequestType}`;

    navigate(path);
  };
  return (
    <SubContainer>
      <Container className="pt-3">
        <Container className="bg-defencemediumlightblue p-4 p-lg-5">
          <CloneModal
            show={showCloneModal}
            {...clonecontent.cloneModal}
            onClose={() => setShowCloneModal(false)}
            caseID={props.caseID}
            onsetAPIError={(data) => props.OnsetAPIError(data)}
            onsetShowModel={() => props.OnsetShowModal()}
            onsetserviceDownModel={() => props.OnsetServiceDownModal()}
            onsetErrorModel={() => props.OnsetErrorModal()}
            onConfirmSuccess={navigatetoCreatePage}
          />
          <h2 className="fw-light headingTextSize">{requestContent.heading}</h2>
          <h3 className="fw-light contentTextSize mb-0">{requestContent.nameLabel}</h3>
          <p className="fw-light contentTextSize">{props.details.data[0].RequestTitle}</p>

          <Container className="d-flex justify-content-start flex-column flex-lg-row">
            <Row>
              <p className="fw-light mb-0 contentTextSize">{requestContent.dateLabel}</p>
              <p className="fw-light contentTextSize">
                {new Date(props.details.data[0].SubmissionDateTime).toDateString()}
              </p>
            </Row>
            <Row>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <p className="fw-light mb-0">
                  {requestContent.IDLabel}
                  <InfoCircle size={15} className="d-inline ms-2" />
                </p>
              </OverlayTrigger>
              <p className="fw-light contentTextSize">{props.details.data[0].CaseID}</p>
            </Row>
            <Row>
              {props.notificationId.length > 0 && (
                <Container>
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderSRNumberTooltip}>
                    <p className="fw-light mb-0 contentTextSize">
                      <Container className="d-flex">
                        {requestContent.SRNumber}
                        <span className="mt-1 ms-2">
                          <InfoCircle size={15} />
                        </span>
                      </Container>
                    </p>
                  </OverlayTrigger>

                  <p className="fw-light contentTextSize">{props.notificationId}</p>
                </Container>
              )}
            </Row>
          </Container>
          <h3 className="fw-light headingTextSize mb-0">{requestContent.statusLabel}</h3>

          <Container className="pb-2">
            <StatusBadge status={props.status} />
          </Container>

          <Container className="mb-3 fw-light contentTextSize">
            {statusMap[props.status]?.map((item, key) => (
              <>{item?.message}</>
            ))}
          </Container>
          <Button onClick={() => setShowCloneModal(true)}>Clone</Button>
        </Container>
      </Container>
    </SubContainer>
  );
};

export default KeyRequestInfo;
