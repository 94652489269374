import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { SubmissionSuccess } from '../../components/ServiceUI/EFT/SubmissionSuccess/SubmissionSuccess';
import content from './Content/Complete.json';

export const NewRequestCompletePage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Completed request';
  }, []);
  const { id } = useParams();
  return (
    <>
      <Header
        {...content.header}
        breadcrumbItems={[{ name: 'Personal Information Request', url: '/personal-information-request' }]}
      />
      <SubContainer verticalMargin="1rem">
        <SubmissionSuccess
          {...content.submissionSuccess}
          dateHeading="Requested"
          caseID={`${id}`}
          date={new Date()}
          status="pending-fulfillment"
        />
        <Flex justifyContent="flex-end">
          <Button variant="primary" w={{ lg: 'auto', base: '100%' }} mb="3rem" as={Link} to="..">
            Back to home
          </Button>
        </Flex>
      </SubContainer>
    </>
  );
};
