import React, { useState, useEffect } from 'react';
import { GridItem, List, ListItem, Link } from '@chakra-ui/react';
import { Arrow_Orange as NavArrow } from '../icons/Outline';

export interface PaginationProps {
  itemsPerPage: number;
  children: JSX.Element | JSX.Element[];
  reset?: number;
  itemSpan?: number;
}

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps): JSX.Element => {
  interface ArrowProps {
    testID: string;
    handlePageChange(direction): void;
    direction: 'increase' | 'decrease';
  }
  const Arrow: React.FC<ArrowProps> = (props: ArrowProps) => {
    return (
      <Link
        data-testid={props.testID}
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && props.handlePageChange(props.direction)}
        onClick={() => props.handlePageChange(props.direction)}
        _hover={{ fill: 'orange.standard' }}
      >
        <NavArrow
          data-testid="arrow-orange"
          height="1rem"
          style={props.direction === 'decrease' ? { rotate: '180deg' } : {}}
          fill="inherit"
        />
      </Link>
    );
  };
  const [limits, setLimits] = useState([0, props.itemsPerPage]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Array.isArray(props.children) ? Math.ceil(props.children.length / props.itemsPerPage) : 1;
  const PAGE_LIMIT = 5;
  useEffect(() => {
    setLimits([(currentPage - 1) * props.itemsPerPage, currentPage * props.itemsPerPage]);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.reset]);

  const handlePageChange = (direction: string) => {
    if (direction === 'increase') {
      currentPage < totalPages && setCurrentPage(currentPage + 1);
    } else if (direction === 'decrease') {
      currentPage !== 1 && setCurrentPage(currentPage - 1);
    }
  };
  const PageNumbers: React.FC = (): JSX.Element => {
    let pageNumbers: React.ReactElement[] = [];
    for (let index = 1; index < totalPages - 1; index++) {
      pageNumbers.push(
        <ListItem key={index}>
          <Link
            key={index}
            data-testid="pageNumber"
            tabIndex={0}
            textDecoration={currentPage === index + 1 ? 'underline' : 'none !important'}
            onClick={() => setCurrentPage(index + 1)}
            onKeyDown={(e) => e.key === 'Enter' && setCurrentPage(index + 1)}
          >
            {index + 1}
          </Link>
        </ListItem>,
      );
    }
    if (currentPage <= PAGE_LIMIT) {
      pageNumbers = pageNumbers.slice(0, PAGE_LIMIT);
    } else if (currentPage > totalPages - 4) {
      pageNumbers = pageNumbers.slice(totalPages - PAGE_LIMIT, totalPages - 1);
    } else {
      pageNumbers = pageNumbers.slice(currentPage - 4, currentPage + 1);
    }
    return <>{pageNumbers}</>;
  };

  return (
    <>
      {Array.isArray(props.children) ? props.children.slice(limits[0], limits[1]) : props.children}
      {Array.isArray(props.children) && props.children.length > props.itemsPerPage && (
        <GridItem justifyContent="center" display="flex" w="100%" colSpan={props.itemSpan ?? 3} mt="1rem">
          <List p={0} className="pagenumbers" display="flex" alignItems="center" flexDirection="row" gap="10px">
            <ListItem>
              <Arrow testID="downArrow" handlePageChange={handlePageChange} direction="decrease" />
            </ListItem>
            <ListItem>
              <Link
                textDecoration={currentPage === 1 ? 'underline' : 'none !important'}
                data-testid="pageNumber"
                onClick={() => setCurrentPage(1)}
                onKeyDown={(e) => e.key === 'Enter' && setCurrentPage(1)}
              >
                1
              </Link>
            </ListItem>
            {!(currentPage <= PAGE_LIMIT) && <ListItem>...</ListItem>}
            <PageNumbers />
            {!(currentPage >= totalPages - 3) && !(PAGE_LIMIT === totalPages) && <ListItem>...</ListItem>}
            <ListItem>
              <Link
                data-testid="pageNumber"
                textDecoration={currentPage === totalPages ? 'underline' : 'none !important'}
                onClick={() => setCurrentPage(totalPages)}
                onKeyDown={(e) => e.key === 'Enter' && setCurrentPage(totalPages)}
              >
                {totalPages}
              </Link>
            </ListItem>
            <ListItem>
              <Arrow testID="upArrow" handlePageChange={handlePageChange} direction="increase" />
            </ListItem>
          </List>
        </GridItem>
      )}
    </>
  );
};
