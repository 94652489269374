import React from 'react';
import { NavItem, NavItemProps } from '../NavItem/NavItem';

interface MenuNavLinks {
  variant?: 'mainNav' | 'subNav';
  router: boolean;
  navigationItems: NavItemProps[];
  notificationCount?: number;
  close?(): void;
}

const MenuTabs = (props: MenuNavLinks): JSX.Element => {
  return (
    <>
      {props.navigationItems?.map((item: NavItemProps, key: number) => (
        <NavItem
          close={props.close}
          variant={props.variant}
          router={props.router}
          notificationCount={props.notificationCount}
          {...item}
          key={key}
        />
      ))}
    </>
  );
};

export default MenuTabs;
