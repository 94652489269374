import { createSlice } from '@reduxjs/toolkit';

const initialState = { hasError: false, error: {} };

export const handleError = createSlice({
  name: 'Handling errors',
  initialState,
  reducers: {
    setShowError: (_state, { payload }) => {
      return payload;
    },
  },
});

export const { setShowError } = handleError.actions;

export default handleError.reducer;
