import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

export interface InfoBoxProps {
  children: ReactElement | ReactElement[];
  desktopPadding?: string;
  mobilePadding?: string;
}

const InfoBox: React.FC<InfoBoxProps> = (props: InfoBoxProps): JSX.Element => {
  return (
    <Box bg="lightblue" padding={{ lg: props.desktopPadding ?? '2rem', base: props.mobilePadding ?? '1rem' }}>
      {props.children}
    </Box>
  );
};

export default InfoBox;
