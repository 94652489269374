import React, { useState } from 'react';
import { Badge, Box, Button, Flex, Grid, GridItem, Heading, Link, Text, useDisclosure } from '@chakra-ui/react';
import { External_White as ExternalWhite } from '../../../ServiceUI/Core/icons/Outline';
import { BaseInductionModal } from './BaseInductionModal';
import { request } from '../../../../helper/useAxios';
import { Pagination } from '../../../ServiceUI/Core/Pagination/Pagination';
import { appConstants } from '../../../../helper/client/constant';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../../../reducers';
import { SubContainer } from '../../../ServiceUI/Core/Layout';
import { Tooltip } from '../../../ServiceUI/Core/Tooltip/Tooltip';
interface BaseInductionProps {
  baseName: string;
  expiryDate: Date | string;
  caseKey: string;
  onOpen?(): void;
}
export interface BaseInductionsProps {
  heading: string;
  subHeading: string;
  badgeContent: string;
  basesInducted: BaseInductionProps[];
  newInductionHeading: string;
  newInductionSubHeading: string;
  newInductionCTAText: string;
  newInductionCTALink: string;
  tooltip: string;
}

//Component for a singular base induction tile
export const BaseInduction: React.FC<BaseInductionProps> = (props: BaseInductionProps) => {
  //Taking the input date and converting it to a JS date, creating a string version in long date style: "Month date, year"
  const date = new Date(props.expiryDate).toLocaleString('en-US', {
    dateStyle: 'long',
  });

  //Returning item
  return (
    <GridItem
      data-testid="induction"
      bg="white"
      colSpan={{ lg: 1, base: 2 }}
      p="0.5rem 1rem"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Text m="0" fontWeight="bold">
          {props.baseName}
        </Text>
        <Text m="0" fontWeight="light">
          Expires: {date}
        </Text>
      </Box>
      <Button onClick={props.onOpen} variant="secondary" w={{ lg: 'auto', base: '100%' }}>
        View
      </Button>
    </GridItem>
  );
};

export const BaseInductions: React.FC<BaseInductionsProps> = (props: BaseInductionsProps) => {
  //Is the modal open?
  const { isOpen, onToggle } = useDisclosure();

  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  //View button handler, sets the case key to be the one corresponding to the tile the view button was pressed on, and then opens the modal
  const handleOpen = (caseKey) => {
    request(appConstants.API_CASES, 'get', { id: caseKey })
      .then((res) => {
        setContent(res.data.content.InteractionData.pyInput.pyMessage);
        onToggle();
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //Returning all three sections of the component: Heading/Subheading, Badge/induction tiles, and New base induction CTA section
  return (
    <>
      <Box py="2rem" bg="whispergrey">
        <SubContainer>
          {/* Heading/Subheading */}
          <Flex gap={1} alignItems="center">
            <Heading as="h3" fontSize="xlarge" display="inline" fontWeight="medium" m={0}>
              {props.heading}
            </Heading>
            <Tooltip placement="right" content={props.tooltip} />
          </Flex>
          {/* <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={headingTooltip}>
            <InfoCircle className="ms-1 d-inline" size={15} />
          </OverlayTrigger> */}
          <Text fontWeight="light">{props.subHeading}</Text>
          {/* Badge/induction tiles */}
          <Badge bg="badge_green" color="white" borderRadius="1rem" padding="0.25rem 0.5rem">
            {props.badgeContent}
          </Badge>
          <Grid templateColumns="repeat(2,1fr)" gap={3} mt="1rem">
            <Pagination itemsPerPage={6} itemSpan={2}>
              {props.basesInducted?.length > 0 ? (
                props.basesInducted?.map((item: BaseInductionProps, key: number) => (
                  <BaseInduction key={key} {...item} onOpen={() => handleOpen(item.caseKey)} />
                ))
              ) : (
                <Text fontStyle="italic">No active base inductions</Text>
              )}
            </Pagination>
          </Grid>
          {/* New base induction CTA section */}
          <Grid templateColumns="repeat(3,1fr)" mt="1rem">
            <GridItem colSpan={3}>
              <Heading fontSize="xlarge" fontWeight="medium">
                {props.newInductionHeading}
              </Heading>
            </GridItem>
            <GridItem colSpan={{ lg: 2, base: 3 }}>
              <Text fontWeight="light">{props.newInductionSubHeading}</Text>
            </GridItem>
            <GridItem colSpan={{ lg: 1, base: 3 }}>
              <Button
                as={Link}
                href={props.newInductionCTALink}
                variant="primary"
                float="right"
                display="flex"
                gap={1}
                w={{ lg: 'auto', base: '100%' }}
                target="_blank"
              >
                {props.newInductionCTAText}
                <ExternalWhite height="1rem" />
              </Button>
            </GridItem>
          </Grid>
        </SubContainer>
      </Box>
      <BaseInductionModal isOpen={isOpen} onClose={onToggle} content={content} />
    </>
  );
};
