import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { NavLink, useLocation } from 'react-router-dom';
import { StatusBadge } from '../CreateRequest/StatusBadge/StatusBadge';
import { appConstants } from '../../../helper/client/constant';
import Header from '../../ServiceUI/Core/Header/Header';
import check from '../../../images/check.svg';
import content from '../../../pages/ServiceRequest/Content/ReviewRequest.json';
import data from '../../../pages/ServiceRequest/Content/Complete.json';
import './ThankyouPage.scss';
import { SubContainer } from '../../ServiceUI/Core/Layout/SubContainer/SubContainer';

const ThankyouPage = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {data.idNote}
    </Tooltip>
  );
  useEffect(() => {
    document.title = 'Request completed';
  }, []);

  const location = useLocation();
  const isMinorRequestParam = location.state[0].isMinorRequest;
  const [value, setValue] = useState('');
  const [caseDetails, setCaseDetails] = useState(location.state[0]);

  const date = new Date();
  const options = { month: 'long' };

  return (
    <>
      <Header
        {...content.header}
        mainHeading={
          caseDetails.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
            ? appConstants.TITLE_REQUEST_TYPE_MAINTENANCE
            : appConstants.TITLE_REQUEST_TYPE_NOT_MAINTENANCE
        }
        subHeading={isMinorRequestParam ? content.header.subHeading : 'Submit your base service request online'}
      />

      <Container fluid>
        <SubContainer>
          <Container className="py-6">
            <Image data-testid="icon" src={check} alt={data.iconAltText} height={100} className="mb-3" />
            <h1>{data.subHeading}</h1>
            <Container className="my-3 p-4 bg-defencemediumlightblue">
              <h3>{data.subSectionHeading}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.subSectionContent,
                }}
                className="fw-light"
              ></p>

              <h3>
                {data.SRNumberHeading} {caseDetails?.content?.GemsNotificationID}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.SRNote,
                }}
              ></p>

              <Row lg={6}>
                <Col lg={3}>
                  <h3>Request information</h3>
                </Col>
              </Row>
              <Row lg={6}>
                <Col lg={3}>
                  <p className="fw-bold mb-0">Request name</p>
                  <p className="fw-light sr-req-name">{caseDetails?.content?.ServiceRequest.RequestTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <p className="fw-bold mb-0">Date requested</p>
                  <p className="fw-light">
                    {date.getDate()} {new Intl.DateTimeFormat('en-US', options).format(date)} {date.getFullYear()}
                  </p>
                </Col>
                <Col lg={4}>
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                    <span>
                      <span className="fw-bold me-2">ServiceConnect ID</span>
                      <InfoCircle size={15} />
                    </span>
                  </OverlayTrigger>
                  <p className="fw-light">{caseDetails?.content?.pxInsName}</p>
                </Col>
                {!!caseDetails?.content?.GemsNotificationID && (
                  <Col lg={4}>
                    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                      <span>
                        <span className="fw-bold me-2">Service Request Number</span>
                        <InfoCircle size={15} />
                      </span>
                    </OverlayTrigger>
                    <p className="fw-light">{caseDetails?.content?.GemsNotificationID}</p>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={3}>
                  <p className="fw-bold mb-0">Current Status</p>

                  <StatusBadge status={caseDetails?.status}></StatusBadge>
                </Col>
              </Row>
            </Container>
            <Button
              as={NavLink}
              data-testid="BackButton"
              className="float-end"
              variant="primary"
              to={data.backButtonLink}
            >
              {data.backButtonText}
            </Button>
          </Container>
        </SubContainer>
      </Container>
    </>
  );
};

export default ThankyouPage;
