import { Box, Flex, Grid, GridItem, Heading, Show, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { Pagination } from '../../Core/Pagination/Pagination';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import { PIR } from '../types';
import { PIRItem } from './PIRItem';

export interface PIRDashboardProps {
  heading: string;
  tooltipContent?: string;
  noActiveRequestsMessage: string;
  pirs: PIR[];
}

export const PIRDashBoard: React.FC<PIRDashboardProps> = (props: PIRDashboardProps) => {
  return (
    <>
      <Box data-testid="pir-dashboard" py="3.5rem">
        <Flex alignItems="center" gap={1}>
          <Heading as="h2" size="sm" fontWeight="medium" m={0}>
            {props.heading}
          </Heading>
          {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
        </Flex>
        {props.pirs?.length > 0 ? (
          <>
            <Show above="lg">
              <Grid px="1.25rem" gridTemplateColumns="repeat(5,1fr)" gap={3}>
                <Spacer as={GridItem} colSpan={3} />
                <Text m={0} color="charcoalgrey" fontWeight="light" fontSize="xxs">
                  Date submitted
                </Text>
              </Grid>
            </Show>
            <Pagination itemsPerPage={6}>
              {props.pirs?.map((pirItem: PIR, key: number) => (
                <PIRItem {...pirItem} key={key} />
              ))}
            </Pagination>
          </>
        ) : (
          <Text fontWeight="light" mt="1rem">
            {props.noActiveRequestsMessage}
          </Text>
        )}
      </Box>
    </>
  );
};
