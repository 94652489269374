import React from 'react';
import { SubContainer } from '../Layout';
import { Grid as ChakraGrid } from '@chakra-ui/react';
import { Pagination } from '../Pagination/Pagination';

interface GridProps {
  children: JSX.Element | JSX.Element[];
  itemsPerPage: number;
  itemsPerRow?: number;
  gap?: number;
  reset?: number;
}

export const Grid: React.FC<GridProps> = (props: GridProps): JSX.Element => {
  return (
    <SubContainer>
      <ChakraGrid
        display={{ lg: 'grid', base: 'flex' }}
        flexDirection="column"
        templateColumns={props.itemsPerRow ? `repeat(${props.itemsPerRow}, 1fr)` : 'repeat(3,1fr)'}
        gap={props.gap ?? 12}
      >
        <Pagination itemSpan={12} itemsPerPage={props.itemsPerPage} reset={props.reset}>
          {props.children}
        </Pagination>
      </ChakraGrid>
    </SubContainer>
  );
};
