import React from 'react';
import axios from 'axios';
import { appConstants } from '../../../helper/client/constant';
import { getApiUrl } from '../../../helper/getOtherAppURL';
import Feedback from '../../ServiceUI/Core/Feedback/Feedback';
import FeedbackContent from '../../../components/ServiceUI/Core/Feedback/Content/FeedbackContent.json';

export const CommonFeedback = ({
  bg = 'navy',
  title = 'Please tell us what you need from ServiceConnect',
  selectedService = 'ServiceConnect',
  myServices,
}) => {
  const shouldShow = (item) => {
    //Is there a condition?
    if (item.condition) {
      if (item.condition === 'hasTravel') {
        return myServices?.hasTravel;
      }
    }
    return true;
  };

  return (
    <Feedback
      {...FeedbackContent.feedback}
      bg={bg}
      title={title}
      modalProps={{
        ...FeedbackContent.feedback.modalProps,
        selectedService: selectedService,
        services: FeedbackContent.feedback.modalProps.services.filter(shouldShow),
        submitAction: (data) =>
          axios.post(getApiUrl + appConstants.API_FEEDBACK, data, {
            withCredentials: true,
          }),
      }}
    />
  );
};
