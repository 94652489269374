import React, { useState, useEffect } from 'react';
import { Box, Text, Button, GridItem, Flex } from '@chakra-ui/react';
import { SearchResult, SearchResultProps } from './SearchResult';
import { SortButton } from '../../Core/SortButton/SortButton';
import { Grid } from '../../Core/Card/CardGrid';
import { stripTags } from '../../../../helper/client/striphtml';
import { SubContainer } from '../../Core/Layout';
import { Spinner } from '../../Core/Spinner/Spinner';

export interface SearchResultsProps {
  results: SearchResultProps[];
  isLoading: Boolean;
  onClear?(): void;
}

export const ResultSorter = (array: SearchResultProps[], paramater: string) => {
  const arrayToSort = array;
  const sortFunction = (a: SearchResultProps, b: SearchResultProps) => {
    //@ts-expect-error
    const keyA = stripTags(a[paramater as keyof FormInfo]!).toLowerCase();
    //@ts-expect-error
    const keyB = stripTags(b[paramater as keyof FormInfo]!).toLowerCase();
    if (keyA! > keyB!) return 1;
    if (keyA! < keyB!) return -1;
    return 0;
  };
  arrayToSort.sort(sortFunction);
  return arrayToSort;
};

export const SearchResults: React.FC<SearchResultsProps> = (props: SearchResultsProps) => {
  const [sorter, setSorter] = useState('title');

  const [results, setResults] = useState<SearchResultProps[]>(ResultSorter(props.results, sorter));

  const handleSort = (e) => {
    setSorter(e);
    setResults(ResultSorter(props.results, sorter));
  };

  useEffect(() => {
    setResults(ResultSorter(props.results, sorter));
  }, [props.results]);

  return props.isLoading ? (
    <Spinner />
  ) : (
    <Box pb="1rem">
      <SubContainer flex alignItems="flex-end" verticalMargin="1rem">
        <Flex alignItems={{ lg: 'center', base: 'flex-start' }} gap={2} flexDirection={{ lg: 'row', base: 'column' }}>
          <Text>{results.length} results</Text>
          <Button variant="secondary_dark" onClick={props.onClear}>
            Clear
          </Button>
        </Flex>
        <SortButton
          isSRSort={false}
          text="Sorted by"
          changeSort={(e) => {
            handleSort(e);
          }}
          variant="search"
          currentSort={sorter}
          dropdownItems={[
            { title: 'Title', property: 'title' },
            { title: 'Description', property: 'description' },
          ]}
        />
      </SubContainer>
      <Grid itemsPerRow={12} itemsPerPage={12} gap={3}>
        {results?.map((item: SearchResultProps, key: number) => (
          <SearchResult key={key} {...item} />
        ))}
      </Grid>
    </Box>
  );
};
