import { createSlice } from '@reduxjs/toolkit';

const initialState = { error: false, code: null, errorMessage: '', errorObj: { response: { data: [] } } };

export const handleInternal = createSlice({
  name: 'Handling internal/external check',
  initialState,
  reducers: {
    setInternal: (_state, { payload }) => {
      return payload;
    },
  },
});

export const { setInternal } = handleInternal.actions;

export default handleInternal.reducer;
