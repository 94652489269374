import { Box, Button, Heading, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { SubContainer } from '../Layout';

export interface StartRequestProps {
  iconURL?: string;
  heading?: string;
  ctaText?: string;
  createCase(): any;
}

const Content = (props) => {
  return (
    <Box position="relative">
      <Box
        bg="white"
        borderRadius="0.5rem"
        padding="2rem"
        w={{ xl: '380px', lg: '300px', base: '100%' }}
        position={{ lg: 'absolute', base: 'relative' }}
        marginTop={{ lg: '-12rem', base: '0' }}
        right={0}
      >
        <Image alt="img" src={require(`../../../../images/${props.iconURL}`)} height="45px" />
        <Heading as="h2" marginY="1rem" fontSize="md" fontWeight="medium">
          {props.heading}
        </Heading>
        <Button w="100%" variant="primary" onClick={() => props.createCase()} whiteSpace="break-spaces">
          {props.ctaText}
        </Button>
      </Box>
    </Box>
  );
};
export const StartForm: React.FC<StartRequestProps> = (props: StartRequestProps) => {
  return (
    <>
      <Show above="lg">
        <SubContainer>
          <Content {...props} />
        </SubContainer>
      </Show>
      <Show below="lg">
        <Content {...props} />
      </Show>
    </>
  );
};
