import { Box, Grid, GridItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconCard } from '../../components/ServiceUI/Core/Card/Card';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { StartForm } from '../../components/ServiceUI/Core/StartForm/StartForm';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import { PIRDashBoard } from '../../components/ServiceUI/EFT/PIRDashboard/PIRDashboard';
import { PIR } from '../../components/ServiceUI/EFT/types';
import { getFormsURL } from '../../helper/getOtherAppURL';
import content from './Content/LandingPage.json';
import { newRequest } from './EFTConstant';
import { appConstants } from '../../helper/client/constant';
import { setShowError } from '../../reducers/errorHandling';
import { useDispatch } from 'react-redux';
import { request } from '../../helper/useAxios';
import { CommonFeedback } from '../../components/common/CommonFeedback/commonFeedback';

export const LandingPage: React.FC<any> = ({ myServices }) => {
  const [pirItems, setPIRItems] = useState<PIR[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Personal information request';
    request(appConstants.API_RECENT_PIRS, 'get')
      .then((data) => data && setPIRItems(data.data.pxResults))
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  }, []);

  const navigate = useNavigate();
  const createPIRCaseFunc = () => {
    navigate(newRequest);
  };
  return (
    <Box bg="whispergrey">
      <Header {...content.header} />
      <StartForm {...content.startform} createCase={createPIRCaseFunc} />
      <SubHeader {...content.top_subheader} />
      <SubContainer>
        <PIRDashBoard {...content.pir_dashboard} pirs={pirItems} />
      </SubContainer>
      <SubHeader {...content.bottom_subheader} />
      <SubContainer>
        <Grid mb="3.5rem" gridTemplateColumns="repeat(2,1fr)" gap={{ lg: '2rem', base: '1rem' }}>
          {content.cards.map((i, idx) => (
            <GridItem colSpan={{ lg: 1, base: 2 }} key={idx}>
              <IconCard {...i} linkURL={i.isAEM ? getFormsURL() : i.linkURL} />
            </GridItem>
          ))}
        </Grid>
      </SubContainer>
      <CommonFeedback
        title="Please tell us what you need from Personal Information Request"
        selectedService="Personal Information Request"
        myServices={myServices}
      />
    </Box>
  );
};
