import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { appConstants } from '../../../../helper/client/constant';
import SRFilesUploaded from '../../CreateRequest/SRFilesUploaded';

const RequestDetailReadOnly = (props) => {
  const [reqDetailData, setReqDetailData] = useState({
    reqTitle: '',
    amountOfAsset: '',
    assetNumber: '',
    reqDescription: '',
    cccCode: '',
    reqRelatesTo: '',
    reqRelationDescription: '',
    serviceType: '',
    workType: '',
    workSubType: '',
    dynamicSelectionArr: [],
  });
  const returnAllCateringFields = (field, index1) => {
    if (!!field.wholeObj) {
      return (
        <Container key={index1}>
          {field.label === appConstants.SR_TYPE_CATERING_MEAL_DETAILS && (
            <h2>{field.value === undefined ? field.label : field.value}</h2>
          )}
          {field.label !== appConstants.SR_TYPE_CATERING_MEAL_DETAILS && (
            <h3>{field.value === undefined ? field.label : field.value}</h3>
          )}
          <>
            {field.wholeObj.map((item, index) => {
              if (item.wholeObj === undefined && item.value !== undefined) {
                return (
                  <Container key={index}>
                    <Form.Group controlId={index + 'dynamicFormField'} className="w-75 mb-4">
                      <Form.Label>{item.label}</Form.Label>

                      <p className="fw-light">{item.value}</p>
                    </Form.Group>
                  </Container>
                );
              } else {
                return returnAllCateringFields(item, index);
              }
            })}
          </>
        </Container>
      );
    } else {
      if (field.value !== undefined) {
        return (
          <Container key={index1}>
            <Form.Group controlId={index1 + 'dynamicFormField'} className="w-75 mb-4">
              <Form.Label>{field.label}</Form.Label>

              <p className="fw-light">{field.value}</p>
            </Form.Group>
          </Container>
        );
      }
      // field.value !== undefined && (
      //   <Container key={index1} className="ps-4 ps-lg-0">
      //     <Form.Group
      //       controlId={index1 + "dynamicFormField"}
      //       className="w-75 mb-4"
      //     >
      //       <Form.Label>{field.label}</Form.Label>

      //       <p className="fw-light">{field.value}</p>
      //     </Form.Group>
      //   </Container>
      // );
    }
  };

  const getdisplayNameAndValue = (elementsArrFromPega, workType) => {
    return elementsArrFromPega.map((field) => {
      if (workType !== appConstants.SR_TYPE_CATERING) {
        if (!!field.DisplayValue || field.Value) {
          return {
            label: field.DisplayName,
            value: !!field.DisplayValue ? field.DisplayValue : field.Value,
          };
        }
      } else {
        if (!!field.DisplayName || field.Value) {
          return {
            label: field.DisplayName,
            value: !!field.DisplayValue ? field.DisplayValue : field.Value,
            wholeObj:
              !!field?.Attributes && field?.Attributes.length > 0
                ? getdisplayNameAndValue(field.Attributes, appConstants.SR_TYPE_CATERING)
                : undefined,
          };
        }
      }
    });
  };
  useEffect(() => {
    let dynamicArr;
    if (!!props.reqData[0].RequestDetail.Attributes) {
      dynamicArr = getdisplayNameAndValue(props.reqData[0].RequestDetail.Attributes, props.reqData[0].WorkType);
    }
    if (props.reqData[0].ServiceType === appConstants.SERVICE_REQUEST_TYPE) {
      setReqDetailData((prevState) => {
        return {
          ...prevState,
          reqTitle: props.reqData[0].RequestTitle,
          amountOfAsset: props.reqData[0].AmountOfAssets,
          assetNumber: props.reqData[0].AssetNumber,
          reqDescription: props.reqData[0].pyDescription,
          cccCode: props.reqData[0].CostCodeCentre,
          reqRelatesTo: props.reqData[0].RequestRelatesTo,
          reqRelationDescription: props.reqData[0].RequestRelationDetail,
          serviceType: props.reqData[0].ServiceType,
          workType: props.workTypeDesc,
          workSubType: props.workSubTypeDesc,
          dynamicSelectionArr: !!props.reqData[0].RequestDetail.Attributes
            ? [...dynamicArr]
            : prevState.dynamicSelectionArr,
        };
      });
    } else {
      setReqDetailData((prevState) => {
        let obj = {
          ...prevState,
          reqTitle: props.reqData[0].RequestTitle,
          // amountOfAsset: props.reqData[0].AmountOfAssets,
          // assetNumber: props.reqData[0].AssetNumber,
          reqDescription: props.reqData[0].pyDescription,
          cccCode: props.reqData[0].CostCodeCentre,
          reqRelatesTo: props.reqData[0].RequestRelatesTo,
          reqRelationDescription: props.reqData[0].RequestRelationDetail,
          serviceType: props.reqData[0].ServiceType,
          workType: props.workTypeDesc,
          dynamicSelectionArr: !!props.reqData[0].RequestDetail.Attributes
            ? [...dynamicArr]
            : prevState.dynamicSelectionArr,
        };
        //old Service Type Access so does not return undefined
        if (obj.serviceType === 'Access') {
          obj = {
            ...obj,
            workType: 'Access',
          };
        }
        return obj;
      });
    }
  }, [props]);

  return (
    <>
      {reqDetailData?.workType?.length > 0 && (
        <Container className="bg-defencewhite mt-4">
          <Container>
            <h1>Request information</h1>
            <Container>
              <Form.Group controlId="workType">
                <Form.Label className="p-lg-0">What type of service do you require?</Form.Label>
                <br />
                <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                  <p className="fw-light">{reqDetailData.workType}</p>
                </Container>
              </Form.Group>
            </Container>
            {reqDetailData.serviceType === appConstants.SERVICE_REQUEST_TYPE && (
              <Container>
                <Form.Group controlId="subtypes">
                  <Form.Label className="p-lg-0">What do you need help with?</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{reqDetailData.workSubType}</p>
                  </Container>
                </Form.Group>
              </Container>
            )}
            <Container>
              <Form.Group controlId="reqTitle">
                <Form.Label className="p-lg-0">Request Title</Form.Label>
                <br />
                <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                  <p className="fw-light">{reqDetailData.reqTitle}</p>
                </Container>
              </Form.Group>
            </Container>
            <Container>
              <Form.Group controlId="reqTitle">
                <Form.Label className="p-lg-0">Request Relates to</Form.Label>
                <br />
                <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                  <p className="fw-light">{reqDetailData.reqRelatesTo}</p>
                </Container>
              </Form.Group>
            </Container>
            {reqDetailData.reqRelatesTo !== 'None' ? (
              <Container>
                <Form.Group controlId="reqTitle">
                  <Form.Label className="p-lg-0">Request Relates to description</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{reqDetailData.reqRelationDescription}</p>
                  </Container>
                </Form.Group>
              </Container>
            ) : (
              <></>
            )}

            {reqDetailData.cccCode !== undefined && reqDetailData.cccCode.length > 0 && (
              <Container>
                <Form.Group controlId="costCodeCenter" className="w-75 mb-4">
                  <Form.Label>Cost Centre Code</Form.Label>

                  <p className="fw-light">{reqDetailData.cccCode}</p>
                </Form.Group>
              </Container>
            )}

            {reqDetailData.serviceType === appConstants.SERVICE_REQUEST_TYPE && (
              <>
                <Container>
                  <Form.Group controlId="amountAssets">
                    <Form.Label className="p-lg-0">Amount of assets</Form.Label>
                    <br />
                    <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                      <p className="fw-light">{reqDetailData.amountOfAsset}</p>
                    </Container>
                  </Form.Group>
                </Container>
                <Container>
                  <Form.Group controlId="assetNumber">
                    <Form.Label className="p-lg-0">Asset number (if one exists or is known)</Form.Label>
                    <br />
                    <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                      <p className="fw-light">{reqDetailData.assetNumber}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </>
            )}
            {reqDetailData.reqDescription !== undefined && (
              <Container>
                <Form.Group controlId="issueDescription" className="w-75 mb-4">
                  <Form.Label>Please describe the issue</Form.Label>

                  <p className="fw-light">{reqDetailData.reqDescription}</p>
                </Form.Group>
              </Container>
            )}

            {reqDetailData.workType !== appConstants.SR_TYPE_CATERING_DESC &&
              reqDetailData.dynamicSelectionArr.map((item, index) => {
                if (
                  item &&
                  item?.value !== '::' &&
                  !(item?.label === 'Return Leg Required' && item?.value === 'false')
                ) {
                  return (
                    <Container key={index}>
                      <Form.Group controlId={index + 'dynamicFormField'} className="w-75 mb-4">
                        <Form.Label>{item.label}</Form.Label>
                        <p className="fw-light">{item.value}</p>
                      </Form.Group>
                    </Container>
                  );
                }
              })}

            {reqDetailData.workType === appConstants.SR_TYPE_CATERING_DESC &&
              reqDetailData.dynamicSelectionArr.map((item, index) => {
                if (item) {
                  return returnAllCateringFields(item, index);
                }
              })}
            <SRFilesUploaded header="Uploaded files:" ctaAction="download" ctaText="download" caseID={props.caseID} />
          </Container>
        </Container>
      )}
    </>
  );
};

export default RequestDetailReadOnly;
