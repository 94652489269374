import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { appConstants } from '../../../helper/client/constant';
import { request } from '../../../helper/useAxios';

//Modals
import AuthenticationModal from '../../Modals/AuthenticationModal/AuthenticationModal';
import GenericErrorModalProps from '../../Modals/GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../Modals/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../Modals/SystemOutageModal/__mocks__/default.json';
import GenericErrorModal from '../../../components/Modals/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../../components/Modals/SystemOutageModal/SystemOutageModal';

const SRFilesUploaded = ({ uploadedFile, header, ctaAction, ctaCallback, ctaText, caseID }) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const getUpdatedFilesFromPega = (responseFromPega) => {
    const filteredArrayNonCorrespondese = responseFromPega.attachments.filter(
      (file) =>
        file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
        file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
    );

    const fileInfoArray = filteredArrayNonCorrespondese.map((file) => {
      return {
        name: file.fileName,
        ID: file.ID,
      };
    });
    setUploadedFiles(fileInfoArray);
  };
  useEffect(() => {
    setUploadedFiles(uploadedFile);
  }, [uploadedFile]);
  useEffect(() => {
    if (ctaAction === 'download') {
      request(appConstants.API_CASES + caseID + '/attachments', 'get').then((res) => {
        if (!!res.data.attachments) {
          getUpdatedFilesFromPega(res.data);
        } else {
          setUploadedFiles([]);
        }
      });
    }
  }, [ctaAction]);

  const onClickCTA = (item) => {
    if (ctaAction === 'download') {
      request(appConstants.API_ATTACHMENTS_FILE + item.ID, 'get')
        .then((res) => {
          let a = document.getElementById('download-attachment');
          a.href = 'data:image/png;base64,' + res.data;
          a.download = item.name;
          a.click();
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    }
    ctaCallback?.(item);
  };

  return (
    uploadedFiles?.length > 0 && (
      <>
        <Card className="file-container-box">
          <Card.Header>{header}</Card.Header>
          <Card.Body className="file-container-card">
            {uploadedFiles?.map((item, index) => {
              return (
                <Row key={index + 'uploadedfile'} className="req-detail-default-padding">
                  <Col sm={10} className="file-name-left-req-detail">
                    {item.name}
                  </Col>
                  <Col sm={2} className="file-name-center-req-detail">
                    <Button variant="dark" className="mobileButton" onClick={() => onClickCTA(item)} size="sm">
                      {ctaText}
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Card.Body>
          <a hidden id="download-attachment" />
        </Card>

        {errorModal && (
          <GenericErrorModal
            title={GenericErrorModalProps.title}
            ctaText={GenericErrorModalProps.ctaText}
            error={APIError}
          />
        )}
        {serviceDownModal && (
          <SystemOutageModal
            title={SystemOutageModalProps.title}
            description={SystemOutageModalProps.description}
            ctaText={SystemOutageModalProps.ctaText}
          />
        )}
        {showModal && (
          <AuthenticationModal
            title={AuthenticationModalProps.title}
            description={AuthenticationModalProps.description}
            ctaText={AuthenticationModalProps.ctaText}
          />
        )}
      </>
    )
  );
};

export default SRFilesUploaded;
