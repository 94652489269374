import * as React from 'react';
import { SVGProps } from 'react';
const Cross = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_8_8)">
      <path
        fill="white"
        d="M15.8193 0.179653C15.7623 0.122587 15.6946 0.0773169 15.6201 0.0464304C15.5455 0.015544 15.4657 -0.000354739 15.385 -0.000354739C15.3043 -0.000354739 15.2244 0.015544 15.1499 0.0464304C15.0754 0.0773169 15.0077 0.122587 14.9507 0.179653L7.99947 7.13085L1.04828 0.179653C0.991241 0.122618 0.923531 0.0773744 0.84901 0.046507C0.77449 0.0156397 0.69462 -0.000247914 0.61396 -0.000247914C0.5333 -0.000247914 0.45343 0.0156397 0.378909 0.046507C0.304389 0.0773744 0.236678 0.122618 0.179643 0.179653C0.122607 0.236688 0.0773643 0.304399 0.046497 0.378919C0.0156296 0.45344 -0.000257992 0.53331 -0.000257992 0.61397C-0.000257992 0.69463 0.0156296 0.7745 0.046497 0.84902C0.0773643 0.923541 0.122607 0.991251 0.179643 1.04829L7.13084 7.99948L0.179643 14.9507C0.0644546 15.0659 -0.000257997 15.2221 -0.000257992 15.385C-0.000257987 15.5479 0.0644546 15.7041 0.179643 15.8193C0.294831 15.9345 0.451059 15.9992 0.61396 15.9992C0.77686 15.9992 0.933089 15.9345 1.04828 15.8193L7.99947 8.86812L14.9507 15.8193C15.0077 15.8763 15.0754 15.9216 15.1499 15.9525C15.2245 15.9833 15.3043 15.9992 15.385 15.9992C15.4656 15.9992 15.5455 15.9833 15.62 15.9525C15.6946 15.9216 15.7623 15.8763 15.8193 15.8193C15.8763 15.7623 15.9216 15.6946 15.9525 15.62C15.9833 15.5455 15.9992 15.4657 15.9992 15.385C15.9992 15.3043 15.9833 15.2245 15.9525 15.1499C15.9216 15.0754 15.8763 15.0077 15.8193 14.9507L8.86811 7.99948L15.8193 1.04829C15.8764 0.991268 15.9216 0.923563 15.9525 0.849041C15.9834 0.774518 15.9993 0.69464 15.9993 0.61397C15.9993 0.5333 15.9834 0.453422 15.9525 0.378899C15.9216 0.304376 15.8764 0.236671 15.8193 0.179653Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={15.141} height={15.141} fill="white" transform="scale(1.05673)" />
      </clipPath>
    </defs>
  </svg>
);
export default Cross;
