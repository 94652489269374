const content = {
  ServiceType: {
    fieldColumnWidth: {
      desktop: 8,
      mobile: 12,
    },
    validation: {
      required: 'Please select the type of service you are requesting.',
    },
  },
  TypeOfRecords: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please select at least one value against Type of record.',
    },
  },
  ApplicantType: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please select the type of applicant that represents you.',
    },
  },
  AdditionalAOADetails: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide details of amendment or annotation.',
    },
  },
  AdditionalDetails: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: "Please provide additonal details for 'Other' selection against 'Type of record'.",
    },
  },
  DateOfFuneral: {
    fieldColumnWidth: { desktop: 6, mobile: 12 },
    validation: {
      required: 'Please provide funeral date.',
    },
  },
  GivenNames: {
    maxLength: 50,
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'First name is required.',
    },
  },
  FullName: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Full name is required.',
    },
  },
  Surname: {
    maxLength: 50,
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Last name is required.',
    },
  },
  Address: {
    maxLength: 254,
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Contact number is required.',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  Number: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Contact number is required.',
    },
  },
  ContactMethod: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  ServiceArea: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Service area(s)is required.',
    },
  },
  IsActiveInForce: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Status is required.',
    },
  },
  DateOfBirth: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  PMKeySNumber: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  ServiceUnit: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  PlannedDischargeDate: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  IsDeceased: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
  },
  YearOfDischarge: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      pattern: {
        value: /^(19|20)\d*$/,
        message: 'Year of discharge must start with ‘19’ or ‘20’.',
      },
      minLength: {
        value: 4,
        message: 'Year of discharge must be 4 characters.',
      },
    },
  },
  Relationship: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Relationship description is required.',
    },
  },
  OrganisationName: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Advocate Organisation name is required.',
    },
  },
  OrganisationType: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    validation: {
      required: 'Advocate Organisation type is required.',
    },
  },
  Acknowledged: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please accept the terms of the Personal information request privacy notice.',
    },
  },
  ProofOfIdentification: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide certified proof of identity documents for the member.',
    },
  },

  SignedConsent: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide signed consent from the member.',
    },
  },
  ProofOfDeath: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide proof of death for the member.',
    },
  },
  MedicalSupportingDocuments: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide additional suppporting documents required for medical or psychological records.',
    },
  },
  ProofOfFamilyMember: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide proof of family member relationship to member.',
    },
  },
  ProofOfFamilyExMember: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide proof of family member relationship to member.',
    },
  },
  FamilyMemberSignedConsent: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide signed consent from the family member.',
    },
  },
  FamilyMemberIdentification: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide certified proof of identity documents for the family member.',
    },
  },
  FamilySignedConsent: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide signed form for application.',
    },
  },
  Declaration: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    validation: {
      required: 'Please provide signed form for application.',
    },
  },
  OfficialDocuments: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
  },
};

export const dynamicFields = {
  IsActiveInForce: {
    target: '.PersonalInfoRequest.SelfApplicantDetails',
    multiAction: true,
    sendListIndex: false,
  },
  IsPersonnelInformation: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
  ApplicantType: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
  ServiceType: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
};

export default content;
