import React, { useEffect } from 'react';
import { Box, SystemProps } from '@chakra-ui/react';

export interface ServiceUIDrawer {
  direction: string;
  open: boolean;
  bg: string;
  children: JSX.Element;
  zIndex?: SystemProps['zIndex'];
  top?: string;
}

const ServiceUIDrawer: React.FC<ServiceUIDrawer> = (props: ServiceUIDrawer): JSX.Element => {
  //locking scroll when a drawer is open
  useEffect(() => {
    const x = document.getElementsByTagName('body')[0];
    if (props.open === true) {
      x.style.overflowY = 'hidden';
    } else {
      x.style.overflowY = 'visible';
    }
  }, [props.open]);
  return (
    <>
      {props.direction === 'top' && (
        <Box
          top={props.top}
          data-testid="drawer"
          display="flex"
          flexWrap="wrap"
          position="absolute"
          overflow="clip"
          transitionDelay="0"
          maxHeight={props.open ? '100vh' : 0}
          transition="max-height 0.5s ease-in-out"
          bg={props.bg}
          w="100%"
          zIndex={props.zIndex}
        >
          {props.children}
        </Box>
      )}
      {props.direction === 'left' && (
        <Box
          top={props.top}
          zIndex={5}
          position="absolute"
          overflow="clip"
          minHeight="100%"
          height="100vh"
          maxWidth={props.open ? '100%' : 0}
          transitionDelay="0"
          transition="max-width 0.5s ease-in-out"
          bg={props.bg}
          w="100%"
        >
          {props.children}
        </Box>
      )}
    </>
  );
};

export default ServiceUIDrawer;
