import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import findAllItems from '../../../helper/findAllItems';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import fieldPropMapping from './RequestDetailsForm/fieldPropsMapping';
import Alert from '../../ServiceUI/Core/icons/Outline/Alert';
const DisplayErrorMessage = () => {
  const formFields = useSelector((state) => state);
  const [allItems, setAllItems] = useState([]);
  useEffect(() => {
    const items = findAllItems(formFields, 'fieldID');
    const errorItems = items.filter((item) => item.error);
    const customErrorItems = items.filter((item) => item.customError);
    const allErrors = errorItems.concat(customErrorItems);
    let seen = new Set();
    let filteredErrorList = allErrors.filter((obj) => {
      let duplicate = seen.has(obj.reference);
      seen.add(obj.reference);
      return !duplicate;
    });
    setAllItems(filteredErrorList);
  }, [formFields]);

  const printLabel = (field) => {
    const labelValue = field.label;
    if (labelValue === '') {
      return fieldPropMapping[field.fieldID]?.required?.errorMessage;
    } else {
      if (labelValue === 'Text Input') {
        return 'Attachment is required.';
      }
      if (labelValue === 'Request Title') {
        return 'Field "Name the request" is required.';
      } else {
        return 'Field "' + String(labelValue).replace(/&#40;/g, '(').replace(/&#41;/g, ')') + '" is required.';
      }
    }
  };

  const printCustomErrorLabel = (field) => {
    const labelValue = field.label;
    if (labelValue === '') {
      return fieldPropMapping[field.fieldID]?.required?.errorMessage;
    } else {
      if (labelValue === 'Text Input') {
        return 'Attachment is required.';
      } else {
        return (
          'Field "' +
          String(labelValue).replace(/&#40;/g, '(').replace(/&#41;/g, ')') +
          '" must not be in past and not be in the next 7 days.'
        );
      }
    }
  };

  return (
    <Box>
      {allItems.length > 0 && (
        <>
          <Heading as="h2" fontSize="regular" fontWeight="regular">
            Please enter the following mandatory fields
          </Heading>
          {allItems.map((item: any, index) => {
            if (item.error) {
              return (
                <Flex alignItems="center" gap={1} key={index + '-error-msg-loc'}>
                  <Alert height="0.8rem" />
                  <Text m={0} color="redalert" fontSize="xxs">
                    {printLabel(item)}
                  </Text>
                </Flex>
              );
            }
            if (item.customError) {
              return (
                <Flex alignItems="center" gap={1} key={index + '-error-msg-loc'}>
                  <Alert height="0.8rem" />
                  <Text m={0} color="redalert" fontSize="xxs">
                    {printCustomErrorLabel(item)}
                  </Text>
                </Flex>
              );
            }
            return null;
          })}
        </>
      )}
    </Box>
  );
};

export default DisplayErrorMessage;
