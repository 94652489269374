import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
export interface ActionButtonsProps {
  setShowDeleteModal(value: boolean): any;
  savePIRHandler(value: boolean): any;
  isFormValid: boolean;
  isSubmitDisabled?: boolean;
}
export const ActionButtons: React.FC<ActionButtonsProps> = (props: ActionButtonsProps) => {
  return (
    <Flex justifyContent="space-between" flexDir={{ lg: 'row', base: 'column' }} gap={3} mb="3rem" mt="37px">
      <Flex gap={3} flexDir={{ lg: 'row', base: 'column' }}>
        <Button variant="secondary" onClick={() => props.savePIRHandler(false)}>
          Save & Close
        </Button>
        <Button variant="tertiary" onClick={() => props.setShowDeleteModal(true)}>
          Delete
        </Button>
      </Flex>
      <Button type="submit" variant="primary" isDisabled={props.isSubmitDisabled}>
        Review Request
      </Button>
    </Flex>
  );
};
