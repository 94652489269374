import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import convertSpecialChars from '../../../helper/convertSpecialChars';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormMulti = ({
  fieldID,
  label,
  required,
  newFunction,
  visible,
  reference,
  formData,
  value,
  options,
  fieldPropMapping,
  readOnly,
  showTooltip,
  tooltipContent,
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();

  // const dispatch = useDispatch();
  const onCheckboxChange = (reference, target) => {
    dispatch(
      setFieldValue({
        reference,
        value: target,
        formData,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
      }),
    );
  }, [displayValue]);

  // Prepare options
  // Needs to support data format that is a checkbocx and radio button
  // Radio pxRadioButtons
  // "tooltip": "(eg, RSL, lawyer, funeral home etc)",
  // "value": "I am an advocate for a current or ex-member",
  // "key": "Advocate"

  // Has different labels and optional subtile
  // Also radio buton might not be checked but will havea label

  return (
    <SCForm.Multi
      disabled={readOnly}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      label={label}
      value={value}
      visible={visible}
      required={required}
      id={fieldID}
      validation={fieldPropMapping[fieldID]?.validation}
      // @ts-ignore
      newFunction={newFunction}
      showTooltip={showTooltip}
      tooltipContent={tooltipContent}
      onSelection={(reference, e) => {
        onCheckboxChange(reference, e);
      }}
      options={options?.map((item, idx) => {
        return {
          subtitle: convertSpecialChars(item?.customAttributes?.subtitle || ''),
          key: item.fieldID,
          label: convertSpecialChars(item.control?.label || item.label || ''),
          isChecked: item.value === 'Yes' || item.value === true || item.value === 'true',
          isDisabled: item.disabled,
          reference: item.reference,
        };
      })}
    />
  );
};

export default FormMulti;
