import { Accordion, Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthURL } from '../../../../helper/getOtherAppURL';
import { setShowError } from '../../../../reducers/errorHandling';
import { GenericAccordionItem } from '../Accordion/GenericAccordionItem';
import Modal from '../Modal/Modal';
import ERRORS from './ErrorModalContent.json';
import { AxiosError } from 'axios';
const SERVER_DOWN = [500];
const NOT_FOUND = [400];
const AUTHENTICATION_FAILED = [403, 401];

type ErrorPropsType = AxiosError & {
  response?: {
    status?: number;
    data?: {
      errorDetails?: { message?: string }[];
      errors?: { message?: string }[];
    };
  };
};
export const ErrorHandler = () => {
  let generic = false;
  const ERROR = useSelector((state: { error: { hasError: boolean; error: ErrorPropsType } }) => {
    return state.error;
  });
  const isOpen = ERROR.hasError;
  const errorProps = ERROR.error;
  const errorCode = errorProps.response?.status ?? 500;
  const errorDetails = () => {
    if (errorProps.code) {
      if (NOT_FOUND.includes(errorCode)) {
        const NOT_FOUND_CONTENT =
          errorProps.response?.data &&
          typeof errorProps.response?.data === 'string' &&
          !(errorProps.response?.data as string).includes('!DOCTYPE html')
            ? errorProps.response?.data
            : ERRORS.GENERIC_NOT_FOUND.content;
        return { content: NOT_FOUND_CONTENT, title: 'Error' };
      } else if (AUTHENTICATION_FAILED.includes(errorCode)) {
        return errorCode === 403 ? ERRORS.FORBIDDEN : ERRORS.SESSION_EXPIRED;
      } else if (SERVER_DOWN.includes(errorCode)) {
        // Check if error is coming form file upload and add custom message
        //@ts-ignore as errorDetails does exist in a pega response
        if (errorProps.response?.data?.errorDetails[0]?.message.includes('Error_Upload_File')) {
          return {
            content:
              "Can't continue with file attachment. File is missing and might have been quarantined by anti-malware",
            title: 'Error',
          };
        }
        return errorProps.response?.data &&
          typeof errorProps.response?.data === 'string' &&
          !(errorProps.response?.data as string).includes('!DOCTYPE html')
          ? { content: errorProps.response.data, title: 'Error' }
          : ERRORS.SERVER_DOWN;
      } else if (errorProps?.response?.data?.errors) {
        // If there is a custom message from api that add it to the content
        return {
          content: errorProps.response?.data?.errors[0]?.message,
          title: 'Error',
        };
      } else {
        generic = true;
        return ERRORS.GENERIC;
      }
    }
  };
  const handleClick = () => {
    generic = false;
    errorCode === 401 || errorCode === 403
      ? (window.location.href = getAuthURL())
      : dispatch(setShowError({ hasError: false, error: {} }));
  };
  const dispatch = useDispatch();
  return (
    <Modal
      size={errorCode === 400 ? 'large' : 'small'}
      buttonPlacement="left"
      showErrorIcon
      title={errorDetails()?.title}
      buttonVariant="primary"
      isOpen={isOpen}
      onClose={handleClick}
    >
      <>
        {errorDetails()?.content}
        <Accordion allowToggle>
          {generic && (
            <GenericAccordionItem title="Errors">
              <Text>Code: {errorCode}</Text>
              <Text>Message: {errorProps.message}</Text>
            </GenericAccordionItem>
          )}
        </Accordion>
      </>
    </Modal>
  );
};
