import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import convertSpecialChars from '../../../helper/convertSpecialChars';
import { findByProp } from '../../../helper/findByProp';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormTextInput = ({
  fieldID,
  label,
  fieldPropMapping,
  required,
  visible,
  reference,
  control,
  error,
  formData,
  type,
  value,
  readOnly,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();

  const dispatchFieldValue = (value) => {
    dispatch(
      setFieldValue({
        reference,
        value: value,
        formData,
      }),
    );
  };

  useEffect(() => {
    const convertedValue = convertSpecialChars(reqDetailDataFromReduxStore?.value ?? '');
    setDisplayValue(convertedValue);
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      <SCForm.Text
        desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
        disabled={readOnly}
        id={fieldID}
        reference={reference}
        isInvalid={error}
        label={label}
        max={control?.modes[0]?.max}
        maxLength={control?.modes[0]?.maxChars}
        min={control?.modes[0]?.min}
        minLength={control?.modes[0]?.minChars}
        mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
        readOnly={readOnly}
        required={required}
        validation={fieldPropMapping[fieldID]?.validation}
        value={displayValue}
        visible={visible}
        onBlur={(e) => {
          dispatchFieldValue(e);
        }}
        minHeight="6rem"
      />
    </>
  );
};

export default FormTextInput;
