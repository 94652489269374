import { Box, Button, Heading } from '@chakra-ui/react';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import {
  External_Black as ExternalBlack,
  External_White as ExternalWhite,
} from '../../../ServiceUI/Core/icons/Outline';
import { SubContainer } from '../Layout/SubContainer/SubContainer';
interface CommonProps {
  title: string;
  description: string;
  buttonPosition?: 'right' | 'bottom' | {};
  buttonVariant?: string;
  bg?: string;
  color?: string;
}

interface NoCTA extends CommonProps {
  ctaRequired: never;
}

interface HasCTA extends CommonProps {
  ctaRequired: boolean;
  ctaText: string;
  ctaURL: string;
  isExternal: boolean;
}

export type SubHeaderProps = NoCTA | HasCTA;

const SubHeader: React.FC<SubHeaderProps> = (props: SubHeaderProps): JSX.Element => {
  return (
    <Box color={props.color ?? 'black'} bg={props.bg ?? 'lightblue'} padding="4rem 0">
      <SubContainer
        flex
        direction={{
          lg: props.buttonPosition === 'bottom' ? 'column' : 'row',
          base: 'column',
        }}
        alignItems={props.buttonPosition === 'bottom' ? 'flex-start' : { lg: 'center', base: 'flex-start' }}
      >
        <Box
          w={{
            lg: props.buttonPosition === 'bottom' ? '100%' : '80%',
            base: '100%',
          }}
        >
          <Heading as="h2" fontSize={{ lg: 'md', base: 'sm' }} fontWeight="medium">
            {props.title}
          </Heading>
          <Box
            fontWeight="light"
            data-testid="description"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props.description),
            }}
          />
        </Box>
        <>
          {props.ctaRequired === true && (
            <Button
              //@ts-ignore
              rightIcon={
                props.isExternal &&
                (props.buttonVariant === 'secondary' ? (
                  <ExternalBlack height="1rem" />
                ) : (
                  <ExternalWhite height="1rem" />
                ))
              }
              target={props.isExternal ? '_blank' : ''}
              as={Link}
              to={props.ctaURL}
              marginTop={{ lg: props.buttonPosition === 'bottom' ? '1rem' : 0, base: '1rem' }}
              variant={props.buttonVariant ?? 'secondary_dark'}
            >
              {props.ctaText}
            </Button>
          )}
        </>
      </SubContainer>
    </Box>
  );
};

export default SubHeader;
