import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../../helper/findByProp.js';
import {
  setCateringMealLocation,
  setFieldValue,
  setKnownAllergies,
  setTransportType,
  setWasteExplosive,
} from '../../../../reducers/requestDetailFormReducer.js';
import { RDFormCheckboxLabel, RDFormRadioElement } from './styles.js';

const FormRadio = ({
  fieldID,
  label,
  control,
  fieldPropMapping,
  formData,
  reference,
  visible,
  required,
  isHazardousSelected,
  value,
}) => {
  const dispatch = useDispatch();
  const mealCount = useSelector((state) => state.requestForm.mealCount);
  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(value);
  const [radioSelection, setRadioSelection] = useState(false);
  const onSelectRadio = (e) => {
    setRadioSelection(true);
    setDisplayValue(e.target.getAttribute('inputKey'));

    const selectedValue = control?.modes[0]?.options?.filter((item) => item.value === e.target.value);

    dispatch(
      setFieldValue({
        value: !!selectedValue && selectedValue.length > 0 ? selectedValue[0].key : e.target.value,
        reference,
        formData,
        required,
        visible,
      }),
    );
  };

  useEffect(() => {
    setDisplayValue(reqDetailDataFromReduxStore?.value);
  }, [reqDetailDataFromReduxStore]);

  useEffect(() => {
    if (displayValue !== '') {
      switch (fieldID) {
        case 'TransportType':
          dispatch(
            setTransportType({
              value: displayValue,
              radioSelection,
              formData,
            }),
          );
          break;
        case 'IsExplosiveOrdYN':
          dispatch(setWasteExplosive(displayValue));
          break;
        case 'IsHazardousYN':
          isHazardousSelected(displayValue);

          break;
        case 'MealLocation':
          dispatch(
            setCateringMealLocation({
              selectedItem: displayValue,
              formData,
              mealCount,
              reference,
            }),
          );
          break;
        case 'KnownAllergiesYN':
          dispatch(
            setKnownAllergies({
              fieldID,
              value: displayValue,
            }),
          );
          break;
        default:
          break;
      }
    }
  }, [displayValue, radioSelection, formData.fieldGroup.length]);

  return (
    <>
      {visible && (
        <Col
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-4"
        >
          <Form.Group
            name={reference}
            controlId={reference}
            className={`${fieldPropMapping[fieldID]?.layoutClassName ?? ''}`}
          >
            {label && (
              <Form.Label
                style={{ display: 'block' }}
                className={required ? 'required-field' : ''}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}
            {control?.modes[0]?.options?.map((item) => (
              <RDFormRadioElement key={item.key} type="radio" id={item.key}>
                <Form.Check.Input
                  required
                  onChange={(e) => onSelectRadio(e)}
                  name={reference}
                  type="radio"
                  value={item.value}
                  inputKey={item.key}
                  checked={item.key === displayValue}
                />
                <RDFormCheckboxLabel>{item.value}</RDFormCheckboxLabel>
                <Form.Control.Feedback type="invalid">
                  {fieldPropMapping[fieldID]?.required?.errorMessage}
                </Form.Control.Feedback>
              </RDFormRadioElement>
            ))}
          </Form.Group>
        </Col>
      )}
    </>
  );
};

export default FormRadio;
