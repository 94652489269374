import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionItemProps as ChakraAccordionItemProps,
  Text,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { Chevron_Orange as ChevronOrange } from '../icons/Outline';
import sanitizeHtml from 'sanitize-html';
import { Link } from '../Links';
import { getAemUrl } from '../../../../helper/getOtherAppURL';

interface LinkProps {
  linkURL: string;
  linkTitle: string;
  linkName: string;
  linkType: string;
  linkImage: string;
  isAEM: Boolean;
}

export interface ItemProps extends ChakraAccordionItemProps {
  title: string;
  anchorId?: string;
  content: string;
  isExpanded?: boolean;
  toggleExpand?(): void;
  links?: LinkProps[];
}

const Item: React.FC<ItemProps> = ({
  title,
  content,
  isDisabled,
  isExpanded,
  toggleExpand,
  links,
}: ItemProps): JSX.Element => {
  const AEM_DOMAIN = getAemUrl;
  return (
    <AccordionItem
      isDisabled={isDisabled}
      data-testid="accordion-item"
      border="1px"
      bg="white"
      borderColor="grey"
      marginBottom="1rem"
    >
      <AccordionButton
        display="flex"
        p="1rem 1.875rem"
        justifyContent="space-between"
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-controls="expandAndcollapse"
        onClick={() => toggleExpand && toggleExpand()}
      >
        <Text
          align="left"
          as="h3"
          fontWeight="medium"
          display="flex"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(title),
          }}
        />
        <Box>
          <ChevronOrange style={{ height: '12px', rotate: isExpanded ? '180deg' : '0deg' }} />
        </Box>
      </AccordionButton>
      <AccordionPanel px="1.875rem" pb="1rem">
        <Text
          className="tableborders"
          fontWeight="light"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content),
          }}
        />
        {links?.map((item, idx) => {
          return (
            <Link
              key={idx}
              type={item.linkType}
              text={item.linkName}
              href={item.isAEM ? `${AEM_DOMAIN}${item.linkURL}` : item.linkURL}
            />
          );
        })}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Item;
