export const getLinkType = (type: string | undefined | {}) => {
  switch (type) {
    case 'phone':
      return 'tel:';
    case 'email':
      return 'mailto:';
    case undefined:
      return '';
    default:
      return '';
  }
};
