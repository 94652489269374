import { Box, Button, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { PIRCaseAudit } from '../../../../pages/EFT/API/PIRCaseAudit';
import { setShowError } from '../../../../reducers/errorHandling';
import { PIRStatuses, PegaFile } from '../types';
import { DownloadableFileItem } from './DownloadableFileItem';

interface ReadyToDownloadDocumentsProps {
  files: PegaFile[];
  caseID: string;
  categories: { title: string; property: string; key: string; isReady: boolean | null }[];
  status: PIRStatuses;
}

interface FileCategoryPanelProps {
  category: string;
  files: PegaFile[];
  isReady: boolean | null;
  downloadIndividualFile(_file): Promise<void>;
  downloadAllFiles(_files): void;
  status: PIRStatuses;
}

const ReadyToDownloadDocuments: React.FC<ReadyToDownloadDocumentsProps> = ({
  files,
  caseID,
  categories,
  status,
}: ReadyToDownloadDocumentsProps) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const [downloadedFilesStatus, setDownloadedFilesStatus] = useState<String[]>([]);
  const [downloadedFilesError, setDownloadedFilesError] = useState<String[]>([]);
  const validCategories = categories.filter(
    (item) =>
      (item.property && item.property !== 'No') ||
      (files.some((file) => file.category === 'OfficialDocuments') && item.key === 'OfficialDocuments'),
  );

  const handleError = (error: any) => {
    dispatch(setShowError({ error: true, code: error.response.status, errorObj: error.response }));
  };

  const caseAuditForEachFile = async (file) => {
    if (caseID) {
      try {
        await PIRCaseAudit(caseID, file.ID, 'PIR07');
      } catch (error) {
        handleError(error);
      }
    }
  };

  const downloadAllDocs = async (filesToDownload) => {
    setDownloading(true);
    const promises = filesToDownload.map((file) => onClickCTA(file));
    await Promise.all(promises);
    setDownloading(false);
  };

  const onClickCTA = async (item) => {
    try {
      await caseAuditForEachFile(item);
      const res = await request(appConstants.API_ATTACHMENTS_FILE, 'get', { id: item.ID });
      const el = document.getElementById('download-attachment') as HTMLAnchorElement;
      el.href = 'data:' + item.mimeType + ';base64,' + res.data;
      el.download = item.fileName;
      el.click();
      setDownloadedFilesStatus((prevStatus) => [...prevStatus, item.ID]);
    } catch (error) {
      setDownloadedFilesError((prevError) => [...prevError, item.ID]);
      handleError(error);
    }
  };

  const FileCategoryPanel: React.FC<FileCategoryPanelProps> = (props: FileCategoryPanelProps) => {
    return (
      <TabPanel p={0}>
        <>
          {props.files.length > 0 && (
            <Button
              variant="primary"
              isDisabled={!props.isReady}
              onClick={() => props.downloadAllFiles(props.files)}
              my="1rem"
            >
              Download category
            </Button>
          )}
          <Grid
            as={GridItem}
            colSpan={4}
            gridTemplateColumns={['repeat(1,1fr)', 'repeat(2,1fr)', 'repeat(3,1fr)']}
            mb="1.5rem"
          >
            {props.isReady === true || props.isReady === null ? (
              <>
                {props.files.map((file) => (
                  <DownloadableFileItem
                    key={file.ID}
                    file={file}
                    onClick={onClickCTA}
                    isDownloaded={downloadedFilesStatus.includes(file.ID)}
                    hasError={downloadedFilesError.includes(file.ID)}
                  />
                ))}
                <Text as={GridItem} colSpan={4} mt="1rem">
                  {props.files.length > 0
                    ? `${props.category} is available for download`
                    : `There were no documents for ${props.category} available`}
                </Text>
              </>
            ) : (
              <Text as={GridItem} colSpan={4} mt="1rem">
                {props.category} is currently pending upload
              </Text>
            )}
          </Grid>
        </>
      </TabPanel>
    );
  };

  return (
    <>
      <Grid gridTemplateColumns="repeat(3,1fr)">
        <GridItem colSpan={{ lg: 1, base: 3 }}>
          <Heading as="h3" fontSize="regular" fontWeight="normal">
            Your records
          </Heading>
        </GridItem>
      </Grid>
      <Box>
        <Button
          variant="primary"
          isDisabled={validCategories.some((item) => item.isReady === false)}
          onClick={() => downloadAllDocs(files)}
          my="1rem"
        >
          Download all
        </Button>
        <Tabs variant="baseNoticeExplorer">
          <TabList>
            {validCategories.map((item) => (
              <Tab>{item.title}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {validCategories.map((item) => (
              <FileCategoryPanel
                category={item.title}
                files={files.filter((file) => {
                  return file.category === item.key;
                })}
                isReady={item.isReady}
                downloadAllFiles={(filesToDownload) => downloadAllDocs(filesToDownload)}
                downloadIndividualFile={(file) => onClickCTA(file)}
                status={status}
              />
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default ReadyToDownloadDocuments;
