import React from 'react';
import { Box, Show, Image, Flex } from '@chakra-ui/react';
import MenuLinks from '../MenuLinks/MenuLinks';
import MainNavigationMobile from './MainNavigationMobile';
import { MainContainer } from '../Layout/MainContainer/MainContainer';
import { Link } from 'react-router-dom';
import { NavItemProps } from '../NavItem/NavItem';

export interface MainNavigationProps {
  router: boolean;
  homeURL?: string;
  logoURL?: string;
  searchURL?: string;
  mobileLogoURL?: string;
  altText?: string;
  navigationItems?: NavItemProps[];
  placeholder?: string;
  notificationCount?: number;
  logOutUrl: string;
}

export const MainNavigationDesktop: React.FC<MainNavigationProps> = (props: MainNavigationProps): JSX.Element => {
  return (
    <Box bg="charcoalgrey">
      <MainContainer flex>
        <Box as={Link} to={props.homeURL ?? '/'} height="80px" style={{ display: 'flex', alignItems: 'center' }}>
          <Image data-testid="logo" height="35px" src={props.logoURL} alt={props.altText} />
        </Box>
        <Flex justifyContent="flex-end">
          <MenuLinks
            variant="mainNav"
            router={props.router}
            navigationItems={props.navigationItems ?? []}
            notificationCount={props.notificationCount}
          />
        </Flex>
      </MainContainer>
    </Box>
  );
};
const MainNavigation: React.FC<MainNavigationProps> = (props: MainNavigationProps): JSX.Element => {
  return (
    <>
      <Show above="lg">
        <MainNavigationDesktop {...props} />
      </Show>
      <Show below="lg">
        <MainNavigationMobile {...props} />
      </Show>
    </>
  );
};

export default MainNavigation;
