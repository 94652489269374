import React, { Suspense } from 'react';
// import { render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// Importing Sass with Bootstrap CSS
import './sass/App.scss';
import './sass/custom.scss';
import { Provider } from 'react-redux';
import { store } from './store';
import { ChakraProvider } from '@chakra-ui/react';
import { defencetheme } from './theme/theme';
import { UtilityModalProvider } from './components/ServiceUI/Core/Modal/UtilityModals';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <ChakraProvider theme={defencetheme}>
        <Provider store={store}>
          <UtilityModalProvider>
            <App />
          </UtilityModalProvider>
        </Provider>
      </ChakraProvider>
    </Suspense>
  </BrowserRouter>,
);
