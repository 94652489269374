import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { appConstants } from '../../helper/client/constant';
import { getApiUrl } from '../../helper/getOtherAppURL';

import { resetEFTFormState, setFormFields } from '../../reducers/eftRequestDetailFormReducer';
import { setShowError } from '../../reducers/errorHandling';
import { PIRCaseAudit } from './API/PIRCaseAudit';
import { ActionButtons } from './ActionButtons';
import content from './Content/CreateRequest.json';
import { root } from './EFTConstant';
import { formDataTransform } from './Helper/formDataTransform';
import { RequestDetailEft } from './RequestDetailEft';
import { FormProvider, useForm } from 'react-hook-form';
import UtilityModals from '../../components/ServiceUI/Core/Modal/UtilityModals';

//Error Handling Modals - to remove and add new method
import DeleteModal from '../../components/MyServiceRequest/CreateRequest/DeleteModal';
import UploadConfirmationSRModal from '../../components/MyServiceRequest/CreateRequest/UploadConfirmationSRModal';
import { deletePIR, savePIR } from './API/eftApiService';
import { getPageInstructionObj, prepareFormFields } from './Helper/eftHelperFunc';
import { Box, Heading, Text } from '@chakra-ui/react';
import { request } from '../../helper/useAxios';

export const NewRequestPage: React.FC = () => {
  //Define variables
  const apiUrl = getApiUrl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reqDetailReduxStore = useSelector((state: any) => state.EFTRequestMemberForm);

  const { confirm } = UtilityModals();

  //States
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [nextAssignmentIdState, setNextAssignmentId] = useState({
    nextAssignmentId: '',
  });
  const [allCaseData, setallCaseData] = useState({
    caseData: {},
  });
  const [actionIdState, setActionId] = useState({
    actionId: '',
  });
  const [caseId, setCaseId] = useState({
    caseId: id || '',
  });

  //DELETE PEGA API
  const deletePIRHandler = async () => {
    setLoading(true);
    await deletePIR(caseId.caseId, actionIdState.actionId)
      .then((_) => {
        setLoading(false);
        dispatch(resetEFTFormState());
        // After api success go to PIR home page
        navigate('/personal-information-request');
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //SAVE PEGA API
  const savePIRHandlerFunc = async () => {
    try {
      const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);
      const finalResult = await getPageInstructionObj(allUpdatedValue);
      // Clear Applicant Details manually as they may be previously saved
      const clearApplicantDetails = [
        {
          instruction: 'DELETE',
          target: '.PersonalInfoRequest.SelfApplicantDetails',
        },
        {
          instruction: 'DELETE',
          target: '.PersonalInfoRequest.FamilyMemberApplicantDetails',
        },
        {
          instruction: 'DELETE',
          target: '.PersonalInfoRequest.AdvocateApplicantDetails',
        },
      ];

      // Combine the clearing of Applicant Details and the actual updated values
      // @ts-ignore

      const combinedPageInstructions = [...clearApplicantDetails, ...finalResult];

      // Define data for put request
      const data = {
        content: {},
        pageInstructions: combinedPageInstructions,
      };

      await savePIR(caseId.caseId, data);

      setLoading(false);
      dispatch(resetEFTFormState());

      try {
        await PIRCaseAudit(caseId.caseId, '', 'PIR01');
      } catch (error) {
        // @ts-ignore
        dispatch(setShowError({ hasError: true, error }));
      }

      navigate('/personal-information-request');
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  const methods = useForm({ mode: 'onTouched', shouldUnregister: true });

  const {
    formState: { isValid },
  } = methods;

  const onSubmit = () => {
    handleUploadPopUp();
  };

  const handleDelete = async () => {
    if (
      await confirm(
        <>
          <Heading fontWeight="medium" fontSize="md">
            Are you sure you want to delete this request?'
          </Heading>
          <Text>
            Please confirm whether you would like to delete this drafted request. This action cannot be undone.
          </Text>
        </>,
        { okText: 'Delete request', cancelText: 'Back', modalProps: { size: 'small', closeOnOverlayClick: true } },
      )
    ) {
      deletePIRHandler();
    }
  };

  const handleUploadPopUp = () => {
    if (reqDetailReduxStore.uploadedFile) {
      setShowUploadModal(true);
    } else {
      reviewRequestandlerFunc();
    }
  };

  const reviewRequestandlerFunc = () => {
    const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);
    const finalInput = getPageInstructionObj(allUpdatedValue);

    finalInput.then((pageInstructionData) => {
      const data = {
        content: {},
        pageInstructions: pageInstructionData,
      };

      let URL =
        appConstants.API_ASSIGNMENTS + nextAssignmentIdState.nextAssignmentId + '?actionID=' + actionIdState.actionId;
      axios
        .post(apiUrl + URL, data, {
          withCredentials: true,
        })
        .then((_) => {
          setLoading(false);
          navigate('/personal-information-request/review/' + nextAssignmentIdState.nextAssignmentId);
        })
        .catch((error) => {
          setLoading(false);
          dispatch(setShowError({ hasError: true, error }));
        });
      window.scrollTo(0, 0); //scroll to top of review page once everything is done
    });
  };

  const createNewPIRReq = () => {
    const data = {
      caseTypeID: 'DoD-SConn-Work-PersonalInfoRequest',
      content: {},
    };
    setLoading(true);
    // INFO: Create case id call
    request(appConstants.API_CASES, 'post', { data })
      .then((res) => {
        // NOTE: call to get the next action which will help us to know what is the next action
        const URL_NEXT_ACTION_API = appConstants.API_ASSIGNMENTS + res.data?.nextAssignmentID;
        const FINAL_URL_NEXT_ACTION_API = apiUrl + URL_NEXT_ACTION_API;
        axios
          .get(FINAL_URL_NEXT_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            setCaseId((prevState) => {
              return {
                ...prevState,
                caseId: res.data.caseID,
              };
            });
            // updating the app states this we will use in update call
            setNextAssignmentId((prevState) => {
              return {
                ...prevState,
                nextAssignmentId: res.data.ID,
              };
            });
            setActionId((prevState) => {
              return {
                ...prevState,
                actionId: res.data.actions[0].ID,
              };
            });
            // This call is to prepopulate the req data
            const URL_CREATE_ACTION_API =
              appConstants.API_ASSIGNMENTS + res.data.ID + '/actions/' + res.data.actions[0].ID;
            axios
              .get(apiUrl + URL_CREATE_ACTION_API, {
                withCredentials: true,
              })
              .then((res) => {
                setallCaseData((prevState) => {
                  return {
                    ...prevState,
                    caseData: res.data,
                  };
                });
                let fieldList: any[] = [];
                fieldList = formDataTransform(res);
                dispatch(
                  setFormFields({
                    fieldList,
                  }),
                );
                setLoading(false);
              })
              .catch((error) => {
                dispatch(setShowError({ hasError: true, error }));
              });
          })
          .catch((error) => {
            dispatch(setShowError({ hasError: true, error }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const ViewReqInNewState = () => {
    setLoading(true);
    const URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL = appConstants.API_CASES + caseId.caseId;
    axios
      .get(apiUrl + URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL, {
        withCredentials: true,
      })
      .then((res) => {
        // If in progress set edit state
        if (res.data?.content?.EditSelected === 'Yes') {
          setFormEditMode(true);
        }
        // updating the app states this we will use in update call
        setNextAssignmentId((prevState) => {
          return {
            ...prevState,
            nextAssignmentId: res.data?.assignments[0]?.ID,
          };
        });
        setActionId((prevState) => {
          return {
            ...prevState,
            actionId: res.data?.assignments[0]?.actions[0]?.ID,
          };
        });
        // This call is to prepopulate the req data

        const URL_CREATE_ACTION_API =
          appConstants.API_ASSIGNMENTS +
          res.data?.assignments[0]?.ID +
          '/actions/' +
          res.data?.assignments[0]?.actions[0]?.ID;
        axios
          .get(apiUrl + URL_CREATE_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            setallCaseData((prevState) => {
              return {
                ...prevState,
                caseData: res.data,
              };
            });
            let fieldList: any[] = [];
            fieldList = formDataTransform(res);
            dispatch(
              setFormFields({
                fieldList,
              }),
            );
            setLoading(false);
          })
          .catch((error) => {
            dispatch(setShowError({ error: true, code: error.response?.status }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response?.status }));
      });
  };

  useEffect(() => {
    document.title = 'New personal information request';
    if (!!id) {
      ViewReqInNewState();
    } else {
      createNewPIRReq();
    }
  }, []);

  return (
    <>
      <Header
        {...content.header}
        breadcrumbItems={[
          {
            name: 'Personal Information Request',
            url: root,
          },
        ]}
      />
      <SubContainer>
        <Box minH="650px" py="1rem" data-testid="new-request">
          <DeleteModal
            show={showDeleteModal}
            closeAction={() => setShowDeleteModal(false)}
            deleteAction={() => deletePIRHandler()}
          />
          {showUploadModal && (
            <UploadConfirmationSRModal
              handleUpdateForms={reviewRequestandlerFunc}
              show={showUploadModal}
              closeAction={() => setShowUploadModal(false)}
            />
          )}
          {loading && <Spinner />}
          {!!nextAssignmentIdState.nextAssignmentId && !!actionIdState.actionId && !!caseId.caseId && !loading && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <RequestDetailEft
                  caseData={allCaseData.caseData}
                  nextAssignmentID={nextAssignmentIdState.nextAssignmentId}
                  actionId={actionIdState.actionId}
                  caseIdOrig={caseId.caseId}
                  formEditMode={formEditMode}
                  setApiCallInProgress={setApiCallInProgress}
                />
                <ActionButtons
                  savePIRHandler={() => savePIRHandlerFunc()}
                  setShowDeleteModal={handleDelete}
                  isFormValid={isValid}
                  isSubmitDisabled={apiCallInProgress}
                />
              </form>
            </FormProvider>
          )}
        </Box>
      </SubContainer>
    </>
  );
};
