import { Button, ButtonGroup, Badge as CBadge, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Cross } from '../icons/Outline';
interface BadgeProps {
  status: string;
  bgOverride?: string;
}
interface FilterBadgeProps {
  text: string | null;
  onClose?(): void;
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps): JSX.Element => {
  let bg = '';
  let colour = '';
  let border = '';

  const status = props.status?.toLocaleUpperCase();
  switch (status) {
    case 'URGENT':
      bg = 'redalert';
      colour = 'white';
      break;
    case 'ERROR':
      bg = 'redalert';
      colour = 'white';
      break;
    case 'DOWNLOADED':
      bg = 'badge_green';
      colour = 'white';
      break;
    default:
      border = '1px solid black';
      bg = props.bgOverride ?? 'white';
      colour = 'black';
  }
  return (
    <CBadge
      fontWeight="medium"
      fontSize="xxs"
      bg={bg}
      color={colour}
      border={border}
      borderRadius="1rem"
      padding="0.25rem 0.5rem"
      overflow="hidden"
    >
      {status}
    </CBadge>
  );
};

export const FilterBadge: React.FC<FilterBadgeProps> = (props: FilterBadgeProps) => {
  return (
    <CBadge
      as={ButtonGroup}
      fontWeight="medium"
      fontSize="xxs"
      bg="black"
      color="white"
      borderRadius="0"
      display="flex"
      alignItems="center"
      gap={2}
      p={0}
      h="fit-content"
      textTransform="none"
    >
      <Button
        onClick={() => props.onClose && props.onClose()}
        borderRadius="0"
        p={0}
        minW="0"
        h="1.5rem"
        w="1.5rem"
        bg="black"
        color="white"
        _hover={{ bg: 'mediumgrey' }}
      >
        <Cross height="0.75rem" />
      </Button>
      <FormLabel m={0} marginInlineEnd="0.25rem !important" marginInlineStart="0 !important" fontSize="xxs">
        {props.text}
      </FormLabel>
    </CBadge>
  );
};

export const PegaCaseBadge: React.FC<BadgeProps> = (props: BadgeProps): JSX.Element => {
  let bg = '';
  let colour = '';
  let border = '';
  let text = '';

  const status = props.status?.toLocaleUpperCase();
  switch (status) {
    case 'NEW':
      bg = 'black';
      colour = 'white';
      text = 'drafted';
      break;
    case 'PENDING-REVIEW':
      bg = 'black';
      colour = 'white';
      text = 'drafted';
      break;
    case 'PENDING-FULFILLMENT':
      bg = 'orange.standard';
      colour = 'white';
      text = 'submitted';
      break;
    case 'PENDING-COMPLETION-PARTIAL':
      border = '1px solid';
      colour = 'orange.standard';
      text = 'Ready Partial';
      break;
    case 'PENDING-CLOSURE':
      bg = 'badge_green';
      colour = 'white';
      text = 'All Ready';
      break;
    case 'PENDING-DOWNLOAD':
      bg = 'badge_green';
      colour = 'white';
      text = 'All Ready';
      break;
    case 'PENDING-COMPLETION':
      bg = 'badge_green';
      colour = 'white';
      text = 'All Ready';
      break;
    case 'PENDING-EXPIRATION':
      bg = 'redalert';
      colour = 'white';
      text = 'Expired';
      break;
    //GEMS SR status
    case 'COMPLETED':
      bg = 'badge_green';
      colour = 'white';
      text = 'Complete';
      break;
    case 'RECEIVED':
      bg = 'white';
      colour = 'orange.standard';
      text = 'Received';
      border = '1px solid #CF4520';
      break;
    case 'REJECTED':
      bg = 'redalert';
      colour = 'white';
      text = 'Rejected';
      break;
    case 'CANCELLED':
      bg = 'redalert';
      colour = 'white';
      text = 'Cancelled';
      break;
    case 'ERROR':
      bg = 'redalert';
      colour = 'white';
      text = 'Error';
      break;
    case 'IN PROGRESS':
      bg = 'badge_blue';
      colour = 'white';
      text = 'In Progress';
      break;
    default:
      border = '1px solid black';
      bg = 'white';
      colour = 'black';
  }
  return (
    <CBadge
      fontWeight="medium"
      fontSize="xxs"
      border={border}
      color={colour}
      bg={bg}
      borderRadius="1rem"
      padding="0.15rem 1rem"
    >
      {text}
    </CBadge>
  );
};
