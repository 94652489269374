import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';

export const WithOverlay = (props) => {
  return (
    <>
      <Box
        bg="black"
        zIndex={10}
        opacity={props.opaqueOverlay ? 1 : 0.5}
        w="100%"
        h="100%"
        textAlign="center"
        py="1rem"
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      />
      <Box
        zIndex={10}
        w="100%"
        h="100%"
        textAlign="center"
        py="1rem"
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <Spinner size="lg" color="white" />
        {props.text && <Text color="white">{props.text}</Text>}
      </Box>
    </>
  );
};
