import React from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';

const FormRadio = ({
  fieldID,
  label,
  control,
  fieldPropMapping,
  formData,
  reference,
  visible,
  variant,
  required,
  newFunction,
  dynamicFieldAction,
  value,
  readOnly,
  disabled,
}) => {
  const refreshFor = encodeURIComponent(control?.actionSets[0]?.actions[1]?.refreshFor);
  const dispatch = useDispatch();
  const onSelectRadio = (value) => {
    dispatch(
      setFieldValue({
        reference,
        value: value,
        formData,
      }),
    );
    dynamicFieldAction(value, fieldID, reference, required, visible, refreshFor);
  };

  return (
    <SCForm.Radio
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      label={label}
      visible={visible}
      options={control?.modes[0]?.options}
      onSelectRadio={(e) => onSelectRadio(e)}
      // @ts-ignore
      selectFunction={newFunction}
      variant={variant || 'primary'}
      id={fieldID}
      required={required}
      validation={fieldPropMapping[fieldID]?.validation}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      onBlur={(e) => {
        onSelectRadio(e);
      }}
      minHeight="6rem"
    />
  );
};

export default FormRadio;
