export function initializeMatomo() {
  if (process.env.REACT_APP_TAG_MANAGER_URL) {
    var _paq = ((window as any)._paq = (window as any)._paq || []);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['requireConsent']);
    _paq.push(['enableHeartBeatTimer', 30]); // Installs a "Heartbeat Timer" in Matomo which sends requests when the user actively views the page to measure visit duration. It doesn't track extra actions or pageviews. If the page is viewed for at least 15 seconds, a ping is sent when the user leaves or switches tabs.
    (function () {
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = process.env.REACT_APP_TAG_MANAGER_URL;
      s.parentNode?.insertBefore(g, s);
    })();
  }
}
export function handleConsent(consentGiven) {
  var _paq = ((window as any)._paq = (window as any)._paq || []);
  if (consentGiven === true || consentGiven === 'true') {
    // If user has opted in of tracking
    _paq.push(['setConsentGiven']);
  } else {
    // If user has opted out of tracking
    _paq.push(['optUserOut']);
    //_paq.push(['setDoNotTrack', true]); //new addition trial
  }
}
