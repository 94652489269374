import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { appConstants } from '../../../helper/client/constant';
import Header from '../../ServiceUI/Core/Header/Header';
import RequestDetailReadOnly from '../ViewServiceRequest/RequestDetailReadOnly/RequestDetailReadOnly';
import content from '../../../pages/ServiceRequest/Content/ReviewRequest.json';
import ErrorPopUp from './ErrorPopup/ErrorPopup';
import Location from './LocationDetails/Location';
import Other from './Other/Other';
import PersonalDetailsPrimary from './Personal-Detail/PersonalDetailPrimary';
import './ReviewRequest.scss';
import ReviewSubheader from './ReviewSubheader/ReviewSubheader';
import ReviewSubheaderProps from './ReviewSubheader/__mocks__/default.json';
import PersonalDetailsSecondary from './SecondaryContactCard/PersonalDetailsSecondary';
import { request } from '../../../helper/useAxios';
import ServiceConnectSpinner from '../../../components/common/Spinner/ServiceConnectSpinner';

//Modals
import AuthenticationModal from '../../Modals/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../Modals/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../Modals/GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../Modals/SystemOutageModal/__mocks__/default.json';
import GenericErrorModal from '../../../components/Modals/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../../components/Modals/SystemOutageModal/SystemOutageModal';
import { SubContainer } from '../../ServiceUI/Core/Layout/SubContainer/SubContainer';

const ReviewRequest = () => {
  useEffect(() => {
    document.title = isMinorRequestParam ? 'Review minor maintenance request' : 'Review new service request';
  }, []);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [error, setError] = useState();
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [showError, setShowError] = useState(false);
  const { id } = useParams(); // this id we might need for future use

  const navigate = useNavigate();
  let location = useLocation();

  const [secondaryContactformInvalid, setsecondaryContactformInvalid] = useState(false);

  const locationDataState = location.state[0].locData;
  const personalDataState = location.state[0].personalData;
  const otherDataState = location.state[0].otherData;
  const locationStateDropdownState = location.state[0].stateDropdown;
  const locationTypeDropdownState = location.state[0].locationTypeDropdown;
  const locationBaseNameDropdownState = location.state[0].baseNameDropDown;
  const entityDropdown = location.state[0].entityDropdown;
  const vesselDropdown = location.state[0].vesselDropdown;
  const showSecondaryState = location.state[0].showSecondary;
  const isMinorRequestParam = location.state[0].isMinorRequest;
  const caseID = location.state[0].caseId.caseId;
  const [srViewInfo, setsrViewInfo] = useState({
    data: [],
  });
  const [loading, setLoading] = useState({
    isLoading: false,
  });

  const handleLocationDataUpdate = (id, name, value) => {};
  const handleVesselDropDownUpdate = (id, name, value) => {};
  const handleEntityDropdownUpdate = (id, name, value) => {};
  const handleVesselCheckBox = () => {};
  const handleEntityDropdownUpdateSecondary = () => {};
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  const handleOtherDetailsUpdate = (id, name, value) => {};
  const addSecondaryContactHandler = () => {};

  const handlePersonalDetailsUpdate = (id, name, value) => {};
  const handlePersonalSecondaryDetailsUpdate = (form, error, data) => {};
  const [workType, setworkType] = useState({
    dropdown: [],
    description: '',
  });
  const [workSubType, setSubworkType] = useState({
    dropdown: [],
    description: '',
  });

  const getRefDropDownWorkSubType = (currentworkType, selectedCode) => {
    request(appConstants.API_WORK_TYPE + `?worktype=${currentworkType}`, 'get')
      .then((response) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(response.data.refValues, selectedCode);
        setSubworkType((prevState) => {
          return {
            ...prevState,
            dropdown: response.data.refValues,
            description: selectedDescription,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        if (error.response.status === 400) {
          setShowError(true);
          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const getSelectedDescription = (refDataDropdown, selectedCode) => {
    const selectedRefDataDescription = refDataDropdown.filter((item) => item.code === selectedCode)[0].description;
    return selectedRefDataDescription;
  };
  const getRefDropDown = (value, selectedCode) => {
    request(appConstants.API_REF_DATA + value, 'get')
      .then((res) => {
        let selectedDescription = 'Select';

        if (!!res.data) {
          setworkType((prevState) => {
            selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
            return {
              ...prevState,
              dropdown: res.data.refValues,
              description: selectedDescription,
            };
          });
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400) {
          setShowError(true);

          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  //Call to update status after user submits the request
  const handleUpdateForms = () => {
    setShowSpinnerApiIsInProgress(true);
    const data = {
      content: {},
      pageInstructions: [],
    };
    request(appConstants.API_ASSIGNMENTS + id + '?actionID=ReviewRequest', 'post', { data })
      .then(() => {
        setShowSpinnerApiIsInProgress(false);
        request(appConstants.API_CASES + caseID, 'get')
          .then((res) => {
            if (
              process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV ||
              process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV
            ) {
              if (!!res.data.content?.pyErrorCode) {
                setErrorModal(true);
              } else {
                navigate('/my-service-request/req-completed', {
                  state: [res.data],
                });
              }
            } else {
              navigate('/my-service-request/req-completed', {
                state: [res.data],
              });
            }
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
              setShowModalHttpCode(error.response.status);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        if (error.response) {
          if (error.response.status === 400) {
            setShowError(true);
            setError(error.response.data);
          }
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        }
      });
  };

  useEffect(() => {
    if (!!caseID) {
      setLoading((prevState) => {
        return {
          ...prevState,
          isLoading: true,
        };
      });
      request(appConstants.API_CASES + caseID, 'get')
        .then((res) => {
          if (res.data.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE) {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('WorkType', workTypeCode, '');
            const workSubTypeCode = res.data.content.ServiceRequest.WorkSubType;
            getRefDropDownWorkSubType(workTypeCode, workSubTypeCode);
          } else {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('servicetype', workTypeCode, '');
          }
          setsrViewInfo((prevState) => {
            return {
              ...prevState,
              data: [res.data.content.ServiceRequest, ...prevState.data],
            };
          });
          setLoading((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 400) {
            setShowError(true);
            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    }
  }, []);

  return (
    <div>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}
      <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />
      <Header
        {...content.header}
        mainHeading={isMinorRequestParam ? 'Minor maintenance request' : 'New service request'}
        subHeading={isMinorRequestParam ? content.header.subHeading : 'Submit a request for base services'}
      />

      <ReviewSubheader
        requestType={
          isMinorRequestParam ? appConstants.SERVICE_REQUEST_TYPE_MINOR : appConstants.SERVICE_REQUEST_TYPE_FULL
        }
        caseIdForFirstEditAPI={caseID}
        caseIdForSecondEditAPI={id}
        heading={ReviewSubheaderProps.mainHeading}
        subheading={ReviewSubheaderProps.subHeading}
      />
      {loading.isLoading && (
        <Container>
          <h2 style={{ textAlign: 'center', margin: '20px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </h2>
        </Container>
      )}

      {!loading.isLoading && srViewInfo.data.length > 0 && (
        <SubContainer>
          <RequestDetailReadOnly
            workTypeDesc={workType.description}
            workSubTypeDesc={workSubType.description}
            reqData={srViewInfo.data}
            caseID={caseID}
          ></RequestDetailReadOnly>
          <Container className="pt-4 pt-lg-6 pb-3">
            <h2>Location Information:</h2>
            <div>
              {locationDataState.location_data_state.length > 0 &&
                locationDataState.location_data_state.map((item, index) => (
                  <Location
                    key={index}
                    check={locationDataState}
                    locationDetail={item}
                    stateValues={locationStateDropdownState}
                    VesselValues={vesselDropdown}
                    locationTypeValues={locationTypeDropdownState}
                    baseNameValues={locationBaseNameDropdownState}
                    onLocationInfoChange={handleLocationDataUpdate}
                    onVesselDropDownChange={handleVesselDropDownUpdate}
                    isReadonlyLocation={true}
                    isReview={true}
                  />
                ))}
            </div>
          </Container>
          <Container className="pt-4 pt-lg-6 pb-1">
            <h2>Personal Information:</h2>
            {otherDataState.other_data_state.length > 0 &&
              otherDataState.other_data_state.map((item, index) => (
                <Other
                  key={index}
                  otherDetail={item}
                  onOtherInfoChange={handleOtherDetailsUpdate}
                  isReadonlyOther={true}
                  isReview={true}
                />
              ))}
            <div className="fieldsContainer">
              {otherDataState.isPrimarySelected === 'false' &&
                personalDataState.personal_data_state.map((item, index) => (
                  <PersonalDetailsPrimary
                    key={index}
                    entityValues={entityDropdown}
                    onEntityChange={handleEntityDropdownUpdate}
                    personalDetail={item}
                    onPersonalInfoChange={handlePersonalDetailsUpdate}
                    isReadonlyPersonalDetails={true}
                    isReview={true}
                  />
                ))}
            </div>

            <div className="fieldsContainer">
              {personalDataState.personal_data_state.length > 0 &&
                otherDataState.isPrimarySelected === 'true' &&
                personalDataState.personal_data_state.map((item, index) => (
                  <PersonalDetailsPrimary
                    key={index}
                    nopersonal="false"
                    entityValues={entityDropdown}
                    onEntityChange={handleEntityDropdownUpdate}
                    personalDetail={item}
                    onPersonalInfoChange={handlePersonalDetailsUpdate}
                    isReadonlyPersonalDetails={true}
                    isReview={true}
                  />
                ))}
            </div>
          </Container>

          {showSecondaryState.isVisible && otherDataState.isPrimarySelected === 'true' && (
            <Container className="pt-4 pt-lg-6 pb-3 pb-lg-1">
              <>
                <h2>Alternate point of contact</h2>
                <PersonalDetailsSecondary
                  entityValuesSecondary={entityDropdown}
                  allDetailsOnSecondary={personalDataState.personal_data_secondary_state}
                  onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                  onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                  onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                  isReadonlyPersonalSecondaryDetails={true}
                  isReview={true}
                />
              </>
            </Container>
          )}

          {otherDataState.isPrimarySelected === 'false' && (
            <Container className="pt-4 pt-lg-6 pb-3 pb-lg-1">
              <>
                <h2>Alternate point of contact</h2>

                <PersonalDetailsSecondary
                  entityValuesSecondary={entityDropdown}
                  onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                  allDetailsOnSecondary={personalDataState.personal_data_secondary_state}
                  onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                  onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                  isReadonlyPersonalSecondaryDetails={true}
                  isReview={true}
                />
              </>
            </Container>
          )}
          {showSpinnerApiIsInProgress && (
            <Container>
              <ServiceConnectSpinner></ServiceConnectSpinner>
            </Container>
          )}
          <Row className="buttonContainer pt-4 pt-lg-6">
            <Col md className="d-flex justify-content-start pt-xs-2 pt-2 pb-6 col-md-12 col-lg-8 order-2 order-lg-1">
              <Button variant="standard" className="mobileButton">
                <a className="noURlStyle" href="/my-service-request">
                  Back
                </a>
              </Button>
            </Col>
            <Col md className="d-xs-inline d-lg-flex pt-2 justify-content-end order-1 order-lg-2">
              <Row>
                <Col
                  xs={12}
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-8 pt-lg-2 d-flex justify-content-end order-1 order-lg-2"
                >
                  <Button variant="primary" className="mobileButton" onClick={handleUpdateForms}>
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </SubContainer>
      )}
    </div>
  );
};

export default ReviewRequest;
