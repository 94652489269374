import { ComponentStyleConfig } from '@chakra-ui/react';
import { spacing } from '../spacing';
import { disabledField } from '../constants';
import { colors } from '../colors';

const BUTTON_HEIGHT = '44px';

const IconButtonBase = {
  h: '80%',
  w: 'auto',
  display: 'flex',
  p: '12px',
  flexDir: 'column',
  color: 'white',
  aspectRatio: '1/1',
  _active: { outline: '1px white solid' },
  _hover: {
    outline: '1px white solid',
  },
};

const Primary = {
  color: 'white !important',
  backgroundColor: 'orange.standard',
  _hover: {
    backgroundColor: 'orange.dark',
    _disabled: {
      backgroundColor: 'charcoal !important',
    },
  },
  _active: { backgroundColor: 'orange.darker' },
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'normal',
    borderRadius: '2',
    padding: '16px 24px !important',
    boxShadow: 'none !important',
    textDecoration: 'none !important',
    _disabled: { opacity: 0.35 },
    _focus: { outline: `2px solid ${colors.progress}` },
  },
  sizes: {
    md: { fontSize: 'xs', h: BUTTON_HEIGHT, minWidth: '120px !important' },
    menu: { fontSize: 'xs', h: BUTTON_HEIGHT },
    sr: { fontSize: 'xs', h: '20px', minWidth: '10px !important' },
  },
  variants: {
    primary: {
      ...Primary,
    },
    secondary: {
      border: '1px solid black',
      color: 'black !important',
      _hover: { backgroundColor: 'grey' },
      _active: { backgroundColor: 'mediumgrey' },
    },
    secondary_fill: {
      background: 'white',
      border: '1px solid black',
      color: 'black',
      _hover: { backgroundColor: 'grey' },
      _active: { backgroundColor: 'mediumgrey' },
    },
    secondary_dark: {
      backgroundColor: 'charcoalgrey',
      color: 'white',
      border: '1px solid charcoalgrey',
      _hover: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
      },
      _active: { backgroundColor: 'grey' },
    },
    secondary_white: {
      backgroundColor: 'white',
      border: '1px solid black',
      color: 'black !important',
      _hover: { backgroundColor: 'grey' },
      _active: { backgroundColor: 'mediumgrey' },
    },
    tertiary: {
      backgroundColor: 'charcoal',
      color: 'white',
      outlineOffset: 0,
      _hover: {
        backgroundColor: 'white',
        color: 'black',
        outline: 'black 2px solid',
      },
      _focus: {
        backgroundColor: 'charcoal',
        outline: 'white 2px solid',
        _hover: { bg: 'white', outline: 'black 2px solid' },
      },
      _disabled: {
        _hover: {
          backgroundColor: 'charcoal !important',
          color: 'white',
          outline: 'none',
        },
      },
      _active: { backgroundColor: 'darkgrey', outline: 'black 1px solid' },
    },
    menuButton: {
      minWidth: 'none !important',
      fontSize: 'regular',
      fontWeight: '200',
      height: '44px',
      borderRadius: '2px',
      backgroundColor: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'mediumgrey',
      padding: `0.5rem ${spacing.default}`,
      _disabled: disabledField,
      _readOnly: disabledField,
      _invalid: { borderColor: 'redalert' },
    },
    sort: {
      bg: 'white',
      borderRadius: 0,
      border: 'none',
      color: 'black',
      outlineOffset: 0,
      _hover: { bg: 'white', outline: '1px solid black', zIndex: 1000 },
      _active: { bg: 'mediumgrey', outline: '1px solid black' },
    },
    mobileSearch: {
      ...Primary,
      borderRadius: 0,
      width: BUTTON_HEIGHT,
      border: 'none !important',
      display: 'flex',
      padding: '0.75rem !important',
    },
    mobileMenu: {
      ...IconButtonBase,
    },
  },
};

export default Button;
