// created this file to have all EFT constant at one place
export const EFTConstants = {
  PIR_ROYAL_SERVICE_TYPE: 'RoyalCommission',
  PIR_TYPE_OF_RECORD_PERSONNEL_REFERENCE: 'PersonalInfoRequest.IsPersonnelInformation',
  YES_SELECTED: 'Yes',
  NO_SELECTED: 'No',
  PIR_MEDICAL_RECORDS_FIELDID: 'IsMedicalRecords',
  PIR_IS_EOLOGY_FIELDID: 'IsEulogy',
  PIR_SERVICE_TYPE_FIELDID: 'ServiceType',
  PIR_ALLOWED_FILE_EXT_END_USER: ['application/pdf', 'image/jpg', 'image/jpeg'],
  PIR_ALLOWED_FILE_EXT_BUSINESS_USER: [
    'application/pdf',
    'image/jpg',
    'image/jpeg',
    'image/png',
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
  ],
  SINGLE_FILE_SIZE_LIMIT: 3145728, // 3mb= 1048576 * 3 bytes
  TOTAL_FILE_SIZE_LIMIT: 12582912, // 12mb = 1048576 * 12 bytes
  PIR_IDENTITY_TWO_FILES_MSG: 'Two proof of identity documents are required.',
  PIR_FILE_ERROR_TYPE_COUNT: 'Count',
  MEM_IS_DECEASED: 'IsDeceased',
  PIR_CATOGORY_FAMILY_MEMBER_IDENDITY: 'FamilyMemberIdentification',
  PIR_CATEGORY_COMMON_IDENTITY: 'ProofOfIdentification',
  PIR_CATEGORY_COMMON_SIGNED_CONSENT: 'SignedConsent',
  PIR_CATEGORY_COMMON_PROOF_OF_DEATH: 'ProofOfDeath',
  PIR_CATEGORY_COMMON_SIGN_THIS_FORM: 'Declaration',
  PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_MEMBER: 'ProofOfFamilyMember',
  PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_EX_MEMBER: 'ProofOfFamilyExMember',
  PIR_CATEGORY_COMMON_FAMILY_MEMBER_IDENTIFICATION: 'FamilyMemberIdentification',
  PIR_CATEGORY_COMMON_FAMILY_MEMEBER_SIGNED_CONSENT: 'FamilyMemberSignedConsent',
  PIR_CATEGORY_MEDICAL_SUPPORTING_DOCUMENTS: 'MedicalSupportingDocuments',
  PIR_CATEGORY_OFFICAIL_DOCUMENTS: 'OfficialDocuments',
  PIR_FILE_UPLOAD_ID: 'file-upload',
  PIR_FILE_SIZE_EXCEED_MSG: 'File size can not be more than 25MB.',
  PIR_FILE_ERROR_TYPE_SIZE: 'Size',
  // PIR_FILE_SIZE_MAX_LIMIT: 25,
  IAU_SINGLE_FILE_SIZE_LIMIT: 367001600, // 350mb = 150 x 1048576 bytes
  IAU_TOTAL_FILE_SIZE_LIMIT: 786432000, // 750mb
  SINGLE_FILE_SIZE_LIMIT_ERROR: 'Each file may not be bigger than 3MB',
  TOTAL_FILE_SIZE_LIMIT_ERROR: 'The total attachment size has exceeded 12MB',
  IAU_SINGLE_FILE_SIZE_LIMIT_ERROR:
    'One or more file(s) exceeded the maximum size limit of 350MB and have not been added to the request.',
  IAU_TOTAL_FILE_SIZE_LIMIT_ERROR: 'Total uploaded files exceeds the total limit of 750mb.',
  PIR_END_USER_TOTAL_FILE_AMOUNT_ERROR: 'The total file amount has exceeded the limit.',

  PIR_END_USER_FILE_TYPE_ERROR: 'This file is not an accepted file type',

  PIR_NO_VALID_FILE_FOUND: 'Valid files not found',
  PIR_NO_FILE_FOUND: 'files not found',
  AUDITCODE_END_USER: 'PIR02',
  AUDITCODE_BUSINESS_USER: 'PIR05',
};

export const root = '/personal-information-request';
export const newRequest = `${root}/new`;

//request details constants
export const REQUEST_DETAILS_PEGA_VIEWID = 'RequestInformation';

export const APPLICANT_TYPE_PEGA_VIEWID = 'PersonalInformation';
export const PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING = ' xlsx, xls, png, jpeg or pdf';
