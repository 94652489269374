import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import Tick from '../../Core/icons/Outline/Tick';
import FormInputFileUpload from '../../../../components/ServiceUI/Core/FilesUpload/FormInputFileUpload';
import FormCheckbox from '../../../../pages/EFT/RequestDetailsFormEFT/FormCheckbox';
import fieldPropMapping from '../../../../pages/EFT/RequestDetailsFormEFT/fieldPropsMapping';
import { useSelector } from 'react-redux';

interface DeliveryProps {
  deliveryData: DocumentDelivery;
  declarationData: Declaration;
  caseIDOriginal: string;
}

interface DocumentDelivery {
  title: string;
  field: {};
}
interface Declaration {
  title: string;
  uploadDeclaration: [
    { caption: { value: string } },
    {
      field: {
        visible: boolean;
        required: boolean;
        reference: string;
        label: string;
        fieldID: string;
        customAttributes: {
          attachmentCategory: string;
          required: string;
        };
      };
    },
  ];
  acknowledgement: {
    label: string;
  };
}

const DeliveryInformation = ({ deliveryData, declarationData, caseIDOriginal }: DeliveryProps) => {
  const uploadDeclarationDataRedux: string = useSelector(
    (state: any) => state.EFTRequestMemberForm.formFields.DeclarationData[0].uploadDeclaration,
  );
  return (
    <>
      {/* Application Document Delivery */}
      <Box data-testid="delivery-info" my="37px">
        <Heading as="h2" size="xs" fontWeight="medium">
          {deliveryData?.title}
        </Heading>
        <Box bg="navy" borderRadius="0.5rem" padding="2rem" color="white" maxW="714px">
          <Tick />
          <Heading as="h3" size="xs" color="white" mt="7px" fontWeight="medium">
            Records available in ServiceConnect
          </Heading>
          <Text fontWeight="300" fontSize="18px">
            The requested records will be uploaded seperately and you will receive an email to advise when each record
            is available to download.
          </Text>
        </Box>
      </Box>

      {/* Applicant Signed Form */}
      <Box data-testid="declaration">
        {uploadDeclarationDataRedux.length > 0 && (
          <FormInputFileUpload
            required={Boolean(declarationData?.uploadDeclaration[1]?.field?.customAttributes?.required)}
            label={declarationData?.title}
            reference={declarationData?.uploadDeclaration[1]?.field?.reference}
            caseIDOriginal={caseIDOriginal}
            formData={declarationData?.uploadDeclaration[1]?.field}
            fieldPropMapping={fieldPropMapping}
            attachmentCategory={declarationData?.uploadDeclaration[1]?.field?.customAttributes?.attachmentCategory}
          />
        )}
        {/* Applicant Acknowledgment */}
        <Heading as="h3" size="xxs" color="black" mt="37px" fontWeight="600">
          {declarationData?.acknowledgement[0]?.label}
          <Box
            display="inline"
            role="presentation"
            aria-hidden="true"
            ml="4px"
            fontSize="18px"
            fontWeight="medium"
            color="redalert"
          >
            *
          </Box>
        </Heading>
        <FormCheckbox
          value={declarationData?.acknowledgement[0]?.value}
          reference={declarationData?.acknowledgement[0]?.reference}
          required={Boolean(declarationData?.acknowledgement[0]?.customAttributes?.required)}
          visible={declarationData?.acknowledgement[0]?.visible}
          label={declarationData?.acknowledgement[1]?.value}
          isInvalid={null}
          fieldID={declarationData?.acknowledgement[0]?.fieldID}
          fieldPropMapping={fieldPropMapping}
          formData={declarationData?.acknowledgement[0]}
          readOnly={declarationData?.acknowledgement[0]?.disabled}
        />
      </Box>
    </>
  );
};

export default DeliveryInformation;
