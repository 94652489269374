import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetmemDataforAdvocate,
  resetmemDataforFamily,
  resetmemDataforSelf,
  setmemberDetailForAdvocate,
  setmemberDetailForFamilyData,
  setmemberDetailForSelf,
} from '../../../reducers/eftRequestDetailFormReducer';
import { setShowError } from '../../../reducers/errorHandling';
import { refreshEFTForm } from '../API/refreshEFTForm';
import FormCheckbox from './FormCheckbox';
import FormDateInput from './FormDateInput';
import FormSelect from './FormSelect';
import FormRadio from './FormRadio';
import FormTextAreaInput from './FormTextAreaInput';
import FormTextInput from './FormTextInput';
import FormInputFileUpload from '../../../components/ServiceUI/Core/FilesUpload/FormInputFileUpload';
import fieldPropMapping from './fieldPropsMapping';
import { getPageInstructionObj, prepareFormFields } from '../Helper/eftHelperFunc';

import { FormLabel } from '@chakra-ui/react';
import { EFTConstants } from '../EFTConstant';
import FormMulti from './FormMulti';

const PIRDynamicFormRender = ({
  caseID,
  caseIDOriginal,
  isReview,
  actionID,
  attachmentCategory,
  nextAssignmentId,
  formDataTorender,
  currentStateData,
  selectedTypeOfRecords,
  deleteAndUpdateDoc,
  setApiCallInProgress,
}) => {
  const dispatch = useDispatch();
  const reqDetailReduxStore = useSelector((state: any) => state.EFTRequestMemberForm);

  const componentMap = (field: any) => {
    switch (field.type) {
      case 'pxDropdown':
        return (
          <FormSelect
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            dynamicFieldAction={dynamicFieldAction}
            key={field.fieldID}
          />
        );
      case 'pxTextInput':
        return (
          <FormTextInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'pxTextArea':
        return (
          <FormTextAreaInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'pxCheckbox':
        return (
          <FormCheckbox
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'pxRadioButtons':
        return (
          <FormRadio
            formData={formDataTorender}
            caseID={caseID}
            nextAssignmentId={nextAssignmentId}
            {...field}
            fieldPropMapping={fieldPropMapping}
            dynamicFieldAction={dynamicFieldAction}
            key={field.fieldID}
          />
        );
      case 'pxAttachContent':
        return (
          <FormInputFileUpload
            {...field}
            isReview={isReview}
            attachmentCategory={field.customAttributes.attachmentCategory}
            caseIDOriginal={caseIDOriginal}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.customAttributes.attachmentCategory}
          />
        );
      case 'pxDateTime':
        return (
          <FormDateInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'caption':
        return <FormLabel key={field.captionFor}>{field.captionFor}</FormLabel>;
      case 'pxDisplayText':
        return <Form.Label key={field.fieldID}>{field.label}</Form.Label>;
      case 'multi':
        return (
          <FormMulti {...field} fieldPropMapping={fieldPropMapping} formData={formDataTorender} key={field.fieldID} />
        );
      case 'default':
        return (
          <FormTextInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
    }

    return null;
  };

  const dynamicFieldAction = async (currValue, fieldID, reference, required, visible, refreshFor?) => {
    setApiCallInProgress(true);

    if (fieldID === EFTConstants.MEM_IS_DECEASED) {
      deleteAndUpdateDoc();
    }

    const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);
    const finalInstruction = await getPageInstructionObj(allUpdatedValue, currValue, fieldID, reference);

    const applicantTypeActions = {
      Self: [setmemberDetailForSelf, resetmemDataforSelf, 'PIRContactDetails_Self'],
      Family: [setmemberDetailForFamilyData, resetmemDataforFamily, 'PIRContactDetails_Family'],
      Advocate: [setmemberDetailForAdvocate, resetmemDataforAdvocate, 'PIRContactDetails_Advocate'],
    };

    const [finalAction, resetAction, finalViewId] = applicantTypeActions[currentStateData.ApplicantType.value];

    dispatch(resetAction());

    try {
      const fieldList = await refreshEFTForm(
        finalInstruction,
        nextAssignmentId,
        actionID,
        finalViewId,
        refreshFor ?? '',
      );
      dispatch(finalAction({ fieldList }));
      setApiCallInProgress(false);
      return 'All Done';
    } catch (error) {
      setApiCallInProgress(false);
      dispatch(setShowError({ hasError: true, error }));
      throw error;
    }
  };

  return (
    <>
      {Object.values(formDataTorender).map((field, idx) => {
        return Array.isArray(field) ? (
          field.map((fieldGroup) =>
            fieldGroup.length > 0 ? fieldGroup.map((fieldItem) => componentMap(fieldItem)) : componentMap(fieldGroup),
          )
        ) : (
          // @ts-ignore
          <React.Fragment key={`${field.fieldID}-key-${idx}` || `${idx}-key`}>{componentMap(field)}</React.Fragment>
        );
      })}
    </>
  );
};

export default PIRDynamicFormRender;
