import { appConstants } from '../helper/client/constant';
import { request } from './useAxios';

export const allEndUserFiles = async (id: string): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    request(appConstants.API_CASES, 'get', { id: id + '/attachments' })
      .then((res) => {
        if (res.data.attachments) {
          const AllPIRUserFiles = res.data.attachments.filter(
            (file: any) =>
              file.category !== appConstants.ATTACHMENT_TYPE_OFFICIAL_DOC &&
              file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
              file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
          );

          const AllUserfileInfoArray = AllPIRUserFiles.map((file) => {
            return {
              name: file.name,
              attachmentLinkID: file.ID,
              size: file.fileSizeinbytes,
            };
          });
          resolve(AllUserfileInfoArray);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
};
