import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import InfoBox from '../../Core/InfoBox/InfoBox';
import { Success } from '../../Core/icons/Outline';
import sanitizeHtml from 'sanitize-html';

interface SubmissionSuccessProps {
  mainHeading: string;
  subHeading: string;
  mainContent: string;
  dateHeading: string;
  caseID: string;
  date: Date;
  status: string;
}

export const SubmissionSuccess: React.FC<SubmissionSuccessProps> = (props: SubmissionSuccessProps): JSX.Element => {
  return (
    <Box py="1.5rem">
      <Box height={{ lg: '4rem', base: '3rem' }}>
        <Success height="100%" />
      </Box>
      <Heading as="h2" fontSize={{ lg: 'xl', base: 'sm' }} fontWeight="medium" mt="1rem" mb="2rem">
        {props.mainHeading}
      </Heading>
      <InfoBox>
        <Heading as="h3" fontSize="sm" fontWeight="medium">
          {props.subHeading}
        </Heading>
        <Box
          fontWeight="light"
          data-testid="description"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(props.mainContent),
          }}
        />
        <Flex justifyContent="space-between" gap={{ lg: 3, base: 1 }} flexDir={{ lg: 'row', base: 'column' }}>
          <Flex gap={{ lg: 3, base: 1 }} flexDir={{ lg: 'row', base: 'column' }}>
            <Box>
              <Text m="0">Personal Information Request ID</Text>
              <Text fontWeight="light" m="0">
                {props.caseID.substring(props.caseID.indexOf('P'))}
              </Text>
            </Box>
            <Box>
              <Text m="0">Date {props.dateHeading}</Text>
              <Text fontWeight="light" m="0">
                {new Date(props.date).toDateString()}
              </Text>
            </Box>
          </Flex>
          <Box>
            <Text m="0">Current status</Text>
            <PegaCaseBadge status={props.status} />
          </Box>
        </Flex>
      </InfoBox>
    </Box>
  );
};
