import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import content from './Content/Help.json';
import { CommonFeedback } from '../../components/common/CommonFeedback/commonFeedback';
import Header from '../../components/ServiceUI/Core/Header/Header';
import Accordion from '../../components/ServiceUI/Core/Accordion/Accordion';

export const HelpPage: React.FC<any> = ({ myServices }) => {
  useEffect(() => {
    document.title = 'Help and FAQs';
  });
  return (
    <>
      <Box>
        <Header {...content.header} />
        {content.accordion.map((value, index) => (
          <Accordion {...value} key={index} index={index} />
        ))}
        <CommonFeedback
          title="Please tell us what you need from Personal Information Request"
          selectedService="personalinformationrequest"
          myServices={myServices}
        />
      </Box>
    </>
  );
};
