import React, { useEffect } from 'react';
import CtaPanels from '../../components/ServiceUI/LandingPage/CtaPanels/CtaPanels';
import content from './Content/OurServices.json';
import { Box, Center } from '@chakra-ui/react';
import { SubContainer } from '../../components/ServiceUI/Core/Layout/SubContainer/SubContainer';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { useSelector } from 'react-redux';

export const OurServicesPage = (ProfileData) => {
  const isInternal = useSelector((state: any) => state.isInternal);

  useEffect(() => {
    document.title = 'All Services';
  });

  return (
    <>
      <Header {...content.header} />
      <Center bg="whispergrey">
        {ProfileData && (
          <SubContainer verticalMargin="1rem">
            <CtaPanels
              heading={content.services.panels_heading}
              variation="primary"
              panels={content.services.panels}
              myServices={ProfileData.myServices}
            />
            {isInternal ? (
              <>
                <Box borderTop={{ md: '1px solid darkgrey' }} my={{ md: '48px', base: '25px' }} />
                <CtaPanels
                  heading={content.dnrServices.panels_heading}
                  variation="secondary"
                  panels={content.dnrServices.panels}
                  myServices={ProfileData.myServices}
                />
              </>
            ) : (
              <></>
            )}
          </SubContainer>
        )}
      </Center>
    </>
  );
};
