import { appConstants } from './client/constant';
import { getUserType } from '../helper/getUserType';

const isInternal = getUserType();

// apiUrl
export const getApiUrl = !isInternal ? process.env.REACT_APP_EXTERNAL_DOMAIN : process.env.REACT_APP_INTERNAL_DOMAIN;

// appUrl
export const getCurrentEnv = !isInternal
  ? process.env.REACT_APP_EXTERNAL_APP_URL
  : process.env.REACT_APP_INTERNAL_APP_URL;

// aemUrl
export const getAemUrl = !isInternal
  ? process.env.REACT_APP_EXTERNAL_DOMAIN_AEM
  : process.env.REACT_APP_INTERNAL_DOMAIN_AEM;

export const getDEAP_URL = () => {
  return (
    (isInternal ? process.env.REACT_APP_INTERNAL_DOMAIN : process.env.REACT_APP_EXTERNAL_DOMAIN) +
    (isInternal ? appConstants.DEAP_INTERNAL_URL : appConstants.DEAP_EXTERNAL_URL)
  );
};

export const getIAU_URL = () => {
  return getApiUrl + appConstants.IAU_PORTAL_COMMON_URL;
};

export const getPostingConnectURL = () => {
  return getApiUrl + (isInternal ? appConstants.POSTINGCONNECT_INTERNAL_URL : appConstants.POSTINGCONNECT_EXTERNAL_URL);
};

export const logoutURL = () => {
  return getApiUrl + (isInternal ? appConstants.INTERNAL_LOGOUT_URL : appConstants.EXTERNAL_LOGOUT_URL);
};

export const getFormsURL = () => {
  return getAemUrl + appConstants.FORMS_COMMON_URL;
};

export const getBaseURL = () => {
  return getAemUrl + appConstants.MYBASE_COMMON_URL;
};

export const getTravelURL = () => {
  return getApiUrl + (isInternal ? appConstants.TRAVEL_INTERNAL_URL : appConstants.TRAVEL_EXTERNAL_URL);
};

export const getAuthURL = () => {
  return getApiUrl + (isInternal ? appConstants.SC_LOGIN_INTERNAL_URL : appConstants.SC_LOGIN_EXTERNAL_URL);
};

export const currentDomain = window.location.origin;
