// IMPORTS
// Hooks/React
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApiUrl } from '../../helper/getOtherAppURL';

// UI
import { Box, Heading, Text } from '@chakra-ui/react';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import DeliveryInformation from '../../components/ServiceUI/EFT/DeliveryInformation/DeliveryInformation';
import FormMulti from './RequestDetailsFormEFT/FormMulti';
import FormRadio from './RequestDetailsFormEFT/FormRadio';
import FormSelect from './RequestDetailsFormEFT/FormSelect';

import { setShowError } from '../../reducers/errorHandling';

import {
  PIRAmend,
  PIRComm,
  PIRComp,
  PIRHistorical,
  PIRMedical,
  PIRRequest,
  PIRRoyal,
  PIRVet,
} from '../../components/ServiceUI/Core/icons/Outline';

import fieldPropMapping from '../EFT/RequestDetailsFormEFT/fieldPropsMapping';
import requestContent from './Content/RequestInformation';

import PIRDynamicFormRender from './RequestDetailsFormEFT/PIRDynamicFormRender';

// Functions
import { appConstants } from '../../helper/client/constant';
import convertSpecialChars from '../../helper/convertSpecialChars';
import { refreshEFTForm } from './API/refreshEFTForm';
import {
  APPLICANT_TYPE_PEGA_VIEWID,
  EFTConstants,
  PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING,
  REQUEST_DETAILS_PEGA_VIEWID,
} from './EFTConstant';

// Reducers
import {
  setApplicantTypeFromPega,
  setFieldValue,
  setTypeOFRecordsFromPega,
  setmemberDetailForAdvocate,
  setmemberDetailForFamilyData,
  setmemberDetailForSelf,
  updateSelectedApplicantType,
} from '../../reducers/eftRequestDetailFormReducer';

// Types
import { RequestDetailEftProps, reactStateElements } from '../../components/ServiceUI/EFT/types';

export const RequestDetailEft: React.FC<RequestDetailEftProps> = (props: RequestDetailEftProps) => {
  const dispatch = useDispatch();
  const [ItemRenderedForRoyalCommison, setItemRenderedForRoyalCommison] = useState(false);
  const formData: reactStateElements = useSelector((state: any) => state.EFTRequestMemberForm.formFields);
  const [typeOfRecordsSelected] = useState([] as Array<string>);
  const [loading, setLoading] = useState({
    isLoading: false,
  });
  const apiUrl = getApiUrl;

  // State
  const [ApplicantTypeAdvocateDatafromReducer, setApplicantTypeAdvocateDataFromReducer] = useState(
    formData?.ApplicantTypeAdvocateMemData,
  );
  const [ApplicantTypeSelfDataFromReducer, setApplicantTypeSelfDataFromReducer] = useState(
    formData?.ApplicantTypeSelfMemData ?? [],
  );
  const [ApplicantTypeFamilyDataFromReducer, setApplicantTypeFamilyDataFromReducer] = useState(
    formData?.ApplicantTypeFamilyMemData ?? [],
  );
  const [attachmentCategory] = useState({
    selectedCategory: 'PersonnelDocuments',
    categories: [],
    categoriesOrig: [],
    selectedCategoryId: 'PersonnelDocuments',
    allowedFileExt: PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING,
  });

  useEffect(() => {
    if (
      !!formData?.ServiceType &&
      !!formData?.TypeofRecords &&
      formData?.ServiceType.value === EFTConstants.PIR_ROYAL_SERVICE_TYPE &&
      formData?.TypeofRecords[0] &&
      ItemRenderedForRoyalCommison === false
    ) {
      const personnelObj = formData?.TypeofRecords[0].find((item) => item.fieldID === 'IsPersonnelInformation');
      onTypeOfRecordsSelection(EFTConstants.PIR_TYPE_OF_RECORD_PERSONNEL_REFERENCE, personnelObj, null);
      setItemRenderedForRoyalCommison(true);
    }
  }, [formData?.ServiceType]);

  const dynamicFieldAction = (contentObj, fieldID, reference, viewID, actionName, refreshFor?) => {
    return new Promise((resolve, reject) => {
      props.setApiCallInProgress(true);

      const pageInstructionObj = {
        instruction: 'UPDATE',
        target: '.' + reference.replace('.' + fieldID, ''),
        content: contentObj,
      };
      let pageInstructions = [pageInstructionObj];

      if (fieldID !== 'ServiceType') {
        const serviceTypePegaeinstruction = {
          instruction: 'UPDATE',
          target: '.' + formData.ServiceType.reference.replace('.' + formData.ServiceType.fieldID, ''),
          content: {
            [formData.ServiceType.fieldID]: formData.ServiceType.value,
          },
        };
        pageInstructions = [...pageInstructions, serviceTypePegaeinstruction];
      }

      return refreshEFTForm(pageInstructions, props.nextAssignmentID, props.actionId, viewID, refreshFor ?? '')
        .then((fieldList) => {
          dispatch(actionName({ fieldList }));
          props.setApiCallInProgress(false);
          resolve('All Done');
        })
        .catch((error) => {
          props.setApiCallInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
          throw error;
        });
    });
  };

  const onTypeOfRecordsSelection = (reference, checked, refresh) => {
    let refreshFor;
    let hasRefresh;

    refresh?.forEach((record) => {
      if (record.reference === reference) {
        refreshFor = encodeURIComponent(record.control?.actionSets[0]?.actions[1]?.refreshFor);
        hasRefresh = record.customAttributes?.refreshFor;
      }
    });

    let noRecordsSelected = formData?.TypeofRecords[0].some((item: any) => {
      return item.value === 'Yes';
    });
    let newRef = reference.split('.')[1];
    let fieldValue = checked ? 'Yes' : 'No';
    let contObj = {
      [newRef]: fieldValue,
      [EFTConstants.PIR_SERVICE_TYPE_FIELDID]: 'Amendment',
    };

    // Add other values  of checked or not and add them to the contObj
    formData?.TypeofRecords[0].forEach((item: any) => {
      if (item.fieldID !== newRef) {
        contObj[item.fieldID] = item.value;
      }
    });

    const finalViewId = noRecordsSelected ? REQUEST_DETAILS_PEGA_VIEWID : APPLICANT_TYPE_PEGA_VIEWID;
    const finalAction = noRecordsSelected ? setTypeOFRecordsFromPega : setApplicantTypeFromPega;
    dynamicFieldAction(contObj, newRef, reference, finalViewId, finalAction, hasRefresh && refreshFor);
  };

  const deleteAttachment = async (file) => {
    try {
      const URL = apiUrl + appConstants.API_ATTACHMENTS_FILE + file.ID;
      await axios.delete(URL, { withCredentials: true });
      return 'All deleted';
    } catch (error) {
      dispatch(setShowError({ hasError: true, error }));
      throw error;
    }
  };

  const getAndDeleteAllAttachmentinTheCase = async () => {
    props.setApiCallInProgress(true);
    setLoading((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const URL = `${appConstants.API_CASES}${props.caseIdOrig}/attachments`;
      const res = await axios.get(apiUrl + URL, { withCredentials: true });

      if (!res.data.attachments) {
        return EFTConstants.PIR_NO_FILE_FOUND;
      } else {
        const filteredArrayNonCorrespondese = res.data.attachments.filter(
          (file) => file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE,
        );

        if (filteredArrayNonCorrespondese.length > 0) {
          const promises = filteredArrayNonCorrespondese.map((file) => deleteAttachment(file));
          const data = await Promise.all(promises);
          return data;
        } else {
          return EFTConstants.PIR_NO_VALID_FILE_FOUND;
        }
      }
    } catch (error) {
      dispatch(setShowError({ hasError: true, error }));
      throw error;
    } finally {
      setLoading((prevState) => ({ ...prevState, isLoading: false }));
      props.setApiCallInProgress(false);
    }
  };

  const onApplicantTypeSelection = (value, refresh) => {
    const refreshFor = encodeURIComponent(refresh?.control?.actionSets[0]?.actions[1]?.refreshFor);
    const hasRefresh = refresh?.customAttributes?.refreshFor === 'true';
    //on applicant type change we need to delete all attached document

    getAndDeleteAllAttachmentinTheCase().then((result) => {
      let contObj = {
        ApplicantType: value,
        ServiceType: formData.ServiceType.value,
      };
      if (formData.TypeOfRecordsAdditionalFields[0] && formData.TypeOfRecordsAdditionalFields[0].length > 0) {
        formData.TypeOfRecordsAdditionalFields[0].forEach((item: any) => {
          contObj[item.fieldID] = item.value;
        });
      }

      for (let i = 0; i < typeOfRecordsSelected.length; i++) {
        contObj[typeOfRecordsSelected[i]] = 'Yes';
      }

      // Add the previous selected type of records so they dont get overwritten
      formData?.TypeofRecords[0].forEach((item: any) => {
        if (item.value === 'Yes') {
          contObj[item.fieldID] = 'Yes';
        }
      });

      const finalViewId =
        value === 'Self'
          ? 'PIRContactDetails_Self'
          : value === 'Family'
          ? 'PIRContactDetails_Family'
          : 'PIRContactDetails_Advocate';

      const finalAction =
        value === 'Self'
          ? setmemberDetailForSelf
          : value === 'Family'
          ? setmemberDetailForFamilyData
          : setmemberDetailForAdvocate;

      dynamicFieldAction(
        contObj,
        'ApplicantType',
        'PersonalInfoRequest.ApplicantType',
        finalViewId,
        finalAction,
        hasRefresh && refreshFor,
      );

      dispatch(
        updateSelectedApplicantType({
          // Update here
          reference: value,
          value: value,
          formData,
        }),
      );
    });
  };

  const handleCallBackChangeServiceType = (value, refresh) => {
    const refreshFor = encodeURIComponent(refresh?.control?.actionSets[0]?.actions[1]?.refreshFor);
    const hasRefresh = refresh.customAttributes.refreshFor === 'true';
    const selectedKey = formData?.ServiceType?.control?.modes[0]?.options?.find((item) => item.key === value);
    getAndDeleteAllAttachmentinTheCase().then((result) => {
      dispatch(
        setFieldValue({
          reference: formData?.ServiceType?.reference,
          value: selectedKey?.value,
          formData,
        }),
      );
      const contentObj = { [formData?.ServiceType?.fieldID]: value };

      dynamicFieldAction(
        contentObj,
        formData?.ServiceType?.fieldID,
        formData?.ServiceType?.reference,
        REQUEST_DETAILS_PEGA_VIEWID,
        setTypeOFRecordsFromPega,
        hasRefresh && refreshFor,
      ).then((val) => {
        // code needs to be added later
      });
    });
  };

  // Format Applicant data for Service Area checkbox

  const updatedDataWithCaption = (member) => {
    let updatedData = formData?.[member].map((item) => {
      let returnedFields = {};
      Object.keys(item).forEach((key) => {
        let allFields = item[key].map((newitem) => {
          if (Array.isArray(newitem)) {
            let options: any[] = [];
            Object.values(newitem).map((i: any) => i.type === 'pxCheckbox' && options.push(i));
            const obj = {
              fieldID: 'ServiceArea',
              type: 'multi',
              options: options,
              visible: true,
              label: 'Service area(s)',
              required: true,
            };

            return [obj];
          } else return newitem;
        });
        returnedFields = { [key]: allFields };
      });
      return returnedFields;
    });
    return updatedData;
  };

  useEffect(() => {
    if (formData?.ApplicantTypeSelfMemData && formData?.ApplicantTypeSelfMemData.length > 0) {
      setApplicantTypeSelfDataFromReducer(updatedDataWithCaption('ApplicantTypeSelfMemData'));
    }
    if (formData?.ApplicantTypeSelfMemData && formData?.ApplicantTypeSelfMemData.length === 0) {
      setApplicantTypeSelfDataFromReducer(formData?.ApplicantTypeSelfMemData);
    }
  }, [formData?.ApplicantTypeSelfMemData]);

  useEffect(() => {
    if (formData?.ApplicantTypeFamilyMemData && formData?.ApplicantTypeFamilyMemData.length > 0) {
      setApplicantTypeFamilyDataFromReducer(updatedDataWithCaption('ApplicantTypeFamilyMemData'));
    }
    if (formData?.ApplicantTypeFamilyMemData && formData?.ApplicantTypeFamilyMemData.length === 0) {
      setApplicantTypeFamilyDataFromReducer(formData?.ApplicantTypeFamilyMemData);
    }
  }, [formData?.ApplicantTypeFamilyMemData]);

  useEffect(() => {
    if (formData?.ApplicantTypeAdvocateMemData && formData?.ApplicantTypeAdvocateMemData.length > 0) {
      setApplicantTypeAdvocateDataFromReducer(updatedDataWithCaption('ApplicantTypeAdvocateMemData'));
    }
    if (formData?.ApplicantTypeAdvocateMemData && formData?.ApplicantTypeAdvocateMemData.length === 0) {
      setApplicantTypeAdvocateDataFromReducer(formData?.ApplicantTypeAdvocateMemData);
    }
  }, [formData?.ApplicantTypeAdvocateMemData]);

  return (
    <Box minH="500px">
      <Heading mt="1rem" as="h2" fontSize="sm" fontWeight="regular">
        Request information
      </Heading>

      {formData?.ServiceType?.fieldID && (
        <>
          {/* @ts-ignore */}
          <FormSelect
            control={formData?.ServiceType?.control}
            newFunction={(e) => {
              handleCallBackChangeServiceType(e, formData.ServiceType);
            }}
            fieldID="ServiceType"
            required={formData?.ServiceType?.required}
            visible={formData?.ServiceType?.visible}
            value={formData?.ServiceType?.value}
            label={formData?.ServiceType?.label}
            isInvalid={formData?.ServiceType?.error}
            fieldPropMapping={fieldPropMapping}
            reference={formData?.ServiceType?.reference}
            // disabled={props.formEditMode}
          />
        </>
      )}

      {!!formData?.TypeofRecords && !!formData?.TypeofRecords[0] && formData?.TypeofRecords[0]?.length > 0 && (
        <>
          <Box bg="navy" borderRadius="0.5rem" padding="2rem" my="20px" color="white" minH="220px" maxW="714px">
            {formData.ServiceType?.value === 'Amendment' && <PIRAmend />}
            {formData.ServiceType?.value === 'Commemoration' && <PIRComm />}
            {formData.ServiceType?.value === 'Compensation' && <PIRComp />}
            {formData.ServiceType?.value === 'Historical' && <PIRHistorical />}
            {formData.ServiceType?.value === 'Medical' && <PIRMedical />}
            {/* {formData.ServiceType?.value === 'Other' && <PIROther />} */}
            {formData.ServiceType?.value === 'Other' && <PIRRequest />}
            {formData.ServiceType?.value === 'RoyalCommission' && <PIRRoyal />}
            {formData.ServiceType?.value === 'Veteran' && <PIRVet />}
            <Heading as="h3" size="xs" color="white" mt="7px" fontWeight="medium">
              {
                Object.values(formData?.ServiceType?.control?.modes[0].options)?.find(
                  (i: any) => i.key === formData.ServiceType?.value,
                )?.value
              }
            </Heading>
            <Text fontWeight="300" fontSize="18px">
              {/* {bannerCaption[formData.ServiceType.value]} */}
              {requestContent.bannerCaption[formData.ServiceType.value]}
            </Text>
          </Box>

          <FormMulti
            label="Type of Records"
            readOnly={false}
            required
            visible
            fieldID="TypeOfRecords"
            formData={formData?.TypeofRecords[0]}
            fieldPropMapping={fieldPropMapping}
            options={formData?.TypeofRecords[0]}
            showTooltip={null}
            tooltipContent={null}
            reference={null}
            value={null}
            // @ts-ignore
            newFunction={(reference, checked) => {
              // @ts-ignore
              onTypeOfRecordsSelection(reference, checked, formData?.TypeofRecords[0]);
            }}
            // @ts-ignore
          />

          {formData?.TypeofRecords[0] &&
            formData?.TypeofRecords[0].map(
              (record) =>
                record?.value === 'Yes' && (
                  <Text key={record.label}>{convertSpecialChars(record.control.modes[0].tooltip)}</Text>
                ),
            )}
        </>
      )}
      {!!formData?.TypeOfRecordsAdditionalFields &&
        formData?.TypeOfRecordsAdditionalFields[0] &&
        formData?.TypeOfRecordsAdditionalFields[0].length > 0 && (
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal=""
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory="image"
            formDataTorender={formData?.TypeOfRecordsAdditionalFields[0]}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
        )}
      {formData?.ApplicantType && Object.keys(formData?.ApplicantType).length > 0 && (
        <Box>
          <Heading mt="1rem" as="h2" fontSize="sm" fontWeight="regular">
            Personal information
          </Heading>
          <FormRadio
            label="Applicant Type"
            required
            variant="eft"
            fieldID="ApplicantType"
            value={formData?.ApplicantType?.value}
            fieldPropMapping={fieldPropMapping}
            readOnly={false}
            newFunction={(e) => onApplicantTypeSelection(e, formData.ApplicantType)}
            control={formData?.ApplicantType?.control}
            visible
            // @ts-ignore
            showTooltip={true}
          />
        </Box>
      )}
      {ApplicantTypeSelfDataFromReducer?.length > 0 && (
        <>
          <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
            Member information
          </Heading>
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal=""
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory="image"
            formDataTorender={ApplicantTypeSelfDataFromReducer[0].self}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
        </>
      )}
      {ApplicantTypeFamilyDataFromReducer?.length > 0 && (
        <>
          {/* Split into 2 ApplicantTypeFamilyDataFromReduceMember */}

          <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
            Member information
          </Heading>
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal={props.caseIdOrig}
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory={attachmentCategory}
            formDataTorender={ApplicantTypeFamilyDataFromReducer[0].member}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
          {/* Split into 2 ApplicantTypeFamilyDataFromReducerFamily */}
          <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
            My information (applicant)
          </Heading>
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal={props.caseIdOrig}
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory={attachmentCategory}
            formDataTorender={ApplicantTypeFamilyDataFromReducer[1].family}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
        </>
      )}
      {ApplicantTypeAdvocateDatafromReducer?.length > 0 && (
        <>
          {/* Split into 2 ApplicantTypeFamilyDataFromReduceMember */}
          <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
            Member information
          </Heading>
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal={props.caseIdOrig}
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory={attachmentCategory}
            formDataTorender={ApplicantTypeAdvocateDatafromReducer[0].applicant}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
          {ApplicantTypeAdvocateDatafromReducer[1].deceased.length > 0 && (
            <Box bgColor="#F5F5F5" my="30px" py="45px" px="30px">
              <h2>Requesting information of a deceased member</h2>
              <p>
                To request information for a deceased ADF member as an advocate, you will need to provide identity
                documentation for a family member.
              </p>
              <h3>Family member details</h3>
              <PIRDynamicFormRender
                caseID=""
                caseIDOriginal={props.caseIdOrig}
                nextAssignmentId={props.nextAssignmentID}
                actionID={props.actionId}
                isReview={false}
                attachmentCategory={attachmentCategory}
                formDataTorender={ApplicantTypeAdvocateDatafromReducer[1].deceased}
                currentStateData={formData}
                selectedTypeOfRecords={typeOfRecordsSelected}
                deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
                setApiCallInProgress={props.setApiCallInProgress}
              ></PIRDynamicFormRender>
            </Box>
          )}

          <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
            My information (the advocate)
          </Heading>
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal={props.caseIdOrig}
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCategory={attachmentCategory}
            formDataTorender={ApplicantTypeAdvocateDatafromReducer[2].advocateDetails}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            setApiCallInProgress={props.setApiCallInProgress}
          ></PIRDynamicFormRender>
        </>
        // Part 2 Requesting information of a deceased member
        // Part 3 My information (the advocate)
      )}
      {/* DELIVERY INFORMATION */}
      {formData?.DeliveryDetailsData &&
        formData?.DeliveryDetailsData.length > 0 &&
        formData?.DeclarationData &&
        formData?.DeclarationData.length > 0 && (
          <DeliveryInformation
            deliveryData={formData?.DeliveryDetailsData[0]}
            declarationData={formData?.DeclarationData[0]}
            caseIDOriginal={props.caseIdOrig}
          />
        )}
      {loading.isLoading && <Spinner />}
    </Box>
  );
};
