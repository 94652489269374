import React, { useEffect } from 'react';
import content from './Content/ProvideDocuments.json';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { Redirector } from '../../components/ServiceUI/Core/Redirector';
import { getApiUrl, getIAU_URL } from '../../helper/getOtherAppURL';
import { appConstants } from '../../helper/client/constant';

export const ProvideDocumentsPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Send document request';
  }, []);

  return (
    <>
      <Header {...content.header} />
      <Redirector
        urlToRedirectTo={getIAU_URL()}
        showMessage
        message="This portal is no longer active. You will be redirected to the IAU business portal"
        redirectButtonText="OK"
      />
    </>
  );
};
