import React from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormSelect = ({
  fieldID,
  control,
  required,
  fieldPropMapping,
  newFunction,
  visible,
  error,
  reference,
  value,
  formData,
  label,
  disabled,
  isInvalid,
}) => {
  const dispatch = useDispatch();

  const handleSelect = (target) => {
    // Only call newFunction if it exists
    newFunction && newFunction(target);
    dispatch(
      setFieldValue({
        reference,
        value: target,
        formData,
      }),
    );
  };

  return (
    <SCForm.Select
      disabled={disabled}
      visible={visible}
      required={required}
      isInvalid={error}
      label={label}
      reference={reference}
      value={value}
      options={control.modes[0].options}
      validation={fieldPropMapping[fieldID]?.validation}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth?.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth?.mobile}
      onSelect={(e) => handleSelect(e)}
    />
  );
};

export default FormSelect;
