import axios from 'axios';
import { getApiUrl } from './getOtherAppURL';

export function request(url, type, opts?: { data?: Object; id?: string; config?: Object; isAEM?: boolean }) {
  let { id, data, config, isAEM } = opts ?? {};
  const envURL = isAEM ? url : getApiUrl + url;

  let returnData;

  // If no config values are beign passed assign the default value of { withCredentials: true }
  if (config === undefined) {
    config = { withCredentials: true };
  }

  const get = async () => {
    if (id) {
      if (id !== null) {
        return axios.get(`${envURL}${id}`, config);
      } else {
        return;
      }
    } else {
      return id !== null && axios.get(envURL, config);
    }
  };

  const post = async () => {
    if (id) {
      if (id !== null) {
        return axios.post(`${envURL}${id}`, data, config);
      } else {
        return;
      }
    } else {
      return id !== null && axios.post(envURL, data, config);
    }
  };

  const put = async () => {
    if (id) {
      if (id !== null) {
        return axios.put(`${envURL}${id}`, data, { ...config, withCredentials: true });
      } else {
        return;
      }
    } else {
      return id !== null && axios.put(envURL, data, config);
    }
  };

  const _delete = async () => {
    if (id) {
      if (id !== null) {
        return axios.delete(`${envURL}${id}`, config);
      } else {
        return;
      }
    } else {
      return id !== null && axios.delete(envURL, config);
    }
  };

  const patch = async () => {
    if (id) {
      if (id !== null) {
        return axios.patch(`${envURL}${id}`, data, config);
      } else {
        return;
      }
    } else {
      return id !== null && axios.patch(envURL, data, config);
    }
  };

  switch (type) {
    case 'get':
      returnData = get().then((e) => {
        return e;
      });
      break;
    case 'post':
      returnData = post().then((e) => {
        return e;
      });
      break;
    case 'put':
      returnData = put().then((e) => {
        return e;
      });
      break;
    case 'delete':
      returnData = _delete();
      break;
    case 'patch':
      returnData = patch();
      break;
    default:
      break;
  }
  return returnData;
}
