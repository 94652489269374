import { ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';
const disabledState = { borderColor: 'charcoal', borderWidth: '1px', borderStyle: 'solid', bg: 'none', opacity: 0.5 };

const Checkbox: ComponentStyleConfig = {
  parts: ['control', 'container', 'label'],
  variants: {
    main: {
      label: { fontSize: 'regular', w: '100%', fontWeight: '400' },
      control: {
        borderColor: 'charcoal',
        borderRadius: 0,
        borderWidth: '1px',
        borderStyle: 'solid',
        width: '16px',
        height: '16px',
        _checked: {
          backgroundColor: 'white',
          borderColor: 'success',
          _hover: { background: 'white', borderColor: 'none' },
          _disabled: disabledState,
        },
        _disabled: disabledState,
      },
      icon: { _hover: { background: 'white' } },
      container: {
        _focusWithin: {
          outlineColor: 'darkgrey',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineOffset: '4px',
        },
      },
    },
    multi: {
      label: { margin: 0, w: '100%' },
      control: { display: 'none' },
      container: {
        maxW: '16rem',
        p: '1.5rem',
        minH: '8rem',
        w: '16rem',
        h: '100%',
        bg: 'lightblue',
        borderRadius: '0.5rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        _checked: {
          color: 'white',
          backgroundColor: 'navy',
        },
        _focusWithin: {
          border: '2px solid black !important',
        },
        _invalid: { border: `1px solid ${colors.redalert}` },
      },
    },
  },
};

export default Checkbox;
